import { Observable, Subscription } from "rxjs";

export interface FileModel {
  id: string;
  fileName: string;
  observable: Observable<any>;
  progress: number;
  subcription: Subscription;
  status: TaskStatus;
}

export enum TaskStatus {
  Started,
  InProgress,
  Completed,
  Failed,
}
