<div class="dialog-section change-password-container">
  <div class="dialog-section__logo">
    <img src="assets/images/Logo.png"/>
  </div>
  <div class="dialog-section__close" *ngIf="!forceChangeRequired">
    <a (click)="onCancel()">
      <mat-icon svgIcon="close"></mat-icon>
    </a>
  </div>

  <form (submit)="onSubmitChangePasswordForm(changePasswordForm)" [formGroup]="changePasswordForm">
    <mat-dialog-content>
      <div>
        <h2>
          Change your password
        </h2>
        <p>Enter the current password for change password</p>
      </div>
      <div class="form-control form-control-admin MB-20">
        <mat-form-field floatLabel=always appearance="fill">
          <mat-label>Current Password</mat-label>
          <input autocomplete="off" formControlName="oldPassword" matInput placeholder="Current password" required
                 type="password"/>
          <mat-error *ngIf="isRequiredField(formControls['oldPassword'])">{{validationMsg.CURRENT_PASSWORD}}
          </mat-error>
          <mat-error *ngIf="isValidLength(formControls['oldPassword'])">{{validationMsg.CURRENT_PASSWORD_LENGTH}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-control form-control-admin MB-20">
        <mat-form-field floatLabel=always appearance="fill">
          <mat-label>New Password</mat-label>
          <input autocomplete="off" (keyup)="onCheckValue(changePasswordForm)" formControlName="newPassword" matInput placeholder="New password" required
                 type="password"/>
          <mat-error *ngIf="isRequiredField(formControls['newPassword'])">{{validationMsg.PASSWORD_REQUIRED}}
          </mat-error>
          <mat-error *ngIf="isValidLength(formControls['newPassword'])">{{validationMsg.PASSWORD_LENGTH}}</mat-error>
          <mat-error *ngIf="isValidField(formControls['newPassword'])">{{validationMsg.PASSWORD_VALID}}</mat-error>
        </mat-form-field>
        <div class="check-validation">
          <ul>
            <li [ngClass]="{'active': passwordError[4]}">
              <mat-icon svgIcon="greycheck"></mat-icon>
              <mat-icon svgIcon="bluecheck"></mat-icon> 8+ character
            </li>
            <li [ngClass]="{'active': passwordError[0]}">
              <mat-icon svgIcon="greycheck"></mat-icon>
              <mat-icon svgIcon="bluecheck"></mat-icon> 1 Uppercase
            </li>
            <li [ngClass]="{'active': passwordError[1]}">
              <mat-icon svgIcon="greycheck"></mat-icon>
              <mat-icon svgIcon="bluecheck"></mat-icon> 1 Lowercase
            </li>
            <li [ngClass]="{'active': passwordError[3]}">
              <mat-icon svgIcon="greycheck"></mat-icon>
              <mat-icon svgIcon="bluecheck"></mat-icon> 1 Numeric
            </li>
            <li [ngClass]="{'active': passwordError[2]}">
              <mat-icon svgIcon="greycheck"></mat-icon>
              <mat-icon svgIcon="bluecheck"></mat-icon> 1 Special (#?!@$%^&*-)
            </li>
          </ul>
        </div>
      </div>
      <div class="form-control form-control-admin MB-20">
        <mat-form-field floatLabel=always appearance="fill">
          <mat-label>Confirm Password</mat-label>
          <input autocomplete="off" formControlName="confirmPassword" matInput placeholder="Confirm password" required
                 type="password"/>
          <mat-error *ngIf="isRequiredField(formControls['confirmPassword'])">{{validationMsg.CONFIRM_PASSWORD}}
          </mat-error>
          <mat-error *ngIf="changePasswordForm.errors?.doesMatchPassword">
            {{validationMsg.PASSWORD_DOESNT_MATCH}}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <button class="btn btn-primary admin-btn-primary change-password-btn" type="submit"
                [disabled]="isLoadingResults"> Submit
          <mat-icon svgIcon="arrowRight"></mat-icon>
        </button>
        <a class="cancel-btn" *ngIf="!forceChangeRequired" (click)="onCancel()">Cancel</a>
      </div>
    </mat-dialog-content>
  </form>
</div>
