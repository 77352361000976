import { Component, OnDestroy, OnInit } from "@angular/core";
import { FileModel, TaskStatus } from "./download-progress.model";
import { Subscription } from "rxjs";
import { SharedService } from "@sharedModule/shared-service";

@Component({
  selector: "app-download-progress",
  templateUrl: "./download-progress.component.html",
  styleUrls: ["./download-progress.component.scss"],
})
export class DownloadProgressComponent implements OnInit, OnDestroy {
  // Data Variables
  downloadTaskList: FileModel[] = [];
  progressSubscription$: Subscription;

  // State Variables
  isMinimized: boolean = false;

  // Constants Variables
  readonly taskStatus = TaskStatus;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.progressSubscription$ = this.sharedService
      .getDownloadTaskList()
      .subscribe((response) => {
        this.downloadTaskList = response;
      });
  }

  ngOnDestroy() {
    this.progressSubscription$.unsubscribe();
  }

  // Click Events
  onMinimizeMaximize = (): void => {
    this.isMinimized = !this.isMinimized;
  };
}
