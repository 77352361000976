import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { catchError, startWith, switchMap } from "rxjs/operators";

import { AdminPatientService } from "@privateModule/services";
import { CommentLogType, PAGE_SIZE_OPTIONS } from "@sharedModule/constants";
import { of } from "rxjs";
import { queryParamsModel } from "@sharedModule/models";
import { TABLE_COLUMNS } from "./comments-log-dialog.constant";

@Component({
  selector: "app-admin-comments-log-dialog",
  templateUrl: "./comments-log-dialog.component.html",
  styleUrls: ["./comments-log-dialog.component.scss"],
})
export class CommentsLogDialogComponent implements OnInit {
  // Angular Variables
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // Data variables
  displayedColumns = TABLE_COLUMNS;
  sortKey = "dateCreated";
  sortOrder = "desc";
  commentList: any = [];
  patientId: number;
  logType: string;

  // State variables
  isLoadingResults = true;

  // Pagination Variables
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  pageSize = PAGE_SIZE_OPTIONS[0];
  resultsLength = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CommentsLogDialogComponent>,
    private adminPatientService: AdminPatientService
  ) {}

  ngOnInit() {
    this.patientId = this.data.patientId;
    this.logType = this.data.commentLogType;
    this.getCommentsList();
  }

  // Initialisation methods
  getCommentsList() {
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return (
            this.logType === CommentLogType.Review_log
              ? this.patientReviewCommentListApiCall(
                  this.sortKey,
                  this.sortOrder
                )
              : this.patientDetailsCommentListApiCall(
                  this.sortKey,
                  this.sortOrder
                )
          ).pipe(
            catchError(() => {
              this.isLoadingResults = false;
              return of({});
            })
          );
        })
      )
      .subscribe((res) => {
        this.handleCommentsListResponse(res);
      });
  }

  handleCommentsListResponse(response) {
    this.isLoadingResults = false;
    if (response && response.content && response.totalElements) {
      this.commentList = response["content"];
      this.resultsLength = response["totalElements"];
    }
  }
  // Api call
  patientDetailsCommentListApiCall(sortKey: string, sortOrder: string) {
    return this.adminPatientService.patientDetailsCommentLog(
      this.queryParams(sortKey, sortOrder)
    );
  }
  patientReviewCommentListApiCall(sortKey: string, sortOrder: string) {
    return this.adminPatientService.patientReviewCommentLog(
      this.queryParams(sortKey, sortOrder)
    );
  }

  // Page events
  onCancel() {
    this.dialogRef.close();
  }

  // Total time Duration column
  totalTimeDuration(totalTime: any) {
    const res = totalTime;
    const hour = Math.floor(res / 3600) % 24;
    const minutes = Math.floor(res / 60) % 60;
    const second = res % 60;
    return res
      ? `${hour !== 0 ? hour + "h" : ""}` +
          " " +
          `${minutes !== 0 ? minutes + "m" : ""}` +
          " " +
          `${second !== 0 ? second + "s" : ""}`
      : "-";
  }

  // Helper methods
  queryParams(sortField: string, sortOrder: string): any {
    const criteriaList: queryParamsModel[] = [
      {
        column: "patientId",
        values: [this.patientId],
        operator: "=",
      },
    ];
    const params = {
      page: this.paginator.pageIndex,
      size: this.paginator.pageSize || this.pageSize,
    };

    params["criteria"] = criteriaList;
    params["sort"] = {
      column: sortField,
      sortType: sortOrder,
    };
    return params;
  }

  getCommentArray = (array: any[]) => {
    const len = array.length;
    return array.slice(1, len);
  };
}
