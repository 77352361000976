<div class="login-page-container">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmitLoginForm(loginForm)">
    <div class="auth-container">
      <div class="patient-login">
        <div class="row">
          <div class="col-xs-12 col-sm-2 col-md-5 offset-md-1">
            <div class="patient-login__content sm-none">
              <div class="patient-login__content__image">
                <img alt="login" src="assets/images/login-user.png">
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-8 col-md-5">
            <div class="patient-login__content">
              <div class="patient-login__content__data">
                <div class="patient-login__content__data__logo">
                  <img alt="kevalogo" src="assets/images/kevaLogo.png">
                </div>

                <div class="form-group">
                  <h2>Physician Login</h2>
                  <p>Please enter your username or password for login.</p>
                </div>

                <div class="MT-30">
                  <div class="form-input form-control-admin MT-20">
                    <mat-form-field floatLabel="always">
                      <mat-label>Username</mat-label>
                      <input appAutofocus (blur)="onBlur(loginForm)" formControlName="username" appAutofocus
                        autocomplete="off" matInput placeholder="Enter username" required type="text" />
                      <div class="error-message error-message--validation admin-auth-validation">
                        <app-validation *ngIf="isRequiredField(formControls['username'])"
                          [errMsg]="validationMsg.USERNAME_REQUIRED"></app-validation>
                        <app-validation class="invalid" *ngIf="isValidLength(formControls['username'])"
                          [errMsg]="validationMsg.USERNAME_LENGTH"></app-validation>
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div class="MT-20">
                  <div class="form-input form-control-admin">
                    <mat-form-field floatLabel="always">
                      <mat-label>Password</mat-label>
                      <input (blur)="onBlur(loginForm)" formControlName="password" autocomplete="off" matInput
                        placeholder="Enter password" required type="password" />
                      <div class="error-message">
                        <app-validation *ngIf="isRequiredField(formControls['password'])"
                          [errMsg]="validationMsg.PASSWORD_REQUIRED"></app-validation>
                      </div>
                    </mat-form-field>
                  </div>
                </div>

                <div>
                  <div class="forgot-password-link text-right">
                    <a (click)="onForgotPasswordOpen()">Forgot Password?</a>
                  </div>
                </div>

                <div class="MT-10 row">
                  <div class="col-md-12 col-xl-12 PL-0">
                    <button [disabled]="loginForm.invalid" class="btn btn-primary admin-btn-primary" type="submit">
                      Log In
                      <mat-icon svgIcon="arrowRight"></mat-icon>
                    </button>
                  </div>

                  <div class="col-md-12 col-xl-12 PLR-0 sign-up-link">
                    Don't have an account? For sign up mail to: <a>Info@kevahealth.com</a>
                  </div>
                </div>

                <div class="MT-10 row"></div>
                <!-- <div class="MT-10 row">
                    <div class="col-md-12 col-xl-12 PLR-0 sign-up-link">
                      <a href="https://itunes.apple.com/US/app/id1436565612?mt=8">
                        <img src="assets/images/svg_files/appleStore.svg" class="width100" alt="Apple Store">
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.kevahealth">
                        <img src="assets/images/svg_files/playStore.svg" class="width100" alt="Play Store">
                      </a>
                    </div>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>