<div class="auth-container">
  <div *ngIf="!isShowSuccessMessage" class="reset-password-container">
    <form [formGroup]="resetPasswordForm" (submit)="onSubmitResetPasswordForm(resetPasswordForm)">
      <div class="patient-login">
        <div class="row">
          <div class="col-md-12 col-xl-12">
            <div class="patient-login__content">
              <div class="patient-login__content__data">
                <h2>Set your password</h2>
                <p>Please set password to avoid unauthorized access.</p>
                <div class="MT-50">
                  <div class="form-input form-control-admin">
                    <mat-form-field floatLabel="always">
                      <mat-label>Password</mat-label>
                      <input type="password" (keyup)="onCheckValue(resetPasswordForm)" matInput
                        placeholder="Enter password" formControlName="password" required />
                      <mat-error *ngIf="
                          isRequiredField(resetPasswordForm.get('password'))
                        ">
                        {{ validationMsg.PASSWORD_REQUIRED }}
                      </mat-error>
                      <mat-error *ngIf="isValidLength(resetPasswordForm.get('password'))">
                        {{ validationMsg.PASSWORD_LENGTH }}</mat-error>
                      <mat-error *ngIf="isValidField(resetPasswordForm.get('password'))">
                        {{ validationMsg.VALID_PASSWORD }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="check-validation">
                    <ul>
                      <li [ngClass]="{ active: passwordError[4] }">
                        <mat-icon svgIcon="greycheck"></mat-icon>
                        <mat-icon svgIcon="bluecheck"></mat-icon>
                        8+ character
                      </li>
                      <li [ngClass]="{ active: passwordError[0] }">
                        <mat-icon svgIcon="greycheck"></mat-icon>
                        <mat-icon svgIcon="bluecheck"></mat-icon>
                        1 Uppercase
                      </li>
                      <li [ngClass]="{ active: passwordError[1] }">
                        <mat-icon svgIcon="greycheck"></mat-icon>
                        <mat-icon svgIcon="bluecheck"></mat-icon>
                        1 Lowercase
                      </li>
                      <li [ngClass]="{ active: passwordError[3] }">
                        <mat-icon svgIcon="greycheck"></mat-icon>
                        <mat-icon svgIcon="bluecheck"></mat-icon>
                        1 Numeric
                      </li>
                      <li [ngClass]="{ active: passwordError[2] }">
                        <mat-icon svgIcon="greycheck"></mat-icon>
                        <mat-icon svgIcon="bluecheck"></mat-icon>
                        1 Special (#?!@$%^&*-)
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="MT-2">
                  <div class="form-input form-control-admin">
                    <mat-form-field floatLabel="always">
                      <mat-label>Confirm password</mat-label>
                      <input type="password" matInput placeholder="Enter confirm password"
                        formControlName="confirmPassword" required />
                      <mat-error *ngIf="
                          isRequiredField(
                            resetPasswordForm.get('confirmPassword')
                          )
                        ">
                        {{ validationMsg.CONFIRM_PASSWORD }}</mat-error>
                      <mat-error *ngIf="
                          isValidField(resetPasswordForm.get('confirmPassword'))
                        ">
                        {{ validationMsg.NOT_MATCHED }}</mat-error>
                      <mat-error *ngIf="resetPasswordForm.errors?.['doesMatchPassword']">{{ validationMsg.NOT_MATCHED }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="MT-10 row">
                  <div class="col-md-12 col-xl-12 PL-0">
                    <button class="btn btn-primary admin-btn-primary" type="submit">
                      Submit
                      <mat-icon svgIcon="arrowRight"></mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="isShowSuccessMessage && userData?.patient" class="password-succesfully-container">
    <mat-icon svgIcon="success"></mat-icon>
    <h2>Password set successfully</h2>
    <h5>Download Keva Asthma Advisor App to start managing your asthma.</h5>
    <div class="info-icon">
      <a href="https://play.google.com/store/apps/details?id=com.kevahealth">
        <mat-icon class="MR-1" svgIcon="android"></mat-icon>
      </a>
      <a href="https://itunes.apple.com/US/app/id1436565612?mt=8">
        <mat-icon svgIcon="ios"></mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="userData?.patient" class="set-password-container">
    <h2>Welcome</h2>
    <p>
      Keva Health Asthma Advisor allows you to exchange secure message with your
      healthcare provider, mange your asthma including your Action Plan,
      Symptoms, Medications, and more. Your email address
      {{ userData?.email }} will be the username to login to your account.
    </p>
    <span>Please email your question or concern to:</span>
    <a>support@kevahealthcom</a>
  </div>

  <div *ngIf="!userData?.patient" class="set-password-container">
    <h2>Keva Health Platform</h2>
    <p class="physician-text">
      Welcome to Keva Health Platform. You can set a password on this screen for
      your account. your email address {{ userData?.email }} will be the
      username to login to your account.
    </p>
    <span>Please email your question or concern to:</span>
    <a>support@kevahealthcom</a>
  </div>
</div>