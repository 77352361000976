import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

import { SharedService } from "@sharedModule/shared-service";
import {
  AppPrivateRoutes,
  HttpStatus,
  ToastStatus,
} from "@sharedModule/constants";
import { Router } from "@angular/router";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private _sharedService: SharedService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!navigator.onLine) {
      return throwError({ error: "NO INTERNET CONNECTION" });
    }
    const jwtReq = request.clone();
    // Pass on the cloned request instead of the original request.
    return next.handle(jwtReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Additional processing logic
        }
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse instanceof HttpErrorResponse) {
          if (errorResponse.error && errorResponse.error["message"]) {
            const message = errorResponse.error["message"];
            this._sharedService.setToastMessage(message, ToastStatus.ERROR);
          }
          if (errorResponse.status === HttpStatus.UNAUTHORIZED) {
            this._sharedService.logout();
          }
          if (errorResponse.status === HttpStatus.FORBIDDEN_ACCESS) {
            this.router.navigate([
              "/" + AppPrivateRoutes.UNAUTHORIZED_ACCESS_ROUTE,
            ]);
          }
        }
        return throwError(errorResponse);
      })
    );
  }
}
