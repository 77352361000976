import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

interface DropdownOption {
  dropdownValue: number;
  dropdownLabel: string;
  selected: boolean;
}

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  // Angular variables
  @ViewChild('select', { static: false }) select: MatSelect;

  /** list of options */
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() searchPlaceholder: string;
  @Input() noMatchMessage = 'No matching data';
  @Input() multiple: boolean;
  @Input() required: boolean;
  @Input() disableSelect: boolean;
  @Input() requiredMessage: string;
  @Input() options: DropdownOption[] = [];
  @Output() selectionChange = new EventEmitter<DropdownOption>();

  /** control for the MatSelect filter keyword */
  public optionFilterCtrl: FormControl = new FormControl();

  /** list of options filtered by search keyword */
  public filteredOptions: ReplaySubject<DropdownOption[]> = new ReplaySubject<
    DropdownOption[]
  >(1);

  /** Subject that emits when the component has been destroyed. */
  protected onDestroy = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  //  Life cycle methods
  ngOnInit(): void {
    // listen for search field value changes
    this.optionFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterOptions();
      });
  }

  ngOnChanges(): void {
    // load the initial option list
    this.filteredOptions.next(this.options.slice());
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  //Page event
  onSelectionChange(value: DropdownOption): void {
    this.selectionChange.emit(value);
  }

  onValueClear($event: Event): void {
    $event.stopPropagation();
    this.formControls[this.controlName].setValue('');
  }

  // Helpers
  /**
   * Sets the initial value after the filteredOptions are loaded initially
   */
  protected setInitialValue(): void {
    this.filteredOptions
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredOptions are loaded initially
        // and after the mat-option elements are available
        this.select.compareWith = (
          a: DropdownOption,
          b: DropdownOption
        ): boolean => a && b && a.dropdownValue === b.dropdownValue;
      });
  }

  protected filterOptions(): void {
    if (!this.options) {
      return;
    }
    // get the search keyword
    let search = this.optionFilterCtrl.value;
    if (!search) {
      this.filteredOptions.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the options
    this.filteredOptions.next(
      this.options.filter(
        (option) => option.dropdownLabel.toLowerCase().indexOf(search) > -1
      )
    );
  }

  isRequiredField = (formControl: AbstractControl): boolean => {
    return (
      formControl && formControl.touched && formControl.hasError('required')
    );
  };

  getTriggerValue = (): unknown => {
    if (
      this.formControls &&
      this.controlName &&
      this.formControls[this.controlName] &&
      this.formControls[this.controlName].value
    ) {
      return this.multiple
        ? this.formControls[this.controlName].value
            .map((value) => value.dropdownLabel)
            .join(', ')
        : this.formControls[this.controlName].value.dropdownLabel;
    }
    return '';
  };

  get formControls(): {
    [key: string]: AbstractControl;
  } {
    return this.formGroup && this.formGroup.controls;
  }
}
