<div class="rpm-report-view-container">
  <div class="rpm-report-view" [ngClass]="{ 'time-spent-width': false }">
    <div class="rpm-report-view__header">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-6 PLR-0">
          <h2>RPM Report</h2>
        </div>

        <div class="col-md-6 col-sm-6 col-xs-6 PLR-0">
          <ul class="btn-actions">
            <li>
              <a matTooltip="Print" (click)="onPrintClick()">
                <mat-icon>printer</mat-icon>
              </a>
            </li>

            <li>
              <a matTooltip="Download" (click)="onDownloadReport()">
                <mat-icon>download</mat-icon>
              </a>
            </li>

            <li>
              <a matTooltip="Close" (click)="onCloseReport()">
                <mat-icon>close</mat-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="rpm-report-view__pdf">
      <pdf-viewer
        *ngIf="pdfSrc"
        [src]="pdfSrc"
        [render-text]="true"
      ></pdf-viewer>
    </div>

    <div class="rpm-report-view__bottom" *ngIf="userData['recordTime'] && !isConsultant">
      <div class="row">
        <div class="col-md-4 col-sm-5 col-xs-12 PLR-0">
          <div class="rpm-report-view__bottom__time-width">
            <span>{{ clockDisplay }}</span>
            <a (click)="onChangeStatus()">
              <mat-icon
                matTooltip="Play"
                *ngIf="!isStartDetailTimer"
                svgIcon="play-icon"
              ></mat-icon>
              <mat-icon
                matTooltip="Stop"
                *ngIf="isStartDetailTimer"
                svgIcon="stop-new"
              ></mat-icon>
            </a>

            <a (click)="onChangeCallStatus()">
              <mat-icon
                *ngIf="isStartCallTimer"
                svgIcon="incoming_call"
              ></mat-icon>
              <mat-icon *ngIf="!isStartCallTimer" svgIcon="end_call"></mat-icon>
            </a>
            <span *ngIf="!isStartCallTimer" class="ML-2">{{
              callClockDisplay
            }}</span>
          </div>
        </div>

        <div class="col-md-8 col-sm-7 col-xs-12 PLR-0">
          <div class="rpm-report-view__bottom__comment">
            <a (click)="onCommentsLogDialogOpen()" class="comments-log">
              <mat-icon svgIcon="comment"></mat-icon>
              View Notes
            </a>

            <input
              placeholder="Enter note"
              type="text"
              [formControl]="commentFormControl"
            />

            <button
              class="submit-btn"
              mat-flat-button
              color="primary"
              (click)="onStopTimer(true)"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div (click)="onCloseReport()" class="gray-color-container"></div>
