import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {switchMap, debounceTime} from 'rxjs/operators';
import {of} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FormBaseComponent } from '@sharedModule/components';
import {FindMedicationModel, PatientModel} from '@privateModule/models';
import { PatientDetailsService } from '@privateModule/services';
import { AddMedFrequency, AddMedUnits } from '@sharedModule/constants';
import { removeEmptyFields } from '@sharedModule/functions';

@Component({
  selector: 'app-admin-medication-patient-details-dialog',
  templateUrl: './medication-patient-details-dialog.component.html',
  styleUrls: ['./medication-patient-details-dialog.component.scss'],
})
export class MedicationPatientDetailsDialogComponent
  extends FormBaseComponent
  implements OnInit
{
  // Angular variables
  @ViewChild('noDataInputRef') noDataInputRef: ElementRef;
  @ViewChild('medInputRef') medInputRef: ElementRef;
  @ViewChild('patientTextInputRef', {static: true})
  patientTextInputRef: ElementRef;

  // Constant variables
  addMedUnitList = AddMedUnits;
  addMedFrequencyList = AddMedFrequency;

  // Form Group Variables
  medicationDetailsForm: UntypedFormGroup;
  filterMedForm: UntypedFormGroup;
  otherMedForm: UntypedFormGroup;

  // Data variables
  medicationArray: FindMedicationModel[] = [];
  patient: PatientModel;

  // State variables
  isShowDropdown = false;

  constructor(
    fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MedicationPatientDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private patientDetailsService: PatientDetailsService,
  ) {
    super(fb);
  }

  ngOnInit() {
    this.patient = this.data['patient'];
    this.createMedicationDetailsForm();
    this.createFilterMedForm();
    this.createOtherMedForm();
    this.searchMedicationName();
    this.otherMedNameValueChanges();
  }

  // Initialisation methods
  createMedicationDetailsForm = () => {
    this.medicationDetailsForm = this.createForm({
      medicationName: ['', []],
      code: ['', [Validators.required]],
      patientText: ['', []],
      frequency: ['', [Validators.required]],
      dose: ['', [Validators.required]],
      units: ['', [Validators.required]],
      otherDetail: ['', []],
    });
  };

  createFilterMedForm = () => {
    this.filterMedForm = new UntypedFormGroup({
      medicationName: new UntypedFormControl(''),
      medicationInput: new UntypedFormControl(''),
      medicationCode: new UntypedFormControl('', [Validators.required]),
    });
  };

  createOtherMedForm = () => {
    this.otherMedForm = new UntypedFormGroup({
      otherMedicationName: new UntypedFormControl('', [Validators.required]),
    });
  };

  searchMedicationName = () => {
    this.formControls['medicationInput'].valueChanges
      .pipe(
        debounceTime(300),
        switchMap((value) => {
          this.medicationArray = [];
          // this.noMedFoundFlag = false;
          if (
            value === '' ||
            this.formControls['medicationName'].value !== value
          ) {
            this.formControls['medicationCode'].reset();
          }
          return value.length > 3
            ? this.findMedicationListApiCall(value)
            : of(this.medicationArray);
        }),
      )
      .subscribe((response) => {
        this.handleMedicationListResponse(response);
      });
  };

  handleMedicationListResponse = (response: any) => {
    if (response.constructor === Object) {
      this.medicationArray = response['payload']['content'];
      if (this.medicationArray.length === 0) {
        // this.noMedFoundFlag = true;
        this.onNoDataInputFocus();
        this.otherMedFormControls['otherMedicationName'].setValue(
          this.formControls['medicationInput'].value,
        );
      }
    } else {
      this.medicationArray = response;
    }
  };

  otherMedNameValueChanges = () => {
    this.otherMedFormControls['otherMedicationName'].valueChanges.subscribe(
      (value) => {
        if (!value) {
          // this.noMedFoundFlag = false;
          this.onMedInputFocus();
          this.formControls['medicationInput'].setValue('');
        }
      },
    );
  };

  onNoDataInputFocus() {
    setTimeout(() => {
      this.noDataInputRef.nativeElement.focus();
    }, 10);
  }

  onMedInputFocus() {
    setTimeout(() => {
      this.medInputRef.nativeElement.focus();
    }, 10);
  }

  // Api calls
  findMedicationListApiCall = (value: string) => {
    return this.patientDetailsService.findMedication(this.queryParams(value));
  };

  addMedicationApiCall = (params: any) => {
    return this.patientDetailsService.addMedication(
      params,
      this.patient.personId,
    );
  };

  // Page events
  onSubmitCreateMedicationDetailsForm = (form: UntypedFormGroup) => {
    if (this.onSubmit(form)) {
      const addMedicationParams = [removeEmptyFields(form.value)];
      this.addMedicationApiCall(
        JSON.parse(JSON.stringify(addMedicationParams)),
      ).subscribe(() => {
        this.onCancel(true);
      });
    }
  };

  changeMedicine = (medication: FindMedicationModel) => {
    if (medication instanceof Object && medication['code']) {
      this.medDetailsFormControls['code'].setValue(medication['code']);
      this.medDetailsFormControls['medicationName'].setValue(
        medication['fullName'],
      );
      this.medDetailsFormControls['patientText'].setValue('');
      this.onPatientTextInputFocus();
      this.onCloseDropdown();
    }
  };

  onSubmitOtherMedicationForm = (form: UntypedFormGroup) => {
    if (this.onSubmit(form)) {
      this.medDetailsFormControls['code'].setValue('CDA-UNK');
      this.medDetailsFormControls['medicationName'].setValue('Other');
      this.medDetailsFormControls['patientText'].setValue(
        form.value['otherMedicationName'],
      );
      this.onPatientTextInputFocus();
      this.onCloseDropdown();
    }
  };

  onPatientTextInputFocus = () => {
    setTimeout(() => {
      this.patientTextInputRef.nativeElement.focus();
    }, 10);
  };

  onShowDropdown = () => {
    this.isShowDropdown = true;
    this.onMedInputFocus();
  };

  onCloseDropdown = () => {
    this.isShowDropdown = false;
  };

  onCancel(flag: boolean): void {
    this.dialogRef.close(flag);
  }

  // Helper methods
  get medDetailsFormControls() {
    return this.medicationDetailsForm.controls;
  }

  get formControls() {
    return this.filterMedForm.controls;
  }

  get otherMedFormControls() {
    return this.otherMedForm.controls;
  }

  queryParams = (valueString: string) => {
    return {
      page: 0,
      size: 10,
      sort: {column: 'code', sortType: 'asc'},
      criteria: [{column: 'fullName', values: [valueString], operator: 'like'}],
    };
  };
}
