/** Whether the number of selected elements matches the total number of rows. */
export const isAllSelected = (dataSource, selection) => {
    const numSelected = selection.selected.length;
    const numRows = dataSource.data.length;
    return numSelected === numRows;
};

/** Selects all rows if they are not all selected; otherwise clear selection. */
export const masterToggle = (dataSource, selection) => {
    isAllSelected(dataSource, selection) ? selection.clear() : dataSource.data.forEach(row => selection.select(row));
};