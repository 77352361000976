<div class="multiple-download-container">
  <div class="multiple-download-container__header flex-between">
    <h4>Downloading files</h4>
    <a
      class="lnkUpDown"
      [ngClass]="{ lnkUp: isMinimized }"
      (click)="onMinimizeMaximize()"
    >
      <mat-icon svgIcon="dropdown-header"></mat-icon>
    </a>
  </div>
  <div
    class="multiple-download-container__file-list"
    [ngClass]="{ hideList: isMinimized }"
  >
    <div class="multiple-download-container__file-list-content">
      <div
        class="multiple-download-container__file-list-file"
        *ngFor="let task of downloadTaskList; let i = index"
      >
        <div class="file-box flex-between">
          <div class="file-box__details flex-between">
            <mat-icon svgIcon="download"></mat-icon>
            <div class="file-box__details-file-name">
              <p>
                {{ task.fileName }}
              </p>
            </div>
          </div>
          <div
            *ngIf="task.status !== taskStatus.Failed"
            class="file-box__progress flex-between"
          >
            <mat-progress-bar
              mode="determinate"
              [value]="task.progress"
            ></mat-progress-bar>
            <div class="file-box__progress-status">
              <div
                *ngIf="task.status === taskStatus.Started"
                class="file-box__progress-status__spinner"
              >
                <mat-spinner></mat-spinner>
              </div>
              <div
                *ngIf="task.status === taskStatus.InProgress"
                class="file-box__progress-status__percent"
              >
                <span>{{ task.progress }}%</span>
              </div>
              <div
                *ngIf="task.status === taskStatus.Completed"
                class="file-box-status__progress__success"
              >
                <mat-icon svgIcon="success"></mat-icon>
              </div>
            </div>
          </div>
          <div
            *ngIf="task.status === taskStatus.Failed"
            class="file-box__error flex-between"
          >
            <span>Download Error</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
