<div class="unauthorize-access-container">
  <div tabindex="1"></div>
  <mat-dialog-content>
    <div class="unauthorize-access-container__header MB-2">
      <mat-icon svgIcon="unauthorize_access"></mat-icon>
    </div>
    <div class="unauthorize-access-container__content">
      <h1 class="MB-2">You don't have the privileges to access this page</h1>
      <p class="MB-4">
        Please try again with another keywords or contact your administrator for
        more information.
      </p>
    </div>

    <div class="row unauthorize-access-container__bottom">
      <div class="col-md-12 col-sm-12 PL-0 PR-0">
        <button
          (click)="onClickHome()"
          mat-flat-button
          class="btn-home MR-2"
          tabindex="2"
        >
          Home
        </button>
        <!-- <button
          (click)="close()"
          mat-flat-button
          color="primary"
          class="btn-back"
          tabindex="3"
        >
          Back
        </button> -->
      </div>
    </div>
  </mat-dialog-content>
</div>
