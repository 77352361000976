import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  HttpMethodsTypeEnum,
  AdminApi,
} from '@sharedModule/constants';
import { APIManager, SharedUserService } from '@sharedModule/shared-service';

@Injectable({
  providedIn: 'root',
})
export class AdminForgotPasswordService {
  constructor(
    private _apiManager: APIManager,
    private adminSharedService: SharedUserService,
  ) {}

  forgotPassword(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FORGOT_PASSWORD_EMAIL,
      params,
      this._apiManager.Content_Type_HttpOptions,
      false,
      true,
    );
  }

  verifyForgotPasswordVerificationCode(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FORGOT_PASSWORD_VERIFY_CODE,
      params,
      this.adminSharedService.Sign_Up_Process_Header,
      false,
      true,
    );
  }

  resendForgotPasswordVerificationCode(): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      AdminApi.FORGOT_PASSWORD_RESEND_CODE,
      {},
      this.adminSharedService.Sign_Up_Process_Header,
      false,
      true,
    );
  }

  resetPassword(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FORGOT_PASSWORD_RESET,
      params,
      this.adminSharedService.Sign_Up_Process_Header,
      false,
      true,
    );
  }
}
