import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

import { MessageModel } from "@privateModule/models";
import { MessageTypeEnum } from "@sharedModule/constants";
import { getMessageActionLabel } from "@sharedModule/functions";

@Component({
  selector: "app-admin-advisor-message-details",
  templateUrl: "./advisor-message-details.component.html",
  styleUrls: ["./advisor-message-details.component.scss"],
})
export class AdvisorMessageDetailsComponent implements OnInit {
  // Data variables
  messageDetail: MessageModel;

  // State variables
  isFromSent = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AdvisorMessageDetailsComponent>
  ) {}

  ngOnInit() {
    this.messageDetail = this.data["message"];
    if (this.data["isFromSent"]) {
      this.isFromSent = this.data["isFromSent"];
    }
  }

  // Page events
  onCancel(performAction: boolean = false): void {
    this.dialogRef.close({
      performAction: performAction,
      action: this.messageDetail?.action,
    });
  }

  // Helper methods
  getSubject = (messageDetail: MessageModel) => {
    if (this.isFromSent) {
      return this.getActionLabel(messageDetail.action);
    } else {
      return messageDetail.message;
    }
  };

  getActionLabel = (action: string) => {
    const act = MessageTypeEnum.find((record) => record.value === action);
    if (act) {
      return act.label;
    } else {
      return action;
    }
  };

  getMessageActionLabel = (action: string) => {
    return getMessageActionLabel(action);
  };
}
