<button
  class="add-patient-btn"
  mat-flat-button
  color="primary"
  #paletteMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="exportMenu"
>
  Export File
</button>
<mat-menu #exportMenu backdropClass="mat-menu">
  <div
    class="export-files-container"
    (click)="$event.stopPropagation()"
  >
    <h3>Export Files</h3>
    <span class="divider"></span>
    <div class="export-files-container__filter">
      <mat-radio-group aria-label="filter" [formControl]="allFilterTypeControl">
        <mat-radio-button [value]="true">All</mat-radio-button>
        <mat-radio-button [value]="false">Current Filter</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="export-btn">
      <button
        mat-flat-button
        color="primary"
        (click)="onClickExport(); paletteMenuTrigger.closeMenu()"
      >
        Export File
      </button>
    </div>
  </div>
</mat-menu>
