<div class="message-details-dialog-container">
  <div class="message-details-dialog">
    <div class="message-details-dialog__header">
      <div class="row">
        <div class="col-md-9 col-xs-10 col-sm-10 PL-0">
          <h4>
            {{ messageDetail?.name }}
            <span>(ID : {{ messageDetail?.personId }})</span>
          </h4>
        </div>

        <div class="col-md-3 col-xs-2 col-sm-2 PR-0">
          <div class="dialog-section__close">
            <a (click)="onCancel()">
              <mat-icon svgIcon="close"></mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="message-details-dialog__body">
      <div *ngIf="isFromSent" class="MB-3">
        <label>To :</label>
        <span>{{ messageDetail?.recipientName | checkEmpty }}</span>
      </div>

      <div class="MB-3">
        <label>Date & Time :</label>
        <span>{{
          messageDetail?.userDateCreated | date : "d, MMMM y h:mm a"
        }}</span>
      </div>

      <div class="MB-3">
        <label>Subject :</label>
        <span>{{ getSubject(messageDetail) | checkEmpty }}</span>
      </div>

      <div class="MB-3">
        <label>Description :</label>
        <span>{{
          (isFromSent ? messageDetail?.message : messageDetail?.description)
            | checkEmpty
        }}</span>
      </div>

      <div *ngIf="false" class="MB-3">
        <label>Link :</label>
        <span
          ><a
            >Community.aafa.org/blog/coronavirus-2019-ncov-flu-what-people-with-
            asthma-need-to-know</a
          ></span
        >
      </div>
    </div>

    <div
      class="message-details-dialog__footer text-right"
      *ngIf="messageDetail?.action && !isFromSent"
    >
      <button
        class="send-button ML-2"
        mat-flat-button
        color="primary"
        (click)="this.onCancel(true)"
      >
        {{ getMessageActionLabel(messageDetail?.action) }}
      </button>
    </div>
  </div>
</div>
