import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AdvisorHeaderComponent } from "./../app/components";
import { AdvisorSvgIconsComponent } from "@sharedModule/components";
import { SharedModule } from "@sharedModule/shared.module";
import { AdvisorAuthModule } from "@publicModule/advisor-auth.module";
import { PrivateModule } from "@privateModule/private.module";

@NgModule({
  declarations: [
    AdvisorHeaderComponent,
    AppComponent,
    AdvisorSvgIconsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AdvisorAuthModule,
    PrivateModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    SharedModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
