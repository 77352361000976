import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import { AdminApi, HttpMethodsTypeEnum } from '@sharedModule/constants';
import { APIManager } from '@sharedModule/shared-service';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordService {
  constructor(private _apiManager: APIManager) {}

  changePassword(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.RESET_PASSWORD,
      params,
      this._apiManager.HttpOptions,
      true,
      true,
    );
  }
}
