import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '@sharedModule/shared.module';
import {AdvisorAuthRoutingModule} from '@publicModule/advisor-auth-routing.module';

import {
  AdvisorForgotOtpComponent,
  AdvisorForgotPasswordComponent,
  AdvisorLoginComponent,
  AdvisorLoginOtpComponent,
  AdvisorResetPasswordComponent,
  AppleSiteAssociationComponent,
  SetPasswordComponent,
} from '@publicModule/components';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule, AdvisorAuthRoutingModule],
  declarations: [
    AdvisorLoginComponent,
    AdvisorForgotPasswordComponent,
    AdvisorForgotOtpComponent,
    AdvisorResetPasswordComponent,
    SetPasswordComponent,
    AdvisorLoginOtpComponent,
    AppleSiteAssociationComponent,
  ],
})
export class AdvisorAuthModule {}