import { NativeDateAdapter } from "@angular/material/core";
import * as FileSaver from "file-saver";

import { PatientModel } from "@privateModule/models";
import { DEFAULT_CHARSET, PhysicianMessageType } from "@sharedModule/constants";

// declare const $;

export const isEmpty = (obj): boolean =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const AppLogger = (value: any) => {
  // console.log(value);
};

/**
 * Copy value function
 * @param value
 * returns {boolean}
 * constructor
 */
export function ClipboardText(value: string): boolean {
  try {
    const inp = document.createElement("input");
    document.body.appendChild(inp);
    inp.value = value;
    inp.select();
    document.execCommand("copy", false);
    inp.remove();
    return true;
  } catch (e) {
    return false;
  }
}

export function isValidImageType(filename: string): boolean {
  return (
    filename === "image/png" ||
    filename === "image/jpeg" ||
    filename === "image/jpg" ||
    filename === "image/gif"
  );
}

export function Copy(o) {
  return JSON.parse(JSON.stringify(o));
}

export function findWithAttr(array, attr, value) {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

export const getCriteria = (column, values, operator) => ({
  column,
  values: [values],
  operator,
});

export function removeEmptyFields(params: any) {
  for (const field of Object.keys(params)) {
    if (params.hasOwnProperty(field)) {
      if (Array.isArray(params[field])) {
        if (checkEmptyValue(params[field])) {
          delete params[field];
        } else {
          params[field].forEach((record) => {
            removeEmptyFields(record);
          });
          params[field] = params[field].filter(
            (value) => !checkEmptyValue(value)
          );
          if (checkEmptyValue(params[field])) {
            delete params[field];
          }
        }
      } else if (typeof params[field] === "object") {
        if (checkEmptyValue(params[field])) {
          delete params[field];
        } else {
          removeEmptyFields(params[field]);
          if (checkEmptyValue(params[field])) {
            delete params[field];
          }
        }
      } else {
        if (checkEmptyValue(params[field])) {
          delete params[field];
        }
      }
    }
  }
  return params;
}

export function checkEmptyValue(value) {
  if (value === null || value === undefined) {
    return true;
  } else if (typeof value === "string" && value === "") {
    return true;
  } else if (Array.isArray(value) && value.length === 0) {
    return true;
  } else if (
    value.constructor === Object &&
    Object.entries(value).length === 0
  ) {
    return true;
  }
  return false;
}

export const saveFile = (response, fileName) => {
  FileSaver.saveAs(response, fileName);
};

export class CustomDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

export function transformTimerValue(value: number): string {
  const minutes: number = Math.floor(value / 60);
  const seconds = value - minutes * 60;
  return (
    (minutes > 9 ? minutes : "0" + minutes) +
    ":" +
    (seconds > 9 ? seconds : "0" + seconds)
  );
}

export function getNameInitialsFunction(patient: PatientModel) {
  const first = patient.first.slice(0, 1).toUpperCase();
  const last = patient.last.slice(0, 1).toUpperCase();
  return first + last;
}

export function getMessageActionLabel(action: string) {
  switch (action) {
    case PhysicianMessageType.REVIEW_MEDICATION:
      return "Review Medication";
    case PhysicianMessageType.ADD_MEDICATION:
      return "Add Medication";
    case PhysicianMessageType.REVIEW_PEAK_FLOW:
      return "Review Peakflow";
    case PhysicianMessageType.REVIEW_ACTION_PLAN:
      return "Review Action Plan";
    case PhysicianMessageType.REVIEW_ALLERGIES:
      return "Review Allergies";
    case PhysicianMessageType.REVIEW_QUESTIONNAIRE:
      return "Review Questionnaire";
    case PhysicianMessageType.REVIEW_DEVICES:
      return "Review Devices";
    case PhysicianMessageType.REVIEW_SPIROMETRY_DATA:
      return "Review Spirometry Data";
    default:
      return action;
  }
}

export const convertToUserTimeZone = (
  date: string | number | Date,
  timeZone: string
) => {
  return new Date(date).toLocaleString("en-US", {
    timeZone: timeZone,
  });
};

export const createUUID = (length: number, characterString: string = DEFAULT_CHARSET): string => {
  const charset = checkEmptyValue(characterString) ? DEFAULT_CHARSET : characterString;
  let result = '';
  const crypto = window.crypto;
  const randomValueArray = new Uint32Array(length);

  crypto.getRandomValues(randomValueArray);
  for (let i = 0; i < length; i++) {
      result += charset[randomValueArray[i] % charset.length];
  }
  return result;
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export const getTimeSpent = (seconds: number) => {
  let hours;
  let minutes;
  hours = Math.floor(seconds / 3600);
  minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h ${minutes}m`;
}
