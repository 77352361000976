import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AdminApi, HttpMethodsTypeEnum } from "@sharedModule/constants";
import { APIManager } from "@sharedModule/shared-service";

@Injectable({
  providedIn: "root",
})
export class PatientDetailsService {
  constructor(private apiManager: APIManager) {}

  getPatientDetails(id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.PATIENT_DETAILS}/${id}`,
      {},
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getPatientEngagementReportData(id: number, params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.ENGAGEMENT_REPORT}/${id}`,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getPatientEventList(params, loader: boolean): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PATIENT_EVENT_LIST,
      params,
      this.apiManager.HttpOptions,
      false,
      loader
    );
  }

  startDetailsTimer(personId: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.PATIENT}/${personId}/details/start`,
      {},
      this.apiManager.HttpOptions,
      false,
      false
    );
  }

  stopDetailsTimer(
    personId: number,
    params: any,
    showLoader: boolean
  ): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.PATIENT}/${personId}/details/stop`,
      params,
      this.apiManager.HttpOptions,
      false,
      showLoader
    );
  }

  getPatientPeakFlowChartData = (params: any, id: number, loader: boolean) => {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.PEAK_FLOW}/${id}`,
      params,
      this.apiManager.HttpOptions,
      false,
      loader
    );
  };

  getMedicationLogs = (params: any, loader: boolean) => {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.MEDICATION_LOGS,
      params,
      this.apiManager.HttpOptions,
      false,
      loader
    );
  };

  getPatientMedicationList = (params: any, loader: boolean) => {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PATIENT_MEDICATION_LIST,
      params,
      this.apiManager.HttpOptions,
      false,
      loader
    );
  };

  removeMedication(params: any, id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.REMOVE_MEDICATIONS}/${id}`,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getActionPlan(id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.ACTION_PLAN}/${id}`,
      {},
      this.apiManager.HttpOptions,
      false,
      false
    );
  }

  updateActionPlan(url: string, params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.ACTION_PLAN}/${url}`,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  findMedication(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FIND_MEDICATION,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  addMedication(params: any, id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.ADD_MEDICATION}/${id}`,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getRescueChartData = (params: any) => {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.RESCUE_CHART,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  };

  getControllerChartData = (params: any) => {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.CONTROLLER_CHART,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  };

  getUserSummaryData = (params: any) => {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.USER_SUMMARY_DATA,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  };

  getQuestionnaire = (params: any, id: number, loader: boolean) => {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.QUESTIONNAIRE}/${id}`,
      params,
      this.apiManager.HttpOptions,
      false,
      loader
    );
  };

  getQuestionnaireDetails = (id: number) => {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.QUESTIONNAIRE_DETAILS}/${id}`,
      {},
      this.apiManager.HttpOptions,
      false,
      true
    );
  };

  //NOT in use after V1
  oximeterChartDetails(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.OXIMETER_CHART_DETAILS,
      params,
      this.apiManager.HttpOptions,
      false,
      false
    );
  }

  oximeterChartDetailsV1(params: any, loader: boolean): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.OXIMETER_CHART_DETAILS_V1,
      params,
      this.apiManager.HttpOptions,
      false,
      loader
    );
  }

  weightScaleChartDetails(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.WEIGHT_SCALE_CHART_DETAILS,
      params,
      this.apiManager.HttpOptions,
      false,
      false
    );
  }
}
