<div class="reset-password-container">
  <form (submit)="onSubmitResetPasswordForm(resetPasswordForm)" [formGroup]="resetPasswordForm">
    <div class="patient-login">
      <div class="row">
        <div class="col-md-12 col-xl-12">
          <div class="patient-login__content">
            <div class="patient-login__content__data">
              <h2>Reset your password</h2>
              <p>Please set password to avoid unauthorized access.</p>
              <div class="MT-20">
                <div class="form-input form-control-admin">
                  <mat-form-field floatLabel="always">
                    <mat-label>Password</mat-label>
                    <input type="password" (keyup)="onCheckValue(resetPasswordForm)" matInput
                      placeholder="Enter password" formControlName="password" required />
                    <mat-error *ngIf="isRequiredField(resetPasswordForm.get('password'))">
                      {{validationMsg.PASSWORD_REQUIRED}}
                    </mat-error>
                    <mat-error *ngIf="isValidLength(resetPasswordForm.get('password'))">
                      {{validationMsg.PASSWORD_LENGTH}}</mat-error>
                    <mat-error *ngIf="isValidField(resetPasswordForm.get('password'))">
                      {{validationMsg.VALID_PASSWORD}}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="check-validation">
                  <ul>
                    <li [ngClass]="{'active': passwordError[4]}">
                      <mat-icon svgIcon="greycheck"></mat-icon>
                      <mat-icon svgIcon="bluecheck"></mat-icon> 8+ character
                    </li>
                    <li [ngClass]="{'active': passwordError[0]}">
                      <mat-icon svgIcon="greycheck"></mat-icon>
                      <mat-icon svgIcon="bluecheck"></mat-icon> 1 Uppercase
                    </li>
                    <li [ngClass]="{'active': passwordError[1]}">
                      <mat-icon svgIcon="greycheck"></mat-icon>
                      <mat-icon svgIcon="bluecheck"></mat-icon> 1 Lowercase
                    </li>
                    <li [ngClass]="{'active': passwordError[3]}">
                      <mat-icon svgIcon="greycheck"></mat-icon>
                      <mat-icon svgIcon="bluecheck"></mat-icon> 1 Numeric
                    </li>
                    <li [ngClass]="{'active': passwordError[2]}">
                      <mat-icon svgIcon="greycheck"></mat-icon>
                      <mat-icon svgIcon="bluecheck"></mat-icon> 1 Special (#?!@$%^&*-)
                    </li>
                  </ul>
                </div>
              </div>
              <div class="MT-2">
                <div class="form-input form-control-admin">
                  <mat-form-field floatLabel="always">
                    <mat-label>Confirm password</mat-label>
                    <input type="password" matInput placeholder="Enter confirm password"
                      formControlName="confirmPassword" required />
                    <mat-error *ngIf="isRequiredField(resetPasswordForm.get('confirmPassword'))">
                      {{validationMsg.CONFIRM_PASSWORD}}</mat-error>
                    <mat-error *ngIf="isValidField(resetPasswordForm.get('confirmPassword'))">
                      {{validationMsg.NOT_MATCHED}}</mat-error>
                    <mat-error *ngIf="resetPasswordForm.errors?.doesMatchPassword">{{validationMsg.NOT_MATCHED}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="MT-10 row">
                <div class="col-md-12 col-xl-12 PL-0">
                  <button [disabled]="resetPasswordForm.invalid" class="btn btn-primary admin-btn-primary"
                    type="submit"> Submit
                    <mat-icon svgIcon="arrowRight"></mat-icon>
                  </button>
                  <!-- <a (click)="onClickLogIn()">Cancel</a> -->
                  <button class="back-btn-auth" (click)="onClickLogIn()" mat-button>Back</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <div class="auth-info-container">
    <app-admin-auth-info></app-admin-auth-info>
  </div> -->