<div class="message-compose-dialog-container">
  <div class="message-compose-dialog">
    <div class="message-compose-dialog__header">
      <h4>Compose</h4>
    </div>

    <form
      (submit)="onSubmitSummaryComposeForm(summaryComposeForm)"
      [formGroup]="summaryComposeForm"
    >
      <div class="message-compose-dialog__body">
        <div *ngIf="data" class="admin-form-control">
          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>To&nbsp;</mat-label>
            <span>{{ getNameInitials() }}</span>
            <input
              required
              class="name-profile"
              autocomplete="off"
              matInput
              placeholder="Enter name"
              formControlName="to"
              type="text"
            />
            <mat-error *ngIf="isRequiredField(formControls['to'])">{{
              validationMsg.TO
            }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="!data" class="admin-form-control">
          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>To</mat-label>
            <mat-select
              formControlName="toUser"
              placeholder="Select patient"
              multiple="true"
              required
            >
              <mat-option>
                <ngx-mat-select-search
                  noEntriesFoundLabel="No matching data"
                  [clearSearchInput]="false"
                  placeholderLabel="Search"
                  [formControl]="patientSearchField"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                [value]="patient"
                *ngFor="let patient of patientList"
                >{{ patient?.name }} ({{ patient?.email }})
              </mat-option>
            </mat-select>
            <mat-error *ngIf="isRequiredField(formControls['toUser'])"
              >{{ validationMsg.PATIENT }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="admin-form-control">
          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>Action&nbsp;</mat-label>
            <mat-select
              required
              placeholder="Select reply"
              formControlName="action"
            >
              <mat-option
                *ngFor="let type of messageTypeEnum"
                [value]="type?.value"
                >{{ type?.label }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="isRequiredField(formControls['action'])">{{
              validationMsg.ACTION
            }}</mat-error>
          </mat-form-field>
        </div>

        <div class="admin-form-control message-field message-error">
          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>Message&nbsp;</mat-label>
            <textarea
              required
              rows="5"
              matInput
              placeholder="Enter message"
              formControlName="messageHeader"
            ></textarea>
            <mat-error *ngIf="isRequiredField(formControls['messageHeader'])">{{
              validationMsg.MESSAGE
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="message-compose-dialog__footer">
        <button
          class="close-button"
          mat-flat-button
          mat-dialog-close
          color="primary"
        >
          Close
        </button>
        <button
          class="send-button ML-2"
          mat-flat-button
          color="primary"
          [disabled]="isLoading"
        >
          Send
        </button>
      </div>
    </form>
  </div>
</div>
