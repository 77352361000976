<form *ngIf="formGroup" [formGroup]="formGroup">
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      [disabled]="disableSelect"
      disableOptionCentering
      [required]="required"
      [formControlName]="controlName"
      [placeholder]="placeholder"
      [multiple]="multiple"
      (selectionChange)="onSelectionChange($event.value)"
      #select
    >
      <mat-select-trigger>{{ getTriggerValue() }}</mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          [formControl]="optionFilterCtrl"
          [placeholderLabel]="searchPlaceholder"
          [noEntriesFoundLabel]="noMatchMessage"
        >
          <mat-icon
            ngxMatSelectSearchClear
            svgIcon="cross"
            matTooltip="Clear"
          ></mat-icon>
        </ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option.dropdownLabel }}
      </mat-option>
    </mat-select>
    <a
      *ngIf="!required && !!formControls[this.controlName].value"
      (click)="onValueClear($event)"
      class="form-control--clear__icon"
    >
      <mat-icon svgIcon="cross" matTooltip="Clear"></mat-icon>
    </a>
    <mat-error *ngIf="isRequiredField(formControls[controlName])"
      >{{ requiredMessage }}
    </mat-error>
  </mat-form-field>
</form>
