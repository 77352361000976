import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  AdminApi,
  HttpMethodsTypeEnum,
} from '@sharedModule/constants';
import { APIManager } from '@sharedModule/shared-service';

@Injectable({
  providedIn: 'root',
})
export class AdvisorPatientsListService {
  constructor(private _apiManager: APIManager) {}

  getPatientList(params: any, showLoader: boolean = true): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PATIENT_LIST,
      params,
      this._apiManager.HttpOptions,
      false,
      showLoader,
    );
  }

  exportPatientList(params: any): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.EXPORT_PATIENT_LISTING,
      params,
      this._apiManager.Blob_Progress_HttpOptions,
      false,
      false,
    );
  }
}
