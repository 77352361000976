<div class="rpm-report-view-container">
  <div class="rpm-report-view">
    <div class="rpm-report-view__rpm-report-view-header">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12 PLR-0">
          <h2>Time Spent Detail</h2>
        </div>

        <div class="col-md-6 col-sm-6 col-xs-12 PLR-0 ">
          <ul class="btn-actions">
            <li>
              <a matTooltip="Print" (click)="onPrintClick()">
                <mat-icon>printer</mat-icon>
              </a>
            </li>

            <li>
              <a matTooltip="Download" (click)="onDownloadReport()">
                <mat-icon>download</mat-icon>
              </a>
            </li>

            <li>
              <a matTooltip="Close" (click)="onCloseReport()">
                <mat-icon>close</mat-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="rpm-report-view__rpm-report-view-pdf">
      <pdf-viewer *ngIf="pdfSrc" [src]="pdfSrc" [render-text]="true"></pdf-viewer>
    </div>
  </div>
</div>

<div (click)="onCloseReport()" class="gray-color-container"></div>