import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

import {SYSTEM_SVG_IMAGES} from './advisor-svg-icons.constant';

@Component({
  selector: 'app-admin-advisor-svg-icons',
  templateUrl: './advisor-svg-icons.component.html',
  styleUrls: ['./advisor-svg-icons.component.scss'],
})
export class AdvisorSvgIconsComponent implements OnInit {
  BASE_DIR_PATH = './assets/images/svg/';
  IMAGE_FORMAT = '.svg';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    SYSTEM_SVG_IMAGES.forEach((image_name) => {
      this.setSvgImage(image_name);
    });
  }

  setSvgImage(image_name: string) {
    this.matIconRegistry.addSvgIcon(
      image_name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${this.BASE_DIR_PATH + image_name + this.IMAGE_FORMAT}`,
      ),
    );
  }
}
