import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

import { AppPrivateRoutes, AppPublicRoutes } from "@sharedModule/constants";
import { SharedService, SharedUserService } from "@sharedModule/shared-service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _sharedService: SharedService,
    private adminSharedService: SharedUserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let activateRoute = true;
    const readURL = state.url.split("?")[0];

    // redirect to login or course according to logged in status and current url
    if (this._sharedService.isLoggedInAdmin()) {
      // check admin access for audit and device routes
      if (
        this.getAdminRoutes(readURL) &&
        !this._sharedService.isSystemAdmin()
      ) {
        this.router.navigate(["/" + AppPrivateRoutes.PATIENTS_ROUTE]);
        activateRoute = false;
      }

      if (
        this._sharedService.isConsultant() &&
        !readURL.includes(`/${AppPrivateRoutes.PATIENTS_DETAILS_ROUTE}`)
      ) {
        const patients = this._sharedService.getPatientListData();
        if (patients.length) {
          this.router.navigate([
            "/" + AppPrivateRoutes.PATIENTS_DETAILS_ROUTE,
            patients[0].personId,
          ]);
        }
        activateRoute = false;
      }

      if (this.getPublicRoutes(readURL)) {
        this.router.navigate(["/" + AppPrivateRoutes.PATIENTS_ROUTE]);
        activateRoute = false;
      }
    } else {
      if (!this.getPublicRoutes(readURL)) {
        this.adminSharedService.setRedirectUrl(readURL);
        this.router.navigate(["/" + AppPublicRoutes.LOGIN]);
        activateRoute = false;
      }
    }
    return activateRoute;
  }

  getAdminRoutes = (readURL: string) => {
    return [
      "/" + AppPrivateRoutes.BILLING_ROUTE,
      "/" +
        AppPrivateRoutes.BILLING_ROUTE +
        "/" +
        AppPrivateRoutes.BILLING_PROGRESS_ROUTE,
      "/" +
        AppPrivateRoutes.BILLING_ROUTE +
        "/" +
        AppPrivateRoutes.BILLING_REPORT_ROUTE,
      "/" + AppPrivateRoutes.AUDIT_ROUTE,
      "/" +
        AppPrivateRoutes.AUDIT_ROUTE +
        "/" +
        AppPrivateRoutes.AUDIT_TIME_SPENT_ROUTE,
      "/" + AppPrivateRoutes.DEVICES_ROUTE,
    ].includes(readURL);
  };

  getPublicRoutes = (readURL: string) => {
    return [
      "/" + AppPublicRoutes.SET_PASSWORD,
      "/" + AppPublicRoutes.LOGIN,
      "/" + AppPublicRoutes.LOGIN_OTP,
      "/" + AppPublicRoutes.FORGOT_PASSWORD,
      "/" + AppPublicRoutes.FORGOT_OTP,
      "/" + AppPublicRoutes.RESET_PASSWORD,
    ].includes(readURL);
  };
}
