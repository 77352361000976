import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AdminApi, HttpMethodsTypeEnum } from "@sharedModule/constants";
import { APIManager } from "@sharedModule/shared-service";

@Injectable({
  providedIn: "root",
})
export class AdminPatientService {
  constructor(private apiManager: APIManager) {}

  getRPMReport(personId: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.PATIENT}/${personId}`,
      {},
      this.apiManager.Blob_HttpOptions,
      false,
      true
    );
  }

  startRPMReportTimer(personId: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.PATIENT}/${personId}/start`,
      {},
      this.apiManager.HttpOptions,
      false,
      false
    );
  }

  stopRPMReportTimer(
    personId: number,
    params: any,
    showLoader: boolean
  ): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.PATIENT}/${personId}/stop`,
      params,
      this.apiManager.HttpOptions,
      false,
      showLoader
    );
  }

  getOrganizationList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FILTER_ORGANISATION,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getDiagnosisList(): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      AdminApi.DIAGNOSIS,
      {},
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getDiagnosisFilter(params: { [key: string]: unknown }): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FILTER_DIAGNOSIS,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getClinicianList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FILTER_CLINICIAN,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getPhysicianList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FILTER_PHYSICIAN,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  addUser(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.USER,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  editUser(params: any, id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.PUT,
      `${AdminApi.USER}/${id}`,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  addPatient(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PATIENT,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  addBillingTime(params: any, patientId: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PATIENT + `/${patientId}` + "/add/manualTime",
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  editPatient(params: any, id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.PUT,
      `${AdminApi.PATIENT}/${id}`,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  deactivatePatient(id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.DELETE,
      `${AdminApi.PATIENT}/${id}`,
      {},
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  activatePatient(id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.ACTIVATE_PATIENT}/${id}`,
      {},
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  unlockUser(id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.UNLOCK_PATIENT}/${id}`,
      {},
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  getLatestCheckInData(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.LATEST_CHECKIN,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  getAllergies(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.ALLERGIES,
      params,
      this.apiManager.HttpOptions,
      false,
      false
    );
  }

  rpmReportAction(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.RPM_REPORT_ACTION,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  startCallTimer(personId: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.PATIENT}/call/${personId}/start`,
      {},
      this.apiManager.HttpOptions,
      false,
      false
    );
  }

  stopCallTimer(personId: number, params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.PATIENT}/call/${personId}/stop`,
      params,
      this.apiManager.HttpOptions,
      false,
      false
    );
  }

  deviceChartDetails(params: any, loader: boolean): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.DEVICE_CHART_DETAILS,
      params,
      this.apiManager.HttpOptions,
      false,
      loader
    );
  }

  getBillingData(personId: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.BILLING}/data/${personId}`,
      {},
      this.apiManager.Blob_HttpOptions,
      false,
      true
    );
  }

  getSpirometryList(params: any, loader: boolean): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FILTER_SPIROMETER,
      params,
      this.apiManager.HttpOptions,
      false,
      loader
    );
  }

  getCountryList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.COUNTRY,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getStateList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.STATE,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getCityList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.CITY,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  addAddress(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.ADDRESS,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  updateAddress(id: number, params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.PUT,
      `${AdminApi.ADDRESS}/${id}`,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  getAddressData(id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.ADDRESS_LIST}/${id}`,
      {},
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  getDevicesData(id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.DEVICES_LIST}/${id}`,
      {},
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getVendorList(): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      AdminApi.VENDOR_LIST,
      {},
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  getVendorDeviceList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.VENDOR_DEVICE_LIST,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }

  addDevice(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.DEVICE,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  editDevice(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.PUT,
      AdminApi.DEVICE,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  removeDevice(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.DEVICE_REMOVE,
      params,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  oximeterReadingList(param: any, loader: boolean): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FILTER_OXIMETER,
      param,
      this.apiManager.HttpOptions,
      true,
      loader
    );
  }

  weightScale(param: any, loader: boolean): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.FILTER_WEIGHT_SCALE,
      param,
      this.apiManager.HttpOptions,
      true,
      loader
    );
  }

  patientDetailsCommentLog(param: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PATIENT_DETAILS_COMMENT_LOG,
      param,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  patientReviewCommentLog(param: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PATIENT_REVIEW_COMMENT_LOG,
      param,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }
}
