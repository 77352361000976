<div class="dialog-container confirmation-dialog-container">
  <div>
    <p>{{caption}}</p>

    <div>
      <button (click)="onSave()" id="btnSave" mat-flat-button color="warn" type="submit">
        {{primaryButtonLabel}}
      </button>
      <button (click)="onCloseDialog()" id="btnClose" mat-button>{{secondaryButtonLabel}}</button>
    </div>
  </div>
</div>