/**
 *
 * Author: smartSense Consulting Solutions Pvt. Ltd.
 * Website: https://smartsensesolutions.com
 * Date: Sep 24 2018 11:30 AM
 * Copyright @ 2018 smartSense Consulting Solutions Pvt. Ltd.
 *
 */
import { ElementRef, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MatDialog } from "@angular/material/dialog";

import { SharedUserService } from "./shared-user.service";
import { EncryptionFunctions, AppLogger } from "@sharedModule/functions";
import { ToastStatus, APPStorage, UserRole } from "@sharedModule/constants";
import { FileModel } from "@sharedModule/components/download-progress/download-progress.model";
@Injectable({
  providedIn: "root",
})
export class SharedService extends SharedUserService {
  helper = new JwtHelperService();
  private loaderCount = 0;
  private _token = "";
  private _VToken = "";
  private _refreshToken = "";
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private allowToResendOTP = 0;
  private msgBody: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /* Initiating Timer */
  private initiateTimer: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  /* Shared LoggedIn Param */
  private isLoginRequired: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private cacheObj = {};

  private taskSubject = new BehaviorSubject<any>([]);

  constructor(private router: Router, private dialogRef: MatDialog) {
    super();
  }

  getTimer(): Observable<boolean> {
    return this.initiateTimer.asObservable();
  }

  setTimer(val: boolean): void {
    this.initiateTimer.next(val);
  }

  // return Observable of loading
  getLoader(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  getToken(): string {
    this._token = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.TOKEN)
    );
    return this._token;
  }

  setToken(value: string): void {
    sessionStorage.setItem(
      APPStorage.TOKEN,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this._token = value;
  }

  getResendOTP(): number {
    this.allowToResendOTP = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.RESEND_OTP)
    );
    return this.allowToResendOTP;
  }

  setResendOTP(value: number): void {
    sessionStorage.setItem(
      APPStorage.RESEND_OTP,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.allowToResendOTP = value;
  }

  getVerificationToken(): string {
    this._VToken = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.VERIFICATION_TOKEN)
    );
    return this._VToken;
  }

  setVerificationToken(value: string): void {
    sessionStorage.setItem(
      APPStorage.VERIFICATION_TOKEN,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this._VToken = value;
  }

  /**
   * for storing and retrieve refresh token
   *
   */
  getRefreshToken(): string {
    this._refreshToken = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.REFRESH_TOKEN)
    );
    return this._refreshToken;
  }

  setRefreshToken(value: string): void {
    sessionStorage.setItem(
      APPStorage.REFRESH_TOKEN,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this._refreshToken = value;
  }

  IsValidToken(token: string): boolean {
    let isValid = true;
    try {
      const decodeToken = this.helper.decodeToken(this.getToken());
      // const isTokenExpired = this.helper.isTokenExpired();
      let isTokenExpired = false;
      if (decodeToken && decodeToken.hasOwnProperty("exp")) {
        isTokenExpired = this.helper.isTokenExpired(this.getToken());
      }
      if (isTokenExpired) {
        isValid = false;
        // this.setToastMessage('Your session has been expired. Please Login again.', ToastStatus.ERROR);
      }
    } catch (e) {
      isValid = false;
    }
    return isValid;
  }

  isValidUser(user: any): boolean {
    return !!user;
  }

  isLoggedInAdmin(): boolean {
    return this.getToken() ? this.IsValidToken(this.getToken()) : false;
  }

  isConsultant(): boolean {
    return this.getUser().roleName === UserRole.consultant_staff;
  }

  isSystemAdmin(): boolean {
    return this.getUser().roleName === UserRole.systemAdmin;
  }

  isLoggedInPatient(): boolean {
    return this.getToken() && this.getUser()
      ? this.IsValidToken(this.getToken()) && this.isValidUser(this.getUser())
      : false;
  }

  setLoader(val: boolean): void {
    if (val) {
      this.loaderCount += 1;
    } else {
      this.loaderCount -= 1;
      if (this.loaderCount !== 0) {
        val = true;
      }
    }
    AppLogger(`<======setLoader======>${val}`);
    this.isLoading.next(val);
  }

  getLoginRequired(): Observable<boolean> {
    return this.isLoginRequired.asObservable();
  }

  setLoginRequired(val: boolean): void {
    this.isLoginRequired.next(val);
  }

  clearSession() {
    this.cacheObj = {};
    this.updateDownloadTaskList([]);
    this.setRefreshToken(null);
    this.setToken(null);
    this.setUser(null);
    localStorage.clear();
    sessionStorage.clear();
  }

  logout(): void {
    this.clearSession();
    this.setLoginRequired(false);
    this.dialogRef.closeAll();
    this.router.navigate(["/" + "login"]);
    location.reload();
  }

  getToastMessage(): Observable<any> {
    return this.msgBody.asObservable();
  }

  setToastMessage(message: any, type: ToastStatus) {
    let body = null;
    if (message) {
      body = {
        message: message,
        type: type,
      };
    }
    this.msgBody.next(body);
  }

  // To get & set cached key
  getCachedValue(key: string | number) {
    return this.cacheObj[key];
  }

  setCachedValue(key: string | number, value: any) {
    this.cacheObj[key] = value;
  }

  /**
   * for setting scrollbar position when no data found
   */
  applyScrollPosition(table: ElementRef, position: number) {
    table.nativeElement.scrollTo(position, 0);
  }

  // Donwload Progress Observable
  getDownloadTaskList = () => {
    return this.taskSubject.asObservable();
  };

  updateDownloadTaskList(taskList: FileModel[]) {
    this.taskSubject.next(taskList);
  }
}
