<div class="forgot-pwd-container">
  <form [formGroup]="forgotEmailForm" (submit)="onSubmitForgotEmailForm(forgotEmailForm)">
    <div class="patient-login">
      <div class="row">
        <div class="col-sm-2 col-md-0"></div>
        <div class="col-xs-12 col-sm-8 col-md-12 col-xl-12">
          <div class="patient-login__content">
            <div class="patient-login__content__data">
              <h2>Forgot Password?</h2>
              <p>Enter email address associated with your account.
                We will email you verification code to reset your password</p>
              <div class="MT-30">
                <div class="form-input form-control-admin">
                  <mat-form-field floatLabel="always">
                    <mat-label>Email</mat-label>
                    <input formControlName="email" matInput placeholder="Enter email address" autocomplete="off"
                      required type="text" />
                    <mat-error *ngIf="isRequiredField(forgotEmailForm.get('email'))">{{validationMsg.EMAIL}}</mat-error>
                    <mat-error *ngIf="isValidLength(forgotEmailForm.get('email'))">{{validationMsg.EMAIL_LENGTH}}
                    </mat-error>
                    <mat-error *ngIf="isValidField(forgotEmailForm.get('email'))">{{validationMsg.VALID_EMAIL_ADDRESS}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="MT-10">
                <div class="col-md-12 col-xl-12 PL-0">
                  <button [disabled]="forgotEmailForm.invalid" class="btn btn-primary admin-btn-primary" type="submit">
                    Send
                    <mat-icon svgIcon="arrowRight"></mat-icon>
                  </button>
                  <button class="back-btn-auth" (click)="onClickLogIn()" mat-button>Back</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>