import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { forkJoin } from "rxjs";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { saveFile, transformTimerValue } from "@sharedModule/functions";
import { AdminPatientService } from "@privateModule/services";
import { PatientModel, UserDataModel } from "@privateModule/models";
import { CommentLogType, UserRole } from "@sharedModule/constants";
import { SharedService } from "@sharedModule/shared-service";
import { CommentsLogDialogComponent } from "../comments-log-dialog/comments-log-dialog.component";

@Component({
  selector: "app-admin-rpm-report-view",
  templateUrl: "./rpm-report-view.component.html",
  styleUrls: ["./rpm-report-view.component.scss"],
})
export class RpmReportViewComponent implements OnInit, OnDestroy {
  // Constant variables
  userRole = UserRole;

  // Data variables
  clockDisplay: string;
  interval: any;
  duration: number;
  userData: UserDataModel;
  callClockDisplay: string = this.transformCall(0);
  callInterval: any;
  callDuration: number;
  previousDuration: number = 0;
  startCallTimerId: number;
  pdfSrc: any;
  personId: number;
  startDetailTimerId: number;
  patientDetail: PatientModel;

  // Form variables
  commentFormControl = new UntypedFormControl();

  // State variables
  isStartDetailTimer = true;
  isStartCallTimer = true;
  isLoading = false;

  constructor(
    public dialog: MatDialog,
    private adminPatientService: AdminPatientService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<RpmReportViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit() {
    [
      this.pdfSrc,
      this.personId,
      this.startDetailTimerId,
      this.patientDetail
    ] = [
      this.data.pdfSrc,
      this.data.personId,
      this.data.startDetailTimerId,
      this.data.patientDetails
    ]
    this.userData = this.sharedService.getUser();
    if(this.userData.recordTime && !this.isConsultant) {
      this.initiateTimer(0);
    }
  }

  ngOnDestroy() {
    if (this.userData.recordTime && !this.isConsultant) {
      if(this.isStartDetailTimer) {
        this.pauseTimer();
        this.onStopTimer();
      }
      if (!this.isStartCallTimer) {
        this.pauseCallTimer();
        this.onStopCallTimer();
      }
    }
  }

  // Api Calls
  /*startTimerApiCalls = () => {
    return this.adminPatientService.startRPMReportTimer(this.personId);
  };*/

  stopTimerApiCall = (params: any, showLoader: boolean = false) => {
    this.previousDuration = this.duration;
    return this.adminPatientService.stopRPMReportTimer(this.personId, params, showLoader);
  };

  startCallTimerApiCalls = () => {
    return this.adminPatientService.startCallTimer(this.personId);
  };

  stopCallTimerApiCall = (params: any) => {
    return this.adminPatientService.stopCallTimer(this.personId, params);
  };

  rpmReportActionApiCall = (params: any) => {
    return this.adminPatientService.rpmReportAction(params);
  };

  startCallAndStopTimer() {
    return forkJoin([
      this.startCallTimerApiCalls(),
      this.stopTimerApiCall({
        id: this.startDetailTimerId,
        message: "",
        totalTime: this.duration - this.previousDuration,
      }),
    ]);
  }

  stopCallAndStartTimer() {
    return this.stopCallTimerApiCall({
      id: this.startCallTimerId,
      message: "",
      totalTime: this.callDuration,
    });
  }

  // Page events
  onCloseReport = () => {
    this.dialogRef.close();
  }

  onCommentsLogDialogOpen = () => {
    this.onCloseReport();
    this.dialog.open(CommentsLogDialogComponent, {
      width: "1100px",
      enterAnimationDuration: 0,
      exitAnimationDuration: 0,
      data: {patientId: this.personId, commentLogType: CommentLogType.RPM_report_log},
    });
  };

  onChangeStatus = () => {
    if (this.isStartDetailTimer && !this.isLoading) {
      this.isLoading = true;
      this.isStartDetailTimer = false;
      this.pauseTimer();
      this.isLoading = false;
    } else if (!this.isStartCallTimer && !this.isLoading) {
      this.isLoading = true;
      this.pauseCallTimer();
      this.stopCallAndStartTimer().subscribe(() => {
        this.commentFormControl.setValue("");
        this.isStartDetailTimer = true;
        this.isStartCallTimer = true;
        this.initiateTimer(this.duration);
        this.callClockDisplay = this.transformCall(0);
        this.isLoading = false;
      });
    } else if (!this.isLoading) {
      // this.isLoading = true;
      // this.startTimerApiCalls().subscribe(() => {
      this.commentFormControl.setValue("");
      this.isStartDetailTimer = true;
      this.initiateTimer(this.duration);
      // this.isLoading = false;
      // });
    }
  };

  onChangeCallStatus = () => {
    if (this.isStartCallTimer && !this.isLoading) {
      this.isLoading = true;
      this.pauseTimer();
      this.startCallAndStopTimer().subscribe((response) => {
        this.commentFormControl.setValue("");
        this.isStartDetailTimer = false;
        this.isStartCallTimer = false;
        this.initiateCallTimer(0);
        this.startCallTimerId = response[0]["payload"]["id"];
        this.isLoading = false;
      });
    } else if (!this.isLoading) {
      this.isLoading = true;
      this.pauseCallTimer();
      this.stopCallAndStartTimer().subscribe(() => {
        this.initiateTimer(this.duration);
        this.isStartDetailTimer = true;
        this.isStartCallTimer = true;
        this.isLoading = false;
        this.callClockDisplay = this.transformCall(0);
      });
    }
  };

  onStopTimer = (showLoader: boolean = false) => {
    this.stopTimerApiCall(
      {
        id: this.startDetailTimerId,
        message: this.commentFormControl.value
          ? this.commentFormControl.value
          : "",
        totalTime: this.duration - this.previousDuration,
      },
      showLoader
    ).subscribe(() => {
      this.commentFormControl.setValue("");
    });
  };

  onStopCallTimer = () => {
    this.stopCallTimerApiCall({
      id: this.startCallTimerId,
      message: "",
      totalTime: this.callDuration,
    }).subscribe(() => {});
  };

  onDownloadReport = () => {
    const params = { patientId: this.personId, action: "Download" };
    this.rpmReportActionApiCall(params).subscribe((response) => {
      saveFile(
        this.pdfSrc,
        `${
          this.patientDetail.preferredName
            ? this.patientDetail.preferredName
            : this.patientDetail.first
        }.pdf`
      );
    });
  };

  onPrintClick = () => {
    const params = { patientId: this.personId, action: "Print" };
    this.rpmReportActionApiCall(params).subscribe((response) => {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = this.pdfSrc;
      document.body.appendChild(iframe);
      if (iframe.contentWindow) {
        iframe.contentWindow.print();
      }
    });
  };

  // Helper methods
  initiateTimer(duration: number) {
    this.interval = setInterval(() => {
      duration++;
      this.clockDisplay = this.transform(duration);
    }, 1000);
  }

  initiateCallTimer(duration: number) {
    this.callInterval = setInterval(() => {
      duration++;
      this.callClockDisplay = this.transformCall(duration);
    }, 1000);
  }

  transform(value: number): string {
    this.duration = value;
    return transformTimerValue(value);
  }

  transformCall(value: number): string {
    this.callDuration = value;
    return transformTimerValue(value);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  pauseCallTimer() {
    clearInterval(this.callInterval);
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    const keyPressed = event.keyCode;
    if (keyPressed === 27) {
      this.onCloseReport();
    }
  }

  get isConsultant() {
    return this.patientDetail.isExternalPatient;
  }
}
