<div class="medication-patient-details-dialog-container">
  <div class="medication-patient-details-dialog">
    <div class="medication-patient-details-dialog__header">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-8 col-xl-6">
          <h4>Add Medication</h4>
        </div>

        <div class="col-md-6 col-sm-6 col-xs-4 col-xl-6">
          <div class="event-details-dialog-section__header__close">
            <a (click)="onCancel(false)">
              <mat-icon svgIcon="close"></mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>

    <form
      (submit)="onSubmitCreateMedicationDetailsForm(medicationDetailsForm)"
      [formGroup]="medicationDetailsForm"
    >
      <mat-dialog-content>
        <div class="medication-patient-details-dialog__body">
          <div class="admin-form-control">
            <mat-form-field floatLabel="always">
              <mat-label>Name</mat-label>
              <input
                class="input-spacing"
                autocomplete="off"
                matInput
                placeholder="Select Name"
                readonly
                formControlName="medicationName"
                (click)="onShowDropdown()"
                (keypress)="onShowDropdown()"
              />
              <mat-icon svgIcon="dropdown"></mat-icon>
            </mat-form-field>
          </div>

          <div *ngIf="isShowDropdown" class="search-box">
            <form [formGroup]="filterMedForm">
              <div class="search-box__header">
                <mat-form-field floatLabel="never">
                  <input
                    autocomplete="off"
                    matInput
                    placeholder="Search"
                    #medInputRef
                    formControlName="medicationInput"
                  />
                  <mat-icon
                    class="search-icon-header"
                    svgIcon="search"
                    matPrefix
                  ></mat-icon>
                </mat-form-field>
              </div>
            </form>

            <div class="search-box__body">
              <ul
                *ngIf="
                  formControls['medicationInput'].value.length > 3 &&
                  medicationArray.length > 0
                "
              >
                <li *ngFor="let medication of medicationArray; let i = index">
                  <a (click)="changeMedicine(medication)">
                    <img
                      *ngIf="medication?.medicationImageUrl"
                      [src]="medication?.medicationImageUrl"
                    />
                    <mat-icon
                      *ngIf="!medication?.medicationImageUrl"
                      svgIcon="medicationplaceholder"
                    ></mat-icon>
                    <span>{{ medication?.fullName }}</span>
                  </a>
                </li>
              </ul>
            </div>

            <div
              class="search-box__body__no-data-box"
              *ngIf="
                formControls['medicationInput'].value.length > 3 &&
                medicationArray.length === 0
              "
            >
              <form
                (submit)="onSubmitOtherMedicationForm(otherMedForm)"
                [formGroup]="otherMedForm"
              >
                <div class="search-box__body__no-data-box__details">
                  <p>No medication found. Please enter full medication name</p>
                  <input
                    #noDataInputRef
                    formControlName="otherMedicationName"
                    matInput
                    placeholder="Enter Medication"
                  />
                </div>
                <div class="search-box__body__no-data-box__button">
                  <button
                    class="MT-2"
                    mat-flat-button
                    color="primary"
                    button="submit"
                    [disabled]="otherMedForm.invalid"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div
            *ngIf="isShowDropdown"
            (click)="onCloseDropdown()"
            class="dropdown-close-layer"
          ></div>

          <div class="admin-form-control">
            <mat-form-field floatLabel="always">
              <mat-label>Medication Details</mat-label>
              <input
                autocomplete="off"
                matInput
                placeholder="Enter medication details"
                #patientTextInputRef
                formControlName="patientText"
                type="text"
              />
            </mat-form-field>
          </div>

          <div class="admin-form-control">
            <mat-form-field floatLabel="always">
              <mat-label>Dose</mat-label>
              <input
                autocomplete="off"
                matInput
                placeholder="Enter dose"
                formControlName="dose"
                min="0"
                type="number"
                required
              />
            </mat-form-field>
          </div>

          <div class="admin-form-control">
            <mat-form-field floatLabel="always">
              <mat-label>Units</mat-label>
              <mat-select
                placeholder="Select units"
                formControlName="units"
                required
              >
                <mat-option
                  *ngFor="let unit of addMedUnitList"
                  [value]="unit.value"
                  >{{ unit.title }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="admin-form-control bottom-spacing">
            <mat-form-field floatLabel="always">
              <mat-label>Frequency</mat-label>
              <mat-select
                placeholder="Select frequency"
                formControlName="frequency"
                required
              >
                <mat-option
                  *ngFor="let frequency of addMedFrequencyList"
                  [value]="frequency.value"
                >
                  {{ frequency.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="admin-form-control comment-field">
            <mat-form-field floatLabel="always">
              <mat-label>&nbsp;</mat-label>
              <input
                autocomplete="off"
                matInput
                placeholder="Enter other details"
                formControlName="otherDetail"
                type="text"
              />
            </mat-form-field>
          </div>

          <div class="medication-patient-details-dialog__footer">
            <button
              class="close-button"
              mat-flat-button
              mat-dialog-close
              color="primary"
              (click)="onCancel(false)"
            >
              Close
            </button>
            <button class="send-button" mat-flat-button color="primary">
              Submit
            </button>
          </div>
        </div>
      </mat-dialog-content>
    </form>
  </div>
</div>
