import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import {SharedService} from '@sharedModule/shared-service';

@Component({
  selector: 'app-progress-hud',
  templateUrl: './progress-hud.component.html',
  styleUrls: ['./progress-hud.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProgressHudComponent implements OnInit, OnDestroy {

  isLoading = false;
  $loaderSubscriber: any;

  constructor(private _sharedService: SharedService) {
  }

  ngOnInit() {
    this.$loaderSubscriber = this._sharedService.getLoader().subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }

  ngOnDestroy() {
    if (this.$loaderSubscriber) {
      this.$loaderSubscriber.unsubscribe();
    }
  }
}
