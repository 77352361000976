import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { KtalkComponent, UnauthorizeAccessDialogComponent } from "@sharedModule/components";

import { AppPrivateRoutes, AppPublicRoutes } from "@sharedModule/constants";

const routes: Routes = [
  {
    path: AppPrivateRoutes.UNAUTHORIZED_ACCESS_ROUTE,
    component: UnauthorizeAccessDialogComponent,
  },
  {
    path: AppPublicRoutes.KTALK_ROUTE,
    component: KtalkComponent,
    children: [
      {
        path: "**",
        component: KtalkComponent,
      },
    ],
  },
  {
    path: "**",
    redirectTo: AppPublicRoutes.LOGIN,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
