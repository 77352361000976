import { Injectable } from "@angular/core";
import { APIManager } from "../../../shared-module/shared-service/apimanager.service";
import { AdminApi, HttpMethodsTypeEnum } from "@sharedModule/constants";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharePatientService {
  constructor(private apiManager: APIManager) {}

  sharePatientDetails(param: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.SHARE_PATIENT_DETAILS,
      param,
      this.apiManager.HttpOptions,
      true,
      true
    );
  }

  getPatientList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.EXTERNAL_PATIENT_LIST,
      params,
      this.apiManager.HttpOptions,
      false,
      true
    );
  }
}
