import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss']
})

export class CustomPaginatorComponent implements OnInit {
  @Input() pageSize = 10;
  @Input() totalRecords = 200;
  @Output() changeEvent: EventEmitter<boolean> = new EventEmitter();

  // Mat paginator Output
  pageEvent;
  pageSizeOptions = [10, 20, 30, 40];

  constructor() {
  }

  ngOnInit() {
  }

  pageChange = (event) => {
    this.pageEvent = event;
    this.changeEvent.emit(event);
  }
}

