<div class="comment-log-container">
  <div class="comment-log-view">
    <div class="comment-log-view__comment-log-view-header">
      <div class="row">
        <div class="col-md-6 col-xs-9 col-sm-6 PL-0">
          <h2>Comments Log</h2>
        </div>

        <div class="col-md-6 col-xs-3 col-sm-6 PR-0">
          <div class="dialog-section__close">
            <a matTooltip="Close" (click)="onCancel()">
              <mat-icon svgIcon="close_box"></mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="comment-log-view__comment-log-view-details">
  <div class="dialog-table-container">
    <section class="table-scroll comment-log-list comment-log-scroll-height">
      <div class="table">
        <table mat-table [dataSource]="commentList">
          <ng-container matColumnDef="physicianId">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">
              Physician ID
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.physicianId | checkEmpty }}
            </td>
          </ng-container>

          <ng-container matColumnDef="physicianName">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">
              Physician Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.physicianName | checkEmpty }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">
              Start Time
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element?.dateCreated | date : "MM/dd/yyyy hh:mm a" | checkEmpty
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalTime">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">
              Total Time
            </th>
            <td mat-cell *matCellDef="let element">
              {{ totalTimeDuration(element?.totalTime) | checkEmpty }}
            </td>
          </ng-container>

          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef style="width: 40%">
              Comment
            </th>
            <td mat-cell *matCellDef="let element">
              <p class="rowspan" *ngIf="element?.messageList?.length == 0">-</p>
              <p class="rowspan" *ngFor="let message of element?.messageList">
                {{ message | checkEmpty }}
              </p>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div *ngIf="!isLoadingResults && commentList && commentList.length === 0">
        <app-data-not-found
          [messageHeader]="'No data found!'"
        ></app-data-not-found>
      </div>
    </section>
    <div class="row">
      <div class="col-md-12 PR-0 PL-0">
        <mat-paginator
          #paginator
          [length]="resultsLength"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          [hidePageSize]="resultsLength < 10"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<div (click)="onCancel()" class="gray-color-container"></div>
