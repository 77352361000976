export enum ToastStatus {
  UNKNOWN = 0,
  SUCCESS = 1,
  ERROR = 2,
  MULTIPLE = 3,
}

export class HttpStatus {
  public static SUCCESS = 200;
  public static UNAUTHORIZED = 401;
  public static EXPIRED = 450;
  public static FORBIDDEN_ACCESS = 403;
}

export enum HttpMethodsTypeEnum {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
  PUT_MULTIPART = "putMultiPart",
  POST_MULTIPART = "postMultiPart",
}

export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY";
export const DATE_FORMAT = "MM/DD/YYYY";
export const PAGE_SIZE_OPTIONS = [10, 20, 30, 40];

export const GenderExpectedValueArray = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "NA" },
];

export const AddMedUnits = [
  {
    title: "ug (micrograms)",
    value: "ug",
  },
  {
    title: "mg (milligrams)",
    value: "mg",
  },
  {
    title: "g (grams)",
    value: "g",
  },
  {
    title: "ml (milliliters)",
    value: "mL",
  },
  {
    title: "meq (milliequivalents)",
    value: "meq",
  },
  {
    title: "puffs",
    value: "puff",
  },
  {
    title: "tablets",
    value: "tablets",
  },
  {
    title: "capsules",
    value: "capsules",
  },
  {
    title: "gtt (drops)",
    value: "drops",
  },
  {
    title: "other",
    value: "CDA-UNK",
  },
  {
    title: "not sure",
    value: "CDA-NI",
  },
];

export const AddMedFrequency = [
  {
    value: "339231010",
    title: "As needed",
  },
  {
    value: "2615150015",
    title: "Every morning",
  },
  {
    value: "450340012",
    title: "Every night",
  },
  {
    value: "344484011",
    title: "Twice daily",
  },
  {
    value: "617535011",
    title: "Three times daily",
  },
  {
    value: "740606015",
    title: "Once a week",
  },
  {
    value: "387920015",
    title: "When I remember",
  },
  {
    value: "CDA-UNK",
    title: "Other",
  },
];

export const UserTypeEnum = [
  {
    value: "Staff",
    label: "Staff",
  },
  {
    value: "Physician",
    label: "Physician",
  },
];

export enum OrganisationScope {
  private = "PRIVATE",
  protected = "PROTECTED",
  superAdmin = "SUPERADMIN",
  self = "SELF",
}

export enum UserRole {
  systemAdmin = "SystemAdmin",
  physician = "Physician",
  staff = "Staff",
  consultant_staff = "ConsultantStaff",
}

export enum DeviceChartStyle {
  dailyAverage = "dailyAvg",
  full = "full",
  partial = "partial",
  rescue = "rescue",
  fullRoutine = "fullRoutine",
  normalRoutine = "normalRoutine",
  partialRoutine = "partialRoutine",
  none = "none",
}

export enum SmokingEnum {
  smokeMostDays = "449868002",
  smokedOccasionally = "428041000124106",
  passiveExposer = "43381005",
  formerSmoker = "8517006",
  neverSmoked = "266919005",
  awaitingReply = "awaiting_reply",
}

export enum AcceptabilityEnum {
  notApplicable = "NotApplicable",
  acceptable = "Acceptable",
  notAcceptable = "NotAcceptable",
  notAcceptableAndUsable = "NotAcceptableAndUsable",
  notAcceptableAndNotUsable = "NotAcceptableAndNotUsable",
}

export const MessageTypeEnum = [
  {
    value: "medications",
    label: "View Medication",
  },
  {
    value: "removeMeds",
    label: "Remove Medication",
  },
  {
    value: "addMeds",
    label: "Add Medication",
  },
  {
    value: "reply_q_ATAQ",
    label: "Asthma Therapy Assessment Questionnaire",
  },
  {
    value: "reply_q_BASELINE_QUESTIONNAIRE",
    label: "Baseline Questionnaire",
  },
  {
    value: "enterSymptom",
    label: "Report Symptoms",
  },
  {
    value: "PeakFlow",
    label: "Report Peakflow",
  },
  {
    value: "Tobacco",
    label: "Smoking Cessation Questionnaire",
  },
  {
    value: "reply_prescription",
    label: "Prescription Questionnaire",
  },
  {
    value: "medComplianceReport",
    label: "Medication Compliance Questionnaire",
  },
  {
    value: "reply_freetext",
    label: "Request Response (Text)",
  },
  {
    value: "VideoInhalerUsage",
    label: "View Inhaler Usage",
  },
  {
    value: "VideoPeakflowUsage",
    label: "View Peakflow Usage",
  },
  {
    value: "reply_yn",
    label: "Yes or No Question",
  },
  {
    value: "reply_check_in",
    label: "Patient Check-In",
  },
];

export enum MessageType {
  MEDICATION = "medications",
  REMOVE_MEDICATION = "removeMeds",
  ADD_MEDICATION = "addMeds",
  ATAQ = "reply_ATAQ",
  SYMPTOMS = "enterSymptom",
  PEAKFLOW = "PeakFlow",
  TOBACCO = "Tobacco",
  PRESCRIPTION = "reply_prescription",
  MED_COMPLIANCE_REPORT = "medComplianceReport",
  FREE_TEXT = "reply_freetext",
  VIDEO_INHALER_USAGE = "VideoInhalerUsage",
  VIDEO_PEAK_FLOW_USAGE = "VideoPeakflowUsage",
  REPLY_YN = "reply_yn",
}

export enum PhysicianMessageType {
  REVIEW_MEDICATION = "reviewMedication",
  ADD_MEDICATION = "addMedication",
  REVIEW_PEAK_FLOW = "reviewPeakFlow",
  REVIEW_ACTION_PLAN = "reviewActionPlan",
  REVIEW_ALLERGIES = "reviewAllergies",
  REVIEW_QUESTIONNAIRE = "reviewQuestionnaire",
  REVIEW_DEVICES = "reviewDevices",
  REVIEW_SPIROMETRY_DATA = "reviewSpirometryData",
}

export enum PatientActionPlanInputName {
  GREEN = "greenActionPlan",
  YELLOW = "yellowActionPlan",
  RED = "redActionPlan",
}

export const FILE_EXPORT_TYPE = [
  {
    value: "EXCEL",
    label: "Excel",
  },
  // {
  //   value: "CSV",
  //   label: "Csv",
  // },
];

export enum RecordType {
  FINDING = "FINDING",
  ALLERGY = "ALLERGY",
  COUNSELING = "COUNSELING",
  DIAGNOSIS = "DIAGNOSIS",
  DIET = "DIET",
  DME = "DME",
  EXERCISE = "EXERCISE",
  HOME_CARE = "HOMECARE",
  LAB = "LAB",
  MEDICATION = "MEDICATION",
  HOME_MONITORING = "HOME_MONITORING",
  ENCOUNTER = "ENCOUNTER",
  PROCEDURE = "PROCEDURE",
  SOCIAL_HISTORY = "SOCIAL_HISTORY",
  TOBACCO = "TOBACCO",
}

export enum CommunicationType {
  EMAIL = "Email",
  PHONE = "Phone",
}

export const AllergyType = [
  { key: "severity", value: "Severity" },
  { key: "allergyCncptType", value: "Allergy Concept Type" },
  { key: "allergyDetail", value: "Allergy Detail" },
  { key: "allergyCode", value: "Allergy Code" },
  { key: "findingDetail", value: "Finding Detail" },
  { key: "findingCode", value: "Finding Code" },
];

export const DiagnosisType = [
  { key: "severity", value: "Severity" },
  { key: "acuity", value: "Acuity" },
  { key: "probability", value: "Probability" },
];

export const FindingType = [
  { key: "severity", value: "Severity" },
  { key: "patientReported", value: "Patient Reported" },
  { key: "performingPhysicianId", value: "Performing Physician Id" },
  { key: "duration", value: "Duration" },
  { key: "durationUnitDetail", value: "Duration Unit Detail" },
  { key: "durationUnits", value: "Duration Units" },
  { key: "frequencyDetail", value: "Frequency Detail" },
  { key: "frequency", value: "Frequency" },
  { key: "totalNumber", value: "Total Number" },
  { key: "totalNumberUnitDetail", value: "Total Number Unit Detail" },
  { key: "totalNumberUnits", value: "Total Number Units" },
];

export const HomeMonitoringType = [
  { key: "equipmentDetail", value: "Equipment Detail" },
  { key: "equipmentCode", value: "Equipment Code" },
  { key: "resultNumber", value: "Result Number" },
  { key: "resultUnitDetail", value: "Result Unit Detail" },
  { key: "resultUnits", value: "Result Units" },
  { key: "resultClass", value: "Result Class" },
  { key: "frequencyDetail", value: "Frequency Detail" },
  { key: "frequency", value: "Frequency" },
  { key: "duration", value: "Duration" },
  { key: "durationUnitDetail", value: "Duration Unit Detail" },
  { key: "durationUnits", value: "Duration Units" },
  { key: "totalNumber", value: "Total Number" },
  { key: "totalNumberUnitDetail", value: "Total Number Unit Detail" },
  { key: "totalNumberUnits", value: "Total Number Units" },
  { key: "hiGoal", value: "High Goal" },
  { key: "hiGoalUnitDetail", value: "High Goal Unit Detail" },
  { key: "hiGoalUnits", value: "High Goal Units" },
  { key: "lowGoal", value: "Low Goal" },
  { key: "lowGoalUnitDetail", value: "Low Goal Unit Detail" },
  { key: "lowGoalUnits", value: "Low Goal Units" },
];

export const HomeCareType = [
  { key: "equipmentCode", value: "Equipment Code" },
  { key: "equipmentDetail", value: "Equipment Detail" },
  { key: "frequency", value: "Frequency" },
  { key: "frequencyDetail", value: "Frequency Detail" },
  { key: "duration", value: "Duration" },
  { key: "durationUnitDetail", value: "Duration Unit Detail" },
  { key: "durationUnits", value: "Duration Units" },
  { key: "totalNumber", value: "Total Number" },
  { key: "totalNumberUnitDetail", value: "Total Number Unit Detail" },
  { key: "totalNumberUnits", value: "Total Number Units" },
];

export const LabType = [
  { key: "equipmentDetail", value: "Equipment Detail" },
  { key: "equipmentCode", value: "Equipment Code" },
  { key: "performingPhysicianId", value: "Performing Physician Id" },
  { key: "hiGoal", value: "High Goal" },
  { key: "hiGoalUnitDetail", value: "High Goal Unit Detail" },
  { key: "hiGoalUnits", value: "High Goal Units" },
  { key: "lowGoal", value: "Low Goal" },
  { key: "lowGoalUnitDetail", value: "Low Goal Unit Detail" },
  { key: "lowGoalUnits", value: "Low Goal Units" },
  { key: "resultNumber", value: "Result Number" },
  { key: "resultUnitDetail", value: "Result Unit Detail" },
  { key: "resultUnits", value: "Result Units" },
  { key: "resultClass", value: "Result Class" },
  { key: "frequencyDetail", value: "Frequency Detail" },
  { key: "frequency", value: "Frequency" },
  { key: "duration", value: "Duration" },
  { key: "durationUnitDetail", value: "Duration Unit Detail" },
  { key: "durationUnits", value: "Duration Units" },
  { key: "totalNumber", value: "Total Number" },
  { key: "totalNumberUnitDetail", value: "Total Number Unit Detail" },
  { key: "totalNumberUnits", value: "Total Number Units" },
];

export const MedicationType = [
  { key: "medRouteCode", value: "Medication Route Code" },
  { key: "medRouteDetail", value: "Medication Route Detail" },
  { key: "dosageUnits", value: "Dosage Units" },
  { key: "dosageUnitDetail", value: "Dosage Unit Detail" },
  { key: "dosageNumber", value: "Dosage Number" },
  { key: "dispense", value: "Dispense" },
  { key: "refills", value: "Refills" },
  { key: "brandName", value: "Brand Name" },
  { key: "prescriptionFact", value: "Prescription Fact" },
  { key: "performingPhysicianId", value: "Performing Physician Id" },
  { key: "maxDosesDay", value: "Maximum Doses Day" },
  { key: "frequency", value: "Frequency" },
  { key: "frequencyDetail", value: "FrequencyDetail" },
  { key: "duration", value: "Duration" },
  { key: "durationUnits", value: "Duration Units" },
  { key: "durationUnitDetail", value: "Duration Unit Detail" },
  { key: "totalNumber", value: "Total Number" },
  { key: "totalNumberUnits", value: "Total Number Units" },
  { key: "totalNumberUnitDetail", value: "Total Number Unit Detail" },
];

export const ProcedureType = [
  { key: "equipmentDetail", value: "Equipment Detail" },
  { key: "equipmentCode", value: "Equipment Code" },
  { key: "performingPhysicianId", value: "Performing Physician Id" },
  { key: "resultNumber", value: "Result Number" },
  { key: "resultUnitDetail", value: "Result Unit Detail" },
  { key: "resultUnits", value: "Result Units" },
  { key: "resultClass", value: "Result Class" },
  { key: "frequencyDetail", value: "Frequency Detail" },
  { key: "frequency", value: "Frequency" },
  { key: "duration", value: "Duration" },
  { key: "durationUnitDetail", value: "Duration Unit Detail" },
  { key: "durationUnits", value: "Duration Units" },
  { key: "totalNumber", value: "Total Number" },
  { key: "totalNumberUnitDetail", value: "Total Number Unit Detail" },
  { key: "totalNumberUnits", value: "Total Number Units" },
];

export const TobaccoType = [
  { key: "frequency", value: "Frequency" },
  { key: "frequencyDetail", value: "Frequency Detail" },
  { key: "duration", value: "Duration" },
  { key: "durationUnitDetail", value: "Duration Unit Detail" },
  { key: "durationUnits", value: "Duration Units" },
  { key: "totalNumber", value: "Total Number" },
  { key: "totalNumberUnitDetail", value: "Total Number Unit Detail" },
  { key: "totalNumberUnits", value: "Total Number Units" },
];

export const EncounterType = [
  { key: "clinicianType", value: "Clinician Type" },
  { key: "clinicianTypeDetail", value: "Clinician Type Detail" },
  { key: "clinicianName", value: "Clinician Name" },
  { key: "frequency", value: "Frequency" },
  { key: "frequencyDetail", value: "Frequency Detail" },
  { key: "duration", value: "Duration" },
  { key: "durationUnits", value: "Duration Units" },
  { key: "durationUnitDetail", value: "Duration Unit Detail" },
  { key: "totalNumber", value: "Total Number" },
  { key: "totalNumberUnits", value: "Total Number Units" },
  { key: "totalNumberUnitDetail", value: "Total Number Unit Detail" },
];

export const MAX_PAGE_SIZE = 2500000;
export const PAGE_SIZE_FIFTY = 50;
export const PAGE_SIZE_TWENTY = 20;

export enum RecordCodeEnum {
  Red = "1212338019",
  Yellow = "1464976019",
  Green = "669268014",
  TotalCount = "",
}

export enum ActiveStatusEnum {
  Active = "0",
  Inactive = "1",
  True = "is_true",
  False = "is_false",
}

export const ACTIVE_STATUS_MAP = {
  [ActiveStatusEnum.Active]: {
    label: "Active",
    value: ActiveStatusEnum.Active,
  },
  [ActiveStatusEnum.Inactive]: {
    label: "Inactive",
    value: ActiveStatusEnum.Inactive,
  },
};

export const ActiveStatusList = Object.values(ACTIVE_STATUS_MAP);

export const PATIENT_ACCOUNT_TYPE_MAP = {
  [ActiveStatusEnum.True]: {
    label: "Test Accounts",
    value: ActiveStatusEnum.True,
  },
  [ActiveStatusEnum.False]: {
    label: "Patients Accounts",
    value: ActiveStatusEnum.False,
  },
};

export const PatientAccountTypeList = Object.values(PATIENT_ACCOUNT_TYPE_MAP);

export const STAFF_ACCOUNT_TYPE_MAP = {
  [ActiveStatusEnum.True]: {
    label: "Test Accounts",
    value: ActiveStatusEnum.True,
  },
  [ActiveStatusEnum.False]: {
    label: "Staff Accounts",
    value: ActiveStatusEnum.False,
  },
};

export const StaffAccountTypeList = Object.values(STAFF_ACCOUNT_TYPE_MAP);

export enum AuditTypeEnum {
  RPM_report = "5",
  Patient_detail = "6",
  Patient_call = "18",
  Manual_time = "19",
  Patient_review = "21",
}

export const AUDIT_TYPE_MAP = {
  [AuditTypeEnum.Patient_call]: {
    label: "Patient Call",
    value: AuditTypeEnum.Patient_call,
  },
  [AuditTypeEnum.Patient_detail]: {
    label: "Patient Detail",
    value: AuditTypeEnum.Patient_detail,
  },
  [AuditTypeEnum.RPM_report]: {
    label: "RPM Report",
    value: AuditTypeEnum.RPM_report,
  },
  [AuditTypeEnum.Manual_time]: {
    label: "Manual Time",
    value: AuditTypeEnum.Manual_time,
  },
  [AuditTypeEnum.Patient_review]: {
    label: "Patient Review",
    value: AuditTypeEnum.Patient_review,
  },
};

export const AuditTypeList = Object.values(AUDIT_TYPE_MAP);

export enum PatientDetailTabsEnum {
  SUMMERY = "summery",
  PEAK_FLOW = "peak-flow",
  ACTION_PLAN = "action-plan",
  MEDICATION = "medication",
  ALLERGIES = "allergies",
  QUESTIONNAIRE = "questionnaire",
  SPIROMETER = "spirometer",
  LOGS = "logs",
  OXI_METER = "oxi-meter",
  WEIGHT = "weight",
  EVENT = "event",
  NOTIFICATION = "notification",
  ACTIVITY = "activity",
}

export enum DaysFilterEnum {
  seven_days = "7",
  one_month = "30",
}

export const DAYS_FILTER_MAP = {
  [DaysFilterEnum.seven_days]: {
    label: "Last 7 Days",
    value: DaysFilterEnum.seven_days,
  },
  [DaysFilterEnum.one_month]: {
    label: "Last 30 Days",
    value: DaysFilterEnum.one_month,
  },
};

export const DaysFilterList = Object.values(DAYS_FILTER_MAP);

export enum DeviceStatusEnum {
  COMPLIANCE_RED = "COMPLIANCE_RED",
  COMPLIANCE_YELLOW = "COMPLIANCE_YELLOW",
  NEVER_USE_DEVICE = "NEVER_USE_DEVICE",
}

export enum CheckInStatusEnum {
  RED_CHECK_IN = "RED_CHECK_IN",
  YELLOW_CHECK_IN = "YELLOW_CHECK_IN",
  NEVER_CHECK_IN = "NEVER_CHECK_IN",
}

export const CommentLogType = {
  patient_details_log: "Patient_detail_logs",
  RPM_report_log: "RPM_report_logs",
  Review_log: "Review_logs",
};

export const ReviewCountStatus = {
  SAD: "RED",
  CONFUSED: "YELLOW",
  HAPPY: "GREEN",
  ALL: "",
};

export enum ReviewStatusEnum {
  Reviewed = 1,
  NotReviewed = 0,
}

export const REVIEW_STATUS_MAP = {
  [ReviewStatusEnum.Reviewed]: {
    label: "Reviewed",
    value: ReviewStatusEnum.Reviewed,
  },
  [ReviewStatusEnum.NotReviewed]: {
    label: "Not Reviewed",
    value: ReviewStatusEnum.NotReviewed,
  },
};

export const ReviewStatusList = Object.values(REVIEW_STATUS_MAP);
export enum ReportSourceEnum {
  ALL = "",
  KEVA = "KEVA",
  Patient = "PATIENT",
  STAFF = "STAFF",
  PHYSICIAN = "PHYSICIAN",
  SYSTEMADMIN = "SYSTEMADMIN",
}

export const REPORT_SOURCE_FILTER_MAP = {
  [ReportSourceEnum.ALL]: {
    label: "All",
    value: ReportSourceEnum.ALL,
  },
  [ReportSourceEnum.KEVA]: {
    label: "Keva",
    value: ReportSourceEnum.KEVA,
  },
  [ReportSourceEnum.Patient]: {
    label: "Patient",
    value: ReportSourceEnum.Patient,
  },
  [ReportSourceEnum.STAFF]: {
    label: "Staff",
    value: ReportSourceEnum.STAFF,
  },
  [ReportSourceEnum.PHYSICIAN]: {
    label: "Physician",
    value: ReportSourceEnum.PHYSICIAN,
  },
  [ReportSourceEnum.SYSTEMADMIN]: {
    label: "System Admin",
    value: ReportSourceEnum.SYSTEMADMIN,
  },
};

export const ReportSourceFilterList = Object.values(REPORT_SOURCE_FILTER_MAP);

export const DEFAULT_CHARSET =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

// FilterType constants
export enum FilterType {
  INPUT,
  AUTO_COMPLETE,
  DROPDOWN,
  MULTI_SELECT_DROPDOWN,
  DATE_RANGE,
}

// Search operator constants
export enum OperatorEnum {
  CONTAIN = "like",
  NOT_CONTAIN = "!like",
  EQUALS = "=",
  NOT_EQUAL = "!=",
  IN = "in",
  NOT_IN = "!in",
  GREATER_THAN = ">",
  LESSER_THAN = "<",
  GREATER_EQUALS = ">=",
  LESSER_EQUALS = "<=",
  TRUE = "is_true",
  FALSE = "is_false",
  NULL = "is_null",
  NOT_NULL = "!is_null",
}

export const BILLIABLE_TYPE_MAP = {
  [ActiveStatusEnum.True]: {
    label: "Yes",
    value: ActiveStatusEnum.True,
  },
  [ActiveStatusEnum.False]: {
    label: "No",
    value: ActiveStatusEnum.False,
  },
};

export const BillableTypeList = Object.values(BILLIABLE_TYPE_MAP);

export enum ReadStatusEnum {
  UNREAD = 0,
  READ = 1,
  EXPIRE = 2,
}

export const READ_STATUS_MAP = {
  [ReadStatusEnum.READ]: {
    label: "Read",
    value: ReadStatusEnum.READ,
  },
  [ReadStatusEnum.UNREAD]: {
    label: "Unread",
    value: ReadStatusEnum.UNREAD,
  },
  [ReadStatusEnum.EXPIRE]: {
    label: "Expire",
    value: ReadStatusEnum.EXPIRE,
  },
};

export const ReadStatusList = Object.values(READ_STATUS_MAP);

export enum ConsultTypeEnum {
  PHYSICIAN = "PHYSICIAN",
  FAMILY = "CARETAKER",
}

export const CONSULT_TYPE_MAP = {
  [ConsultTypeEnum.PHYSICIAN]: {
    label: "Physician",
    value: ConsultTypeEnum.PHYSICIAN,
  },
  [ConsultTypeEnum.FAMILY]: {
    label: "Family",
    value: ConsultTypeEnum.FAMILY,
  },
};

export const ConsultTypeList = Object.values(CONSULT_TYPE_MAP);

export const ExtOrganizations = ["Keva External"];
