<div class="dialog-section change-password-container">
  <div class="dialog-section__logo">
    <img src="assets/images/Logo.png" />
  </div>
  <div class="dialog-section__close">
    <a (click)="onCancel()">
      <mat-icon svgIcon="close"></mat-icon>
    </a>
  </div>

  <form [formGroup]="userChangePasswordForm" 
        (submit)="onSubmitUserChangePasswordForm(userChangePasswordForm)">
    <mat-dialog-content>
      <h2>Change Password</h2>

      <div class="form-control form-control-admin MB-20">
        <mat-form-field floatLabel=always>
          <mat-label>New Password</mat-label>
          <input autocomplete="off" formControlName="newPassword" matInput placeholder="New password" required
                type="password"/>
          <mat-error *ngIf="isRequiredField(formControls['newPassword'])">{{validationMsg.PASSWORD_REQUIRED}}
          </mat-error>
          <mat-error *ngIf="isValidLength(formControls['newPassword'])">{{validationMsg.PASSWORD_LENGTH}}</mat-error>
          <mat-error *ngIf="isValidField(formControls['newPassword'])">{{validationMsg.PASSWORD_VALID}}</mat-error>
        </mat-form-field>
      </div>

      <div class="form-control form-control-admin MB-20">
        <mat-form-field floatLabel=always>
          <mat-label>Confirm Password</mat-label>
          <input autocomplete="off" formControlName="confirmPassword" matInput placeholder="Confirm password" required
                type="password"/>
          <mat-error *ngIf="isRequiredField(formControls['confirmPassword'])">{{validationMsg.CONFIRM_PASSWORD}}
          </mat-error>
          <mat-error *ngIf="userChangePasswordForm.errors?.doesMatchPassword">
            {{validationMsg.PASSWORD_DOESNT_MATCH}}
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <button class="btn btn-primary admin-btn-primary change-password-btn" type="submit"> Submit
          <mat-icon svgIcon="arrowRight"></mat-icon>
        </button>
        <a class="cancel-btn" (click)="onCancel()">Cancel</a>
      </div>
    </mat-dialog-content>
  </form>
</div>
