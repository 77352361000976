export const SYSTEM_SVG_IMAGES = [
  'dropdown',
  'logo',
  'logo-header',
  'dropdown-header',
  'calendar',
  'doctor',
  'gender',
  'home',
  'medication',
  'recordDetails',
  'settings',
  'user',
  'rightArrow',
  'happy',
  'login-logo',
  'allergy',
  'close',
  'filter',
  'sad',
  'confused',
  'lastCheckIn',
  'sorting',
  'calendar2',
  'filter2',
  'user_placeholder',
  'arrowRight',
  'kevalogo',
  'arrowRight',
  'list',
  'mail',
  'test',
  'ThreeDotMaterail',
  'info',
  'play',
  'stop',
  'comingsoon',
  'leftArrow',
  'right-arrow',
  'profile',
  'smoker',
  'nonsmoker',
  'summary',
  'peakflowgraph',
  'actionplan',
  'medication-new',
  'allergies',
  'questionnaire',
  'device',
  'patientlog',
  'composemessage',
  'refresh',
  'controlleradherence',
  'nighttimeresucebaseline',
  'rescuebaseline',
  'search',
  'profile-new',
  'totalcheckin',
  'stop-new',
  'event',
  'Capa_1',
  'message',
  'delete',
  'Medication',
  'messageplaceholder',
  'calendar-event',
  'addButton',
  'delete-red',
  'deleted',
  'message-new',
  'edit',
  'save',
  'cancel',
  'nomedicationplaceholder',
  'medicationplaceholder',
  'logout',
  'score',
  'clock',
  'profileplaceholder',
  'patient',
  'message-header',
  'profileplaceholder-header',
  'user-header',
  'info-blue',
  'cross',
  'reset',
  'inbox',
  'sent',
  'setting',
  'message-setting',
  'android',
  'ios',
  'greycheck',
  'bluecheck',
  'actionplan-without',
  'success',
  'end_call',
  'incoming_call',
  'billing',
  'no_record_found_placeholder',
  'time_spent',
  'csv',
  'excel',
  'pdf',
  'dailyAvg',
  'full',
  'partial',
  'close-new',
  'call',
  'device_icon',
  'drop_down',
  'play-icon',
  'oxymeter',
  'weight',
  'comment',
  'close_box',
  'audit',
  'review',
  'mark-view',
  'menu',
  'question-mark',
  'basic_question',
  'acceptable',
  'non-acceptable',
  'usable',
  'compliance-report',
  'unauthorize_access',
  'download',
  'success',
  'patient-review',
  'patient-review-details',
  'patient-review-compliance',
  'bell',
  'external-patient',
  'activity'
];
