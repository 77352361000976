import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { switchMap, debounceTime } from "rxjs/operators";
import { ValidationConstant } from "@sharedModule/constants";
import { PatientModel } from "@privateModule/models";
import { MessageService } from "@privateModule/services";
import { AdvisorPatientsListService } from "@privateModule/services";
import { FormBaseComponent } from "@sharedModule/components";
import {
  getNameInitialsFunction,
  removeEmptyFields,
} from "@sharedModule/functions";
import { queryParamsModel } from "@sharedModule/models";

@Component({
  selector: "app-admin-message-compose-dialog",
  templateUrl: "./message-compose-dialog.component.html",
  styleUrls: ["./message-compose-dialog.component.scss"],
})
export class MessageComposeDialogComponent
  extends FormBaseComponent
  implements OnInit
{
  // Constant variables
  validationMsg = new ValidationConstant();

  // Data variables
  messageTypeEnum: any;
  patientDetails: PatientModel;
  patientList: PatientModel[] = [];

  // Form Group Variables
  summaryComposeForm: UntypedFormGroup;
  patientSearchField: UntypedFormControl = new UntypedFormControl("");

  // State variables
  isLoading = false;

  constructor(
    public dialog: MatDialog,
    fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MessageComposeDialogComponent>,
    private advisorPatientsListService: AdvisorPatientsListService,
    private messageService: MessageService
  ) {
    super(fb);
  }

  ngOnInit() {
    if (this.data) {
      this.patientDetails = this.data["patientDetails"];
    } else {
      this.patientNameChangeValue();
    }
    this.createSummaryComposeForm();
    this.getMessageList();
  }

  // Initialization methods
  createSummaryComposeForm = () => {
    this.summaryComposeForm = this.createForm({
      to: [
        {
          value: this.patientDetails ? this.patientDetails.name : "",
          disabled: true,
        },
      ],
      toUser: [
        {
          value: this.patientDetails ? this.patientDetails.personId : [],
          disabled: !!this.patientDetails,
        },
        [Validators.required],
      ],
      action: ["", [Validators.required]],
      messageHeader: ["", [Validators.required]],
    });
  };

  patientNameChangeValue = () => {
    this.patientSearchField.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(() => {
          return this.getPatientListApiCall();
        })
      )
      .subscribe((response) => {
        this.handlePatientListResponse(response);
      });
  };

  handlePatientListResponse(response) {
    this.patientList = response["payload"]["content"];
    // Add already selected patient to response
    const selectedPatients = this.formControls["toUser"].value;
    const uniquePatients = this.patientList.filter(
      (patient) =>
        !selectedPatients.some(
          (selectedPatient) => selectedPatient.personId === patient.personId
        )
    );
    this.patientList = selectedPatients.concat(uniquePatients);
  }

  getMessageList = () => {
    this.getMessageTypeApiCall().subscribe((response) => {
      this.messageTypeEnum = response["payload"];
    });
  };

  // Api Calls
  composeMessageApiCall = (params: any) => {
    return this.messageService.composeMessage(params);
  };

  multipleComposeMessageApiCall = (params: any) => {
    return this.messageService.multipleComposeMessage(params);
  };

  getPatientListApiCall() {
    return this.advisorPatientsListService.getPatientList(
      this.queryParams(),
      false
    );
  }

  getMessageTypeApiCall() {
    return this.messageService.getMessageType();
  }

  // Page event
  onSubmitSummaryComposeForm = (form: UntypedFormGroup) => {
    if (this.onSubmit(form)) {
      this.isLoading = true;
      const params = removeEmptyFields(form.getRawValue());
      if (this.patientDetails) {
        params["toUserId"] = this.patientDetails.personId;
        
        delete params["toUser"];

        this.composeMessageApiCall(params).subscribe({
          next: () => {
            this.isLoading = false;
            this.onCancel(true);
          },
          error: () => {
            this.isLoading = false;
          },
        });
      } else {
        params["toUserIdList"] = this.formControls["toUser"].value.map(
          (patient: PatientModel) => patient.personId
        );

        delete params["toUser"];

        this.multipleComposeMessageApiCall(params).subscribe({
          next: () => {
            this.isLoading = false;
            this.onCancel(true);
          },
          error: () => {
            this.isLoading = false;
          },
        });
      }
    }
  };

  onCancel(status: boolean): void {
    this.dialogRef.close(status);
  }

  // Helper methods
  get formControls() {
    return this.summaryComposeForm.controls;
  }

  getNameInitials = () => {
    return getNameInitialsFunction(this.patientDetails);
  };

  queryParams(): any {
    const andCriteriaList: queryParamsModel[] = [];
    const orCriteriaList: queryParamsModel[] = [];
    let criteria: queryParamsModel;
    const params = {
      page: 0,
      size: 20,
    };

    if (this.patientSearchField.value) {
      const value = this.patientSearchField.value;
      criteria = {
        column: "name",
        values: [value],
        operator: "like",
      };
      orCriteriaList.push(criteria);

      criteria = {
        column: "email",
        values: [value],
        operator: "like",
      };
      orCriteriaList.push(criteria);
    }

    criteria = {
      column: "voided",
      values: [0],
      operator: "=",
    };
    andCriteriaList.push(criteria);

    params["orCriteria"] = orCriteriaList;
    params["criteria"] = andCriteriaList;
    params["sort"] = {
      column: "name",
      sortType: "desc",
    };
    return params;
  }
}
