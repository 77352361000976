import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AdminApi,
  HttpMethodsTypeEnum,
} from '@sharedModule/constants';
import { APIManager, SharedUserService } from '@sharedModule/shared-service';

@Injectable({
  providedIn: 'root',
})
export class SetPasswordService {
  constructor(
    private apiManager: APIManager,
    private adminSharedService: SharedUserService,
  ) {}

  getResetPasswordInformation(id: string): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      `${AdminApi.INFORMATION_RESET_PASSWORD}/${id}`,
      {},
      this.apiManager.Content_Type_HttpOptions,
      false,
      true,
    );
  }

  setPasswordPatient(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PATIENT_SET_PASSWORD,
      params,
      this.adminSharedService.Set_Password_Process_Header,
      false,
      true,
    );
  }

  setPasswordPhysician(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.PHYSICIAN_SET_PASSWORD,
      params,
      this.adminSharedService.Set_Password_Process_Header,
      false,
      true,
    );
  }
}
