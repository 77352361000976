import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminLoginService } from "@publicModule/services";
import { SharedService, SharedUserService } from "@sharedModule/shared-service";
import { ToastStatus } from "@sharedModule/constants";
import { FileModel } from "@sharedModule/components/download-progress/download-progress.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  toastSubscriber$: any;
  downloadTaskList: FileModel[] = [];

  constructor(
    private toastr: ToastrService,
    private _sharedService: SharedService,
    private adminLoginService: AdminLoginService,
    private adminSharedService: SharedUserService
  ) {}

  ngOnInit() {
    this.configureToastManager();
    if (this.isLoggedInAdmin && !this._sharedService.isConsultant()) {
      this.getConfigData();
    }
    this._sharedService.getDownloadTaskList().subscribe((taskList) => {
      this.downloadTaskList = taskList;
    });
    // this._sharedService.setToastMessage('test', ToastStatus.SUCCESS);
  }

  configureToastManager() {
    this.toastSubscriber$ = this._sharedService
      .getToastMessage()
      .subscribe((msgBody) => {
        if (msgBody) {
          if (msgBody.type === ToastStatus.SUCCESS) {
            setTimeout(() =>
              this.toastr.success(msgBody.message, msgBody.title)
            );
          } else if (msgBody.type === ToastStatus.ERROR) {
            setTimeout(() => this.toastr.error(msgBody.message, msgBody.title));
          }
          this._sharedService.setToastMessage("", ToastStatus.UNKNOWN);
        }
      });
  }

  getConfigData = () => {
    this.adminConfigApiCall().subscribe((response) => {
      this.adminSharedService.setConfigData(response["payload"]);
    });
  };

  ngOnDestroy() {
    if (this.toastSubscriber$) {
      this.toastSubscriber$.unsubscribe();
    }
  }

  // Api calls
  adminConfigApiCall = () => {
    return this.adminLoginService.getConfigData();
  };

  get isLoggedInAdmin() {
    return this._sharedService.isLoggedInAdmin();
  }
}
