import {Component} from '@angular/core';

@Component({
  selector: 'ss-table-base',
  template: ''
})
export class TableBaseComponent {

  isAllSelected = (dataSource, selection) => {
    const numSelected = selection.selected.length;
    const numRows = dataSource.length;
    return numSelected === numRows;
  };
  masterToggle = (dataSource, selection) => {
    this.isAllSelected(dataSource, selection) ? selection.clear() : dataSource.forEach(row => selection.select(row));
  };

  constructor() {
  }

}
