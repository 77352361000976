import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import {
  CommonRegexp,
  CommunicationType,
  AppPrivateRoutes,
} from "@sharedModule/constants";
import {
  ChangePasswordDialogComponent,
  FormBaseComponent,
} from "@sharedModule/components";
import { AdminLoginService } from "@publicModule/services";
import { SharedService, SharedUserService } from "@sharedModule/shared-service";

@Component({
  selector: "app-admin-advisor-login-otp",
  templateUrl: "./advisor-login-otp.component.html",
  styleUrls: ["./advisor-login-otp.component.scss"],
})
export class AdvisorLoginOtpComponent
  extends FormBaseComponent
  implements OnInit
{
  // Form related variables
  otpForm: UntypedFormGroup;

  // Constant variables
  communicationType = CommunicationType;

  // Data related variables
  emailAddress: any;
  duration = 300;
  minutes = "00";
  seconds = "00";
  clockDisplay: string;
  interval: any;
  userDetails: any;

  // State Variables
  showResendOTP: boolean;
  showResendOTPButton = true;

  @ViewChild("inputRef1", { static: true }) inputRef1;
  @ViewChild("inputRef2", { static: true }) inputRef2;
  @ViewChild("inputRef3", { static: true }) inputRef3;
  @ViewChild("inputRef4", { static: true }) inputRef4;
  @ViewChild("inputRef5", { static: true }) inputRef5;
  @ViewChild("inputRef6", { static: true }) inputRef6;

  constructor(
    private router: Router,
    fb: UntypedFormBuilder,
    private adminSharedService: SharedUserService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private adminLoginService: AdminLoginService
  ) {
    super(fb);
  }

  ngOnInit() {
    this.userDetails = this.sharedService.getUser();
    this.emailAddress = this.sharedService.getUser()["email"];
    this.createVerificationCodeFrom();
    this.initiateTimer(300);
  }

  // Initialization methods
  createVerificationCodeFrom() {
    this.otpForm = this.createForm({
      otpInput1: [
        "",
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput2: [
        "",
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput3: [
        "",
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput4: [
        "",
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput5: [
        "",
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput6: [
        "",
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
    });
    setTimeout(() => {
      this.inputRef1.nativeElement.focus();
    }, 100);
  }

  initiateTimer(duration: number) {
    if (duration > 0) {
      this.interval = setInterval(() => {
        if (duration <= 0) {
          clearInterval(this.interval);
          this.showResendOTPButton = true;
          this.showResendOTP = true;
          this.sharedService.setResendOTP(0);
        }
        duration = duration - 1;
        const minutes = Math.floor(duration / 60);
        const seconds = duration - minutes * 60;
        if (minutes > 9) {
          this.minutes = minutes.toString();
        } else {
          this.minutes = "0" + minutes.toString();
        }
        if (seconds > 9) {
          this.seconds = seconds.toString();
        } else {
          this.seconds = "0" + seconds.toString();
        }
        this.clockDisplay = this.minutes + " : " + this.seconds;
        if (duration <= 0) {
          if (duration === 0) {
            this.sharedService.setResendOTP(0);
          }
          this.showResendOTPButton = true;
          this.showResendOTP = true;
          clearInterval(this.interval);
        } else {
          this.sharedService.setResendOTP(duration);
          this.showResendOTP = false;
        }
      }, 1000);
    }
  }

  // Api calls
  adminConfigApiCall = () => {
    return this.adminLoginService.getConfigData();
  };

  // Page events
  onSubmitVerificationCodeForm(form: UntypedFormGroup) {
    if (this.onSubmit(form)) {
      const verificationCodeParams = {
        otp:
          form.value["otpInput1"] +
          form.value["otpInput2"] +
          form.value["otpInput3"] +
          form.value["otpInput4"] +
          form.value["otpInput5"] +
          form.value["otpInput6"],
      };
      this.adminLoginService
        .verifyLoginVerificationCode(verificationCodeParams)
        .subscribe((response) => {
          this.sharedService.setToken(response.headers.get("X-Auth"));
          this.sharedService.setUser(response["body"]["payload"]);
          this.getConfigData();
          if (response["body"]["payload"]["tempUser"]) {
            this.openChangePasswordDialog();
          }
          const url = this.adminSharedService.getRedirectUrl();
          if (url) {
            this.router.navigate([url]);
          } else {
            this.router.navigate(["/" + AppPrivateRoutes.PATIENTS_ROUTE]);
          }
        });
    }
  }

  openChangePasswordDialog = () => {
    this.dialog.open(ChangePasswordDialogComponent, {
      panelClass: "change-password-dialog-container",
      data: {
        forceChangeRequired: true,
      },
    });
  };

  getConfigData = () => {
    this.adminConfigApiCall().subscribe((response) => {
      this.adminSharedService.setConfigData(response["payload"]);
      this.sharedService.setLoginRequired(true);
    });
  };

  onClickResendCode() {
    this.adminLoginService
      .resendLoginVerificationCode()
      .subscribe((response) => {
        this.initiateTimer(this.duration);
        this.showResendOTPButton = true;
      });
  }

  /**
   * Field Blur Event
   */
  onBlurInput(event, currentRef, nextRef, prevRef) {
    if (event.keyCode === 16) {
      event.preventDefault();
    } else if (event.keyCode === 37) {
      if (prevRef) {
        setTimeout(() => {
          prevRef.focus();
        }, 1);
      }
    } else if (event.keyCode !== 8) {
      if (nextRef) {
        setTimeout(() => {
          nextRef.focus();
        }, 1);
      }
    }
  }

  /**
   * Field Focus Event
   */
  onFocus(currentRef) {
    const originalValue = currentRef.value;
    currentRef.value = "";
    currentRef.blur();
    currentRef.focus();
    currentRef.value = originalValue;
  }

  onPasteEvent(event: ClipboardEvent) {
    const clipboardData = event.clipboardData; // || window.clipboardData
    const pastedText = clipboardData.getData("text");
    const arry = pastedText.split("");

    for (let i = 0; i < arry.length; i++) {
      this.otpForm.get("otpInput" + (i + 1)).setValue(arry[i]);
    }
    this.inputRef6.nativeElement.focus();
  }
}
