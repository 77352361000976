import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {AdminForgotPasswordService} from '@publicModule/services';
import {
  CommonRegexp,
  AppPublicRoutes,
} from '@sharedModule/constants';
import {FormBaseComponent} from '@sharedModule/components';
import { SharedService, SharedUserService } from '@sharedModule/shared-service';

@Component({
  selector: 'app-admin-advisor-forgot-otp',
  templateUrl: './advisor-forgot-otp.component.html',
  styleUrls: ['./advisor-forgot-otp.component.scss'],
})
export class AdvisorForgotOtpComponent
  extends FormBaseComponent
  implements OnInit
{
  // Form related variables
  otpForm: UntypedFormGroup;

  // Data related variables
  emailAddress: any;
  duration = 300;
  minutes = '00';
  seconds = '00';
  clockDisplay: string;
  interval: any;

  // State Variables
  showResendOTP: boolean;
  showResendOTPButton = true;

  @ViewChild('inputRef1', {static: true}) inputRef1;
  @ViewChild('inputRef2', {static: true}) inputRef2;
  @ViewChild('inputRef3', {static: true}) inputRef3;
  @ViewChild('inputRef4', {static: true}) inputRef4;
  @ViewChild('inputRef5', {static: true}) inputRef5;
  @ViewChild('inputRef6', {static: true}) inputRef6;

  constructor(
    private router: Router,
    fb: UntypedFormBuilder,
    private adminForgotPasswordService: AdminForgotPasswordService,
    private adminSharedService: SharedUserService,
    private sharedService: SharedService,
  ) {
    super(fb);
  }

  ngOnInit() {
    this.emailAddress = this.adminSharedService.getAdminEmailAddress();
    this.createVerificationCodeFrom();
    this.initiateTimer(300);
  }

  // Initialization methods
  createVerificationCodeFrom() {
    this.otpForm = this.createForm({
      otpInput1: [
        '',
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput2: [
        '',
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput3: [
        '',
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput4: [
        '',
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput5: [
        '',
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
      otpInput6: [
        '',
        [
          <any>Validators.required,
          <any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
        ],
      ],
    });
    setTimeout(() => {
      this.inputRef1.nativeElement.focus();
    }, 100);
  }

  initiateTimer(duration: number) {
    if (duration > 0) {
      this.interval = setInterval(() => {
        if (duration <= 0) {
          clearInterval(this.interval);
          this.showResendOTPButton = true;
          this.showResendOTP = true;
          this.sharedService.setResendOTP(0);
        }
        duration = duration - 1;
        const minutes = Math.floor(duration / 60);
        const seconds = duration - minutes * 60;
        if (minutes > 9) {
          this.minutes = minutes.toString();
        } else {
          this.minutes = '0' + minutes.toString();
        }
        if (seconds > 9) {
          this.seconds = seconds.toString();
        } else {
          this.seconds = '0' + seconds.toString();
        }
        this.clockDisplay = this.minutes + ' : ' + this.seconds;
        if (duration <= 0) {
          if (duration === 0) {
            this.sharedService.setResendOTP(0);
          }
          this.showResendOTPButton = true;
          this.showResendOTP = true;
          clearInterval(this.interval);
        } else {
          this.sharedService.setResendOTP(duration);
          this.showResendOTP = false;
        }
      }, 1000);
    }
  }

  // Page events
  onSubmitVerificationCodeForm(form: UntypedFormGroup) {
    if (this.onSubmit(form)) {
      const verificationCodeParams = {
        otp:
          form.value['otpInput1'] +
          form.value['otpInput2'] +
          form.value['otpInput3'] +
          form.value['otpInput4'] +
          form.value['otpInput5'] +
          form.value['otpInput6'],
      };
      this.adminForgotPasswordService
        .verifyForgotPasswordVerificationCode(verificationCodeParams)
        .subscribe((response) => {
          this.handleForgotPasswordOtpResponse(response);
        });
    }
  }

  handleForgotPasswordOtpResponse(response: any) {
    this.adminSharedService.setAdminSignUpToken(response.headers.get('X-Auth'));
    this.adminSharedService.setAdminEmailAddress('');
    this.router.navigate(['/' + AppPublicRoutes.RESET_PASSWORD]);
  }

  onClickResendCode() {
    this.adminForgotPasswordService
      .resendForgotPasswordVerificationCode()
      .subscribe((response) => {
        this.adminSharedService.setAdminSignUpToken(
          response.headers.get('X-Auth'),
        );
        this.initiateTimer(this.duration);
        this.showResendOTPButton = true;
      });
  }

  onBackForgotPassword() {
    this.clearOutSessions();
    this.router.navigate(['/' + AppPublicRoutes.FORGOT_PASSWORD]);
  }

  clearOutSessions() {
    this.adminSharedService.setAdminSignUpDetails({});
    this.adminSharedService.setAdminEmailAddress('');
    this.adminSharedService.setAdminSignUpToken('');
  }

  // Helper methods
  /**
   * Field Blur Event
   */
  onBlurInput(event, currentRef, nextRef, prevRef) {
    if (event.keyCode === 16) {
      event.preventDefault();
    } else if (event.keyCode === 37) {
      if (prevRef) {
        setTimeout(() => {
          prevRef.focus();
        }, 1);
      }
    } else if (event.keyCode !== 8) {
      if (nextRef) {
        setTimeout(() => {
          nextRef.focus();
        }, 1);
      }
    }
  }

  /**
   * Field Focus Event
   */
  onFocus(currentRef) {
    const originalValue = currentRef.value;
    currentRef.value = '';
    currentRef.blur();
    currentRef.focus();
    currentRef.value = originalValue;
  }

  onPasteEvent(event: ClipboardEvent) {
    const clipboardData = event.clipboardData; // || window.clipboardData
    const pastedText = clipboardData.getData('text');
    const array = pastedText.split('');
    for (let i = 0; i < array.length; i++) {
      this.otpForm.get('otpInput' + (i + 1)).setValue(array[i]);
    }
    this.inputRef6.nativeElement.focus();
  }
}
