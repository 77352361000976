import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppPrivateRoutes } from "@sharedModule/constants";

@Component({
  selector: "app-unauthorize-access-dialog",
  templateUrl: "./unauthorize-access-dialog.component.html",
  styleUrls: ["./unauthorize-access-dialog.component.scss"],
})
export class UnauthorizeAccessDialogComponent {
  constructor(
    private _router: Router,
  ) {}

  // Page events
  onClickHome() {
    this._router.navigate(["/" + AppPrivateRoutes.PATIENTS_ROUTE]);
  }
}
