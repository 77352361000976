import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import { AdminLoginService } from "@publicModule/services";
import {
  AppPrivateRoutes,
  AppPublicRoutes,
  OperatorEnum,
  PAGE_SIZE_TWENTY,
  ValidationConstant,
} from "@sharedModule/constants";
import {
  ChangePasswordDialogComponent,
  FormBaseComponent,
} from "@sharedModule/components";
import { SharedService, SharedUserService } from "@sharedModule/shared-service";
import { lastValueFrom } from "rxjs";
import { PatientModel } from "@privateModule/models";
import { SharePatientService } from "@privateModule/services/share-patient/share-patient.service";

@Component({
  selector: "app-admin-advisor-login",
  templateUrl: "advisor-login.component.html",
  styleUrls: ["advisor-login.component.scss"],
  providers: [AdminLoginService],
})
export class AdvisorLoginComponent extends FormBaseComponent implements OnInit {
  // Constant Variables
  validationMsg = new ValidationConstant();

  // FormGroup Variables
  loginForm: UntypedFormGroup;

  constructor(
    fb: UntypedFormBuilder,
    private _router: Router,
    private router: Router,
    private _adminLoginService: AdminLoginService,
    private _sharedService: SharedService,
    public dialog: MatDialog,
    private adminSharedService: SharedUserService,
    private sharePatientService: SharePatientService
  ) {
    super(fb);
  }

  /**
   * convenience getter for easy access to form fields
   */
  get formControls() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.createLoginForm();
  }

  /**
   * Create Login Form
   */
  createLoginForm() {
    this.loginForm = this.createForm({
      username: [
        "",
        [
          <any>Validators.required,
          <any>Validators.minLength(2),
          <any>Validators.maxLength(50),
        ],
      ],
      password: ["", [<any>Validators.required]],
    });
  }

  // Api calls
  adminConfigApiCall = () => {
    return this._adminLoginService.getConfigData();
  };

  /**
   * login method
   * @param form
   */
  onSubmitLoginForm(form: UntypedFormGroup) {
    if (this.onSubmit(form)) {
      this._adminLoginService.login(form.value).subscribe((response) => {
        this.handleAdminLoginResponse(response);
      });
    }
  }

  async handleAdminLoginResponse(response: any) {
    if (response["body"]["payload"]["twoFactAuth"]) {
      this._sharedService.setVerificationToken(response.headers.get("X-Auth"));
      this._sharedService.setUser(response["body"]["payload"]);
      this._router.navigate(["/" + AppPublicRoutes.LOGIN_OTP]);
    } else {
      this._sharedService.setToken(response.headers.get("X-Auth"));
      this._sharedService.setUser(response["body"]["payload"]);
      const url = this.adminSharedService.getRedirectUrl();
      if (this._sharedService.isConsultant()) {
        const response = await lastValueFrom(
          this.sharePatientService.getPatientList(this.getQueryParams())
        );
        const patients: PatientModel[] = response?.payload?.content;
        this._sharedService.setPatientListData(patients);
        this._sharedService.setLoginRequired(true);
        if (
          url &&
          url.includes("/" + AppPrivateRoutes.PATIENTS_DETAILS_ROUTE)
        ) {
          this._router.navigate([url]);
        } else {
          if (patients.length) {
            this._router.navigate([
              "/" + AppPrivateRoutes.PATIENTS_DETAILS_ROUTE,
              patients[0].personId,
            ]);
          }
        }
      } else {
        this.getConfigData();
        if (url) {
          this._router.navigate([url]);
        } else {
          this._router.navigate(["/" + AppPrivateRoutes.PATIENTS_ROUTE]);
        }
        if (response["body"]["payload"]["tempUser"]) {
          this.openChangePasswordDialog();
        }
      }
    }
  }

  getQueryParams = () => {
    const params = {
      page: 0,
      size: PAGE_SIZE_TWENTY,
      criteria: [
        {
          column: "voided",
          values: [0],
          operator: OperatorEnum.EQUALS,
        },
      ],
      sort: { column: "personId", sortType: "desc" },
    };

    return params;
  };

  getConfigData = () => {
    this.adminConfigApiCall().subscribe((response) => {
      this.adminSharedService.setConfigData(response["payload"]);
      this._sharedService.setLoginRequired(true);
    });
  };

  openChangePasswordDialog = () => {
    this.dialog.open(ChangePasswordDialogComponent, {
      panelClass: "change-password-dialog-container",
      data: {
        forceChangeRequired: true,
      },
    });
  };

  onForgotPasswordOpen() {
    this.router.navigate(["/" + AppPublicRoutes.FORGOT_PASSWORD]);
  }
}
