export class AppPublicRoutes {
  public static APPLE_SITE_AASSOCIATION = "apple-app-site-association";
  public static LOGIN = "login";
  public static LOGIN_OTP = "login-otp";
  public static FORGOT_PASSWORD = "forgot-password";
  public static FORGOT_OTP = "forgot-otp";
  public static RESET_PASSWORD = "reset-password";
  public static CHANGE_PASSWORD = "change-password";
  public static SET_PASSWORD = "set-password";
  public static KTALK_ROUTE = "ktalk";
}
export class AppPrivateRoutes {
  public static AUTH_MODULE_ROUTE = "";

  public static PATIENTS_ROUTE = "patients";
  public static USERS_ROUTE = "users";
  public static BILLING_ROUTE = "billing";
  public static BILLING_PROGRESS_ROUTE = "progress";
  public static BILLING_REPORT_ROUTE = "report";
  public static DEVICES_ROUTE = "device";
  public static COMPLIANCE_REPORT_ROUTE = "compliance-report";
  public static AUDIT_ROUTE = "audit";
  public static AUDIT_TIME_SPENT_ROUTE = "time-spent";
  public static REVIEW_ROUTE = "review";
  public static USER_VIEW_PROFILE = "view-profile";
  public static PATIENT_REVIEW = "patients-review";
  // public static XEALTH_ROUTE = "xealth";

  public static readonly PATIENTS_DETAILS_ROUTE = `patients/details`;
  public static readonly ADVISOR_CONTAINER_ROUTE = "message-container";
  public static readonly UNAUTHORIZED_ACCESS_ROUTE = 'unauthorized-access';
}
