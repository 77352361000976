import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {
  CommonRegexp,
  passwordRegex,
  ValidationConstant,
  AppPublicRoutes,
} from '@sharedModule/constants';
import {FormBaseComponent} from '@sharedModule/components';
import {AdminForgotPasswordService} from '@publicModule/services';
import { SharedUserService } from '@sharedModule/shared-service';

@Component({
  selector: 'app-admin-advisor-reset-password',
  templateUrl: './advisor-reset-password.component.html',
  styleUrls: ['./advisor-reset-password.component.scss'],
})
export class AdvisorResetPasswordComponent
  extends FormBaseComponent
  implements OnInit
{
  validationMsg = new ValidationConstant();

  // Form related variables
  resetPasswordForm: UntypedFormGroup;

  // Check reset password validation variables
  passwordError = [];

  constructor(
    private router: Router,
    fb: UntypedFormBuilder,
    private adminForgotPasswordService: AdminForgotPasswordService,
    private adminSharedService: SharedUserService,
  ) {
    super(fb);
  }

  ngOnInit() {
    this.createResetPwdForm();
  }

  // Initialisation methods
  createResetPwdForm() {
    this.resetPasswordForm = this.createForm(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(30),
            Validators.pattern(CommonRegexp.PASSWORD_REGEXP),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {validator: this.validate},
    );
  }

  // Page events
  onSubmitResetPasswordForm(form: UntypedFormGroup) {
    if (this.onSubmit(form)) {
      this.adminForgotPasswordService
        .resetPassword(form.value)
        .subscribe((response) => {
          this.onClickLogIn();
        });
    }
  }

  onClickLogIn() {
    this.adminSharedService.setAdminSignUpToken('');
    this.router.navigate(['/' + AppPublicRoutes.LOGIN]);
  }

  // Helper methods
  validate(registrationFormGroup: UntypedFormGroup) {
    const password = registrationFormGroup.controls['password'];
    const repeatPassword = registrationFormGroup.controls['confirmPassword'];
    if (repeatPassword.value.length === 0) {
      return null;
    }
    if (repeatPassword.value !== password.value) {
      repeatPassword.setErrors({incorrect: true});
      return {
        doesMatchPassword: true,
      };
    }
    return null;
  }

  /**
   * On reset password validation check value
   * @param form
   */
  onCheckValue = (form: UntypedFormGroup) => {
    const {lowerCaseLetters, upperCaseLetters, numbers, specialCharacter} =
      passwordRegex;
    const value = form.controls.password.value;
    this.passwordError[1] = value.match(lowerCaseLetters);
    this.passwordError[0] = value.match(upperCaseLetters);
    this.passwordError[3] = value.match(numbers);
    this.passwordError[2] = value.match(specialCharacter);
    this.passwordError[4] = value.length >= 8;
  };
}
