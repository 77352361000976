import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { PrivateModuleRoutingModule } from "@privateModule/private-routing.module";
import { SharedModule } from "@sharedModule/shared.module";
import {
  AdvisorMessageDetailsComponent,
  MedicationPatientDetailsDialogComponent,
  MessageComposeDialogComponent,
} from "@privateModule/components";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

@NgModule({
  declarations: [
    AdvisorMessageDetailsComponent,
    MedicationPatientDetailsDialogComponent,
    MessageComposeDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PrivateModuleRoutingModule,
    NgxMatSelectSearchModule,
  ],
  exports: [],
})
export class PrivateModule {}
