import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {
  CommonRegexp,
  ValidationConstant,
  AppPublicRoutes,
} from '@sharedModule/constants';
import {FormBaseComponent} from '@sharedModule/components';
import {AdminForgotPasswordService} from '@publicModule/services';
import { SharedUserService } from '@sharedModule/shared-service';

@Component({
  selector: 'app-admin-advisor-forgot-password',
  templateUrl: './advisor-forgot-password.component.html',
  styleUrls: ['./advisor-forgot-password.component.scss'],
})
export class AdvisorForgotPasswordComponent
  extends FormBaseComponent
  implements OnInit
{
  validationMsg = new ValidationConstant();

  forgotEmailForm: UntypedFormGroup;

  constructor(
    private router: Router,
    fb: UntypedFormBuilder,
    private adminForgotPasswordService: AdminForgotPasswordService,
    private adminSharedService: SharedUserService,
  ) {
    super(fb);
  }

  ngOnInit() {
    this.createForgotPwdForm();
  }

  createForgotPwdForm() {
    this.forgotEmailForm = this.createForm({
      email: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(100),
          Validators.pattern(CommonRegexp.EMAIL_ADDRESS_REGEXP),
        ],
      ],
    });
  }

  // Page events
  onSubmitForgotEmailForm(form: UntypedFormGroup) {
    if (this.onSubmit(form)) {
      this.adminForgotPasswordService
        .forgotPassword(form.value)
        .subscribe((response) => {
          this.handleForgotPasswordResponse(response, form.value);
        });
    }
  }

  handleForgotPasswordResponse(response: any, value: any) {
    this.adminSharedService.setAdminSignUpToken(response.headers.get('X-Auth'));
    this.adminSharedService.setAdminEmailAddress(value['email']);
    this.router.navigate(['/' + AppPublicRoutes.FORGOT_OTP]);
  }

  onClickLogIn() {
    this.router.navigate(['/' + AppPublicRoutes.LOGIN]);
  }
}
