import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpMethodsTypeEnum, AdminApi } from "@sharedModule/constants";
import { APIManager } from "@sharedModule/shared-service";

@Injectable({
  providedIn: "root",
})
export class AdminLoginService {
  constructor(private _apiManager: APIManager) {}

  login(params): Observable<any> {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.LOGIN,
      params,
      this._apiManager.Content_Type_HttpOptions,
      false,
      true
    );
  }

  getConfigData = (): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.ADMIN_CONFIG,
      {},
      this._apiManager.HttpOptions,
      false,
      true
    );
  };

  verifyLoginVerificationCode = (params: any): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.LOGIN_VERIFICATION_CODE,
      params,
      this._apiManager.Auth_User_Signup_HttpOptions,
      false,
      true
    );
  };

  resendLoginVerificationCode = (): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.RESEND_LOGIN_VERIFICATION_CODE,
      {},
      this._apiManager.HttpOptionsWithObserve,
      false,
      true
    );
  };
}
