<!-- admin-header start -->
<div class="header">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-sm-6 col-xs-6 PLR-0 sm-PR-0 xs-PR-0">
        <div class="header__logo">
          <mat-icon
            *ngIf="!isConsultant"
            (click)="onShowNavbarPhone()"
            class="menu-icon"
            svgIcon="menu"
          ></mat-icon>

          <mat-icon svgIcon="logo-header"></mat-icon>
        </div>

        <div
          *ngIf="!isConsultant && isShowNavbar"
          class="header__nav"
          (click)="onChangeTab()"
        >
          <ul>
            <li>
              <a [routerLink]="patientsManagementUrl" routerLinkActive="active">
                <mat-icon svgIcon="patient"></mat-icon>
                <span>Patients</span>
              </a>
            </li>

            <li>
              <a [routerLink]="usersManagementUrl" routerLinkActive="active">
                <mat-icon svgIcon="user-header"></mat-icon>
                <span>Staff</span>
              </a>
            </li>

            <li *ngIf="isSystemAdmin">
              <a [routerLink]="billingManagementUrl" routerLinkActive="active">
                <mat-icon class="billing-icon" svgIcon="billing"></mat-icon>
                <span>Billing</span>
              </a>
            </li>

            <li *ngIf="isSystemAdmin">
              <a [routerLink]="loginAuditUrl" routerLinkActive="active">
                <mat-icon svgIcon="audit"></mat-icon>
                <span>Audit</span>
              </a>
            </li>

            <li>
              <a [routerLink]="reviewUrl" routerLinkActive="active">
                <mat-icon svgIcon="review"></mat-icon>
                <span class="ML-08">Review</span>
              </a>
            </li>

            <li *ngIf="isSystemAdmin">
              <a [routerLink]="deviceManagementUrl" routerLinkActive="active">
                <mat-icon class="device-icon" svgIcon="device_icon"></mat-icon>
                <span>Devices</span>
              </a>
            </li>

            <!-- <li>
              <a [routerLink]="complianceReportUrl" routerLinkActive="active">
                <mat-icon svgIcon="compliance-report"></mat-icon>
                <span>Compliance report</span>
              </a>
            </li> -->

            <li>
              <a [routerLink]="patientReviewUrl" routerLinkActive="active">
                <mat-icon svgIcon="patient-review"></mat-icon>
                <span>Patient Review</span>
              </a>
            </li>

            <!-- <li>
              <a [routerLink]="xealthUrl" routerLinkActive="active">
                <img
                  src="./assets/images/logoXealthWhite.png"
                  height="20"
                  alt=""
                />
                <span class="ML-08">Xealth</span>
              </a>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-xs-6 PLR-0 sm-PL-0 xs-PL-0">
        <div class="header__profile">
          <ul>
            <li class="help-number MR-1">
              <a>
                <mat-icon
                  matTooltip="support phone number"
                  svgIcon="call"
                ></mat-icon>
              </a>
              <a class="support-number" href="1-888-727-KEVA">
                1-888-727-KEVA
              </a>
            </li>

            <li *ngIf="!isConsultant">
              <a
                [routerLink]="MessageManagementUrl"
                routerLinkActive="active"
                class="header__profile__message-border"
              >
                <mat-icon svgIcon="message-header"></mat-icon>
                <label
                  class="badge-count"
                  *ngIf="!!configData?.unreadMessages"
                  >{{
                    getUnreadMessageCount(configData?.unreadMessages)
                      | checkEmpty
                  }}</label
                >
              </a>
            </li>

            <li class="ML-1">
              <a
                class="header__profile__dropdown-spacing"
                (click)="onShowDropdown()"
              >
                <mat-icon
                  class="header__profile__dropdown-spacing__icon"
                  svgIcon="profileplaceholder-header"
                >
                </mat-icon>

                <span>{{ userDetail.userName }}</span>

                <mat-icon svgIcon="dropdown-header"></mat-icon>
              </a>

              <div *ngIf="isShowDropDown" class="dropdown-actions">
                <ul>
                  <li>
                    <div class="dropdown-actions__row">
                      <div class="dropdown-actions__row__img">
                        <!--<img src="assets/images/profile.png"/>-->
                        <mat-icon
                          class="user-placeholder"
                          svgIcon="profileplaceholder"
                        ></mat-icon>
                      </div>

                      <div class="dropdown-actions__row__details">
                        <h3>{{ userDetail.userName }}</h3>
                        <a
                          *ngIf="!isConsultant"
                          (click)="onUserViewProfileDropdown()"
                          [routerLink]="userViewProfileUrl"
                          routerLinkActive="active"
                          >View Profile</a
                        >
                      </div>
                    </div>
                  </li>

                  <li>
                    <a (click)="onChangePasswordOpen()">Change Password</a>
                    <a *ngIf="isSystemAdmin" (click)="onClickTempClean()"
                      >Clear Cache</a
                    >
                    <a (click)="onClickLogout()">
                      Logout
                      <mat-icon svgIcon="logout"></mat-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div
          (click)="isShowDropDown = false"
          *ngIf="isShowDropDown"
          class="white-bg-transparent"
        ></div>
      </div>
    </div>
  </div>
  <!-- header nav start -->
  <!-- <div class="header__nav">
    <div class="container">
      <ul>
        <li><a>
            <mat-icon svgIcon="home"></mat-icon>
            Dashboard
          </a></li>
        <li><a [routerLink]="usersManagementUrl" routerLinkActive="active">
            <mat-icon svgIcon="user"></mat-icon>
            Users
          </a></li>
        <li><a [routerLink]="patientsManagementUrl" routerLinkActive="active">
            <mat-icon svgIcon="user"></mat-icon>
            Patients
          </a></li>
        <li><a>
            <mat-icon svgIcon="settings"></mat-icon>
            Setting
          </a></li>
      </ul>
    </div>
  </div> -->
  <!-- header nav end -->
</div>
<!-- admin-header end -->
