import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AdminApi,
  HttpMethodsTypeEnum,
} from '@sharedModule/constants';
import { APIManager } from '@sharedModule/shared-service';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService {
  constructor(private apiManager: APIManager) {}

  getUserList(params: any, flag = false): Observable<any> {
    const url = flag
      ? AdminApi.USER_LIST + '?isPhysicianRequired=true'
      : AdminApi.USER_LIST;
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      url,
      params,
      this.apiManager.HttpOptions,
      false,
      true,
    );
  }

  deleteUser(id: number): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.DELETE,
      `${AdminApi.USER}/${id}`,
      {},
      this.apiManager.HttpOptions,
      true,
      true,
    );
  }

  updateUserProfile(param, id): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.PUT,
      `${AdminApi.USER}/${id}`,
      param,
      this.apiManager.HttpOptions,
      true,
      true,
    );
  }

  changePassword(id: number, params): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      `${AdminApi.CHANGE_PASSWORD}${id}` + '/password/change',
      params,
      this.apiManager.HttpOptions,
      true,
      true,
    );
  }

  exportStaffList(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.EXPORT_STAFF_LISTING,
      params,
      this.apiManager.Blob_Progress_HttpOptions,
      false,
      false,
    );
  }
}
