import { Component } from '@angular/core';

@Component({
  selector: 'app-ktalk',
  templateUrl: './ktalk.component.html',
  styleUrls: ['./ktalk.component.scss']
})
export class KtalkComponent {

}
