export class CommonRegexp {
  public static NUMERIC_REGEXP = "^[0-9]*$";
  public static ALPHA_NUMERIC_REGEXP = "^[A-Za-z0-9 ]*$";
  public static ALPHA_NUMERIC_WITHOUT_SPACE_REGEXP = "^[a-zA-Z0-9]*$";
  public static ALPHA_NUMERIC_DOT_REGEXP = "^[A-Za-z0-9. ]*$";
  public static USER_NAME_REGEXP = "^[A-Za-z0-9-_. ]*$";
  public static MOBILE_NO_REGEXP = "^[0-9-+ ]*$";
  public static EMAIL_ADDRESS_REGEXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static PASSWORD_REGEXP =
    /^(?=.*[a-zA-Z])(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d#?!@$%^&*-]{8,}$/;
}

export class ValidationConstant {
  public static FOUR_LENGTH = 4;
  public static HUNDRED_LENGTH = 100;

  public REQUIRED = `is required`;

  public USERNAME_REQUIRED = `Username ${this.REQUIRED}`;
  public USERNAME_LENGTH = `Username length must between 2 to 50 characters`;
  public USERNAME_VALID = "Username is invalid";

  public PASSWORD_REQUIRED = `Password ${this.REQUIRED}`;
  public PASSWORD_LENGTH = `Password should be between 8 to 30 alphanumeric characters`;
  public PASSWORD_VALID = "Password is invalid";
  public VALID_PASSWORD = `Password allowed only alphanumeric and #?!@$%^&*-`;
  public NOT_MATCHED = `Confirm password doesn't match`;

  public EMAIL = `Email ${this.REQUIRED}`;
  public EMAIL_LENGTH = `Email length should be between ${ValidationConstant.FOUR_LENGTH} to ${ValidationConstant.HUNDRED_LENGTH} characters`;
  public VALID_EMAIL_ADDRESS = `Please enter valid email address`;

  public CURRENT_PASSWORD = `Current password ${this.REQUIRED}`;
  public CURRENT_PASSWORD_LENGTH = `Password should be between 8 to 30 alphanumeric characters`;

  public CONFIRM_PASSWORD = `Confirm password ${this.REQUIRED}`;
  public PASSWORD_DOESNT_MATCH = `Confirm password should be same as password`;

  public FIRST_NAME = `First name ${this.REQUIRED}`;
  public FIRST_NAME_LENGTH = `First name length between 1 to 15 characters`;
  public FIRST_NAME_VALID = `Please enter valid First name`;

  public LAST_NAME = `Last name ${this.REQUIRED}`;
  public LAST_NAME_LENGTH = `Last name length between 1 to 15 characters`;
  public LAST_NAME_VALID = `Please enter valid Last name`;

  public MIDDLE_NAME = `Middle name ${this.REQUIRED}`;
  public MIDDLE_NAME_LENGTH = `Middle name length between 1 to 15 characters`;
  public MIDDLE_NAME_VALID = `Please enter valid Middle name`;

  public PREFERRED_NAME = `Preferred name ${this.REQUIRED}`;
  public PREFERRED_NAME_LENGTH = `Preferred name length between 1 to 30 characters`;
  public PREFERRED_NAME_VALID = `Please enter valid Preferred name`;

  public PHONE_NUMBER = `Phone number ${this.REQUIRED}`;
  public CELL_NUMBER = `Cell Phone ${this.REQUIRED}`;
  public PHONE_NUMBER_LENGTH = `Phone number length between 10 to 14 characters`;
  public PHONE_NUMBER_VALID = `Please enter valid Phone number`;

  public ORGANISATION = `Organization ${this.REQUIRED}`;

  public STAFF = `Staff ${this.REQUIRED}`;
  public BILLING_DATE = `Billing date ${this.REQUIRED}`;
  public BILLING_DATE_VALID = `Please enter valid date`;

  public BILLING_TIME = `Billing time ${this.REQUIRED}`;
  public BILLING_TIME_VALID = `Time must be greater than 0`;

  public PHYSICIAN = `Physician ${this.REQUIRED}`;
  public COMMUNICATION = `Communication type ${this.REQUIRED}`;
  public DIAGNOSIS = `Diagnosis type ${this.REQUIRED}`;

  public USER_TYPE = `Staff type ${this.REQUIRED}`;

  public TO = `Name ${this.REQUIRED}`;

  public PATIENT = `Patient ${this.REQUIRED}`;

  public ACTION = `Action ${this.REQUIRED}`;

  public MESSAGE = `Message ${this.REQUIRED}`;

  public VALID_COUNTRY = "Please select valid country";
  public VALID_STATE = "Please select valid state";
  public VALID_CITY = "Please select valid city";
  public COUNTRY = `Country ${this.REQUIRED}`;
  public STATE = `State ${this.REQUIRED}`;
  public CITY = `City ${this.REQUIRED}`;
  public COUNTRY_REQUIRED = "Please select country first";
  public STATE_REQUIRED = "Please select state first";
  public STREET_ADDRESS_REQUIRED = `Street address ${this.REQUIRED}`;
  public POSTALCODE_REQUIRED = `Zip code ${this.REQUIRED}`;
  public POSTALCODE_LENGTH = `Length should be less than or equals to 12 characters`;

  public SERIAL_NUMBER = "Serial Number " + this.REQUIRED;
  public TRACKING_NUMBER = "Tracking Number " + this.REQUIRED;
  public VENDOR_NAME = "Vendor Name " + this.REQUIRED;
  public DEVICE_NAME = "Device Name " + this.REQUIRED;

  public SELECT_MONTH = "Month " + this.REQUIRED;

  public SELECT_FILE_TYPE = `File type ${this.REQUIRED}`;

  public INPUT_LENGTH = `Length should be less than or equals to 265 characters`;
  public EXTERNAL_PERSON_ID_REQUIRED = `External patient id ${this.REQUIRED}`;

  public MAX_255_LENGTH = `Length should be less than or equals to 255 characters`;

  public RECIPIENT_REQUIRED = "Recipient " + this.REQUIRED;
}

export const passwordRegex = {
  lowerCaseLetters: /[a-z]/g,
  upperCaseLetters: /[A-Z]/g,
  specialCharacter: /[!@#$%^&*\[\]+//(//)//_;./?:/\,\+=-]/g,
  numbers: /[0-9]/g,
};
