import {Component, OnInit} from '@angular/core';

import {saveFile} from '@sharedModule/functions';

@Component({
  selector: 'app-admin-apple-site-association',
  templateUrl: './apple-site-association.component.html',
  styleUrls: ['./apple-site-association.component.scss'],
})
export class AppleSiteAssociationComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    saveFile(
      'assets/files/.well-known/apple-app-site-association',
      'apple-app-site-association',
    );
  }
}
