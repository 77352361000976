<div class="login-otp-container">
  <form (submit)="onSubmitVerificationCodeForm(otpForm)" [formGroup]="otpForm">
    <div class="auth-container">
      <div class="patient-login">
        <div class="row">
          <div class="col-sm-2 col-md-0"></div>
          <div class="col-xs-12 col-sm-8 col-md-12 col-xl-12 PLR-0">
            <div class="patient-login__content">
              <div class="patient-login__content__data">
                <h2>Verification code</h2>
                <p class="MB-20" *ngIf="userDetails['communicationType'] === communicationType.EMAIL">Enter Verification
                  code we just sent you on
                  <span>{{emailAddress}}</span></p>
                <p class="MB-20" *ngIf="userDetails['communicationType'] === communicationType.PHONE">Enter Verification
                  code we just sent you on phone number associated with
                  <span>{{emailAddress}}</span></p>
                <h3>Verification code</h3>
                <div class="MT-20">
                  <div class="form-input form-control-admin">
                    <input autocomplete="off" type="text" class="form-control" name="otpTextField1" maxlength="1"
                           #inputRef1
                           (keyup)="onBlurInput($event, inputRef1, inputRef2, null)"
                           (click)="onFocus(inputRef1)" (paste)="onPasteEvent($event)" formControlName="otpInput1"/>

                    <input autocomplete="off" type="text" class="form-control" name="otpTextField2" maxlength="1"
                           #inputRef2
                           (keyup)="onBlurInput($event, inputRef2, inputRef3, inputRef1)"
                           (click)="onFocus(inputRef2)" (paste)="onPasteEvent($event)" formControlName="otpInput2"/>

                    <input autocomplete="off" type="text" class="form-control" name="otpTextField3" maxlength="1"
                           #inputRef3
                           (keyup)="onBlurInput($event, inputRef3, inputRef4, inputRef2)"
                           (click)="onFocus(inputRef3)" (paste)="onPasteEvent($event)" formControlName="otpInput3"/>

                    <input autocomplete="off" type="text" class="form-control" name="otpTextField4" maxlength="1"
                           #inputRef4
                           (keyup)="onBlurInput($event, inputRef4, inputRef5, inputRef3)"
                           (click)="onFocus(inputRef4)" (paste)="onPasteEvent($event)" formControlName="otpInput4"/>

                    <input autocomplete="off" type="text" class="form-control" name="otpTextField5" maxlength="1"
                           #inputRef5
                           (keyup)="onBlurInput($event, inputRef5, inputRef6, inputRef4)"
                           (click)="onFocus(inputRef5)" (paste)="onPasteEvent($event)" formControlName="otpInput5"/>

                    <input autocomplete="off" type="text" class="form-control" name="otpTextField6" maxlength="1"
                           #inputRef6
                           (keyup)="onBlurInput($event, inputRef6, null, inputRef5)"
                           (click)="onFocus(inputRef6)" (paste)="onPasteEvent($event)" formControlName="otpInput6"/>

                  </div>
                </div>
                <p class="MT-20">If you didn't receive a Verification code!
                  <a *ngIf="showResendOTP" [ngClass]="{'disabledClass' :!showResendOTPButton}"
                     (click)="onClickResendCode()">Resend</a>
                  <a *ngIf="!showResendOTP">{{clockDisplay}}</a>
                </p>

                <div class="MT-20">
                  <div class="col-md-12 col-xl-12 PL-0">
                    <button class="btn btn-primary admin-btn-primary" type="submit" [disabled]="otpForm.invalid">
                      Verify
                      <mat-icon svgIcon="arrowRight"></mat-icon>
                    </button>
                    <!-- <a class="PL-20" (click)="onBackForgotPassword()">Back</a> -->
                    <!--<button class="back-btn-auth" mat-button>Back</button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
