export class APPStorage {
  public static TOKEN = 'at';
  public static VERIFICATION_TOKEN = 'vt';
  public static REFRESH_TOKEN = 'rt';
  public static USER = 'u';
  public static RESEND_OTP = 'ro';
  public static AUTH_ENTERPRISE_INFORMATION = 'aei';
  public static AUTH_ENTERPRISE_ID = 'aeid';
  public static AUTH_USER = 'au';
  public static ROUTINGNAME = 'rName';
  public static PATIENT_DATA = 'pd';
  public static PATIENT_DETAIL_TAB_DATA = 'pdtd';
  public static PATIENT_PERSON_ID_MESSAGE = 'ppim';
  public static SIGN_UP_TOKEN = 'sut';
  public static SET_PASSWORD_TOKEN = 'spt';
  public static EMAIL_ADDRESS = 'ea';
  public static REDIRECT_URL = 'rurl';
  public static FROM_VERIFICATION_CODE = 'fvc';
  public static SIGN_UP_DETAILS = 'sud';
  public static PATIENT_DETAIL_TIMER = 'pdt';
  public static ORGANIZATION_ID = 'oi';
  public static COMPLIANCE_ORGANIZATION_ID = 'coi';
}
