/**
 *
 * Author: smartSense Consulting Solutions Pvt. Ltd.
 * Website: https://smartsensesolutions.com
 * Date: Sep 24 2018 11:30 AM
 * Copyright @ 2018 smartSense Consulting Solutions Pvt. Ltd.
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './shared.service';
import { HttpHelperService } from './http-helper.service';
import { Observable } from 'rxjs';

import { HttpMethodsTypeEnum } from '@sharedModule/constants';
import { AppLogger } from '@sharedModule/functions';

@Injectable()
export class APIManager extends HttpHelperService {
  constructor(_sharedService: SharedService, http: HttpClient) {
    super(_sharedService, http);
  }

  // return authorisation header
  get Authorised_HttpOptions() {
    const authToken = this._sharedService.getToken();
    const httpOptions = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });
    return { headers: httpOptions };
  }

  get Auth_User_Signup_HttpOptions() {
    const authToken = this._sharedService.getVerificationToken();
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth': authToken,
    });
    return { headers: httpOptions, observe: 'response' };
  }

  // return authorisation header with only content-type
  get Content_Type_HttpOptions() {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return { headers: httpOptions, observe: 'response' };
  }

  get HttpOptionsWithObserve() {
    let httpOptions;
    const authToken = this._sharedService.getToken();
    if (this._sharedService.IsValidToken(authToken)) {
      httpOptions = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth': `${authToken}`,
      });
    } else {
      httpOptions = this.Content_Type_HttpOptions;
    }
    return { headers: httpOptions, observe: 'response' };
  }

  // return authorisation header with content-type as x-www-form-urlencoded
  get Form_URL_Encoded_HttpOptions() {
    const authToken = this._sharedService.getToken();
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Auth': `${authToken}`,
    });
    return { headers: httpOptions };
  }

  get HttpOptions() {
    let httpOptions;
    const authToken = this._sharedService.getToken();
    if (this._sharedService.IsValidToken(authToken)) {
      httpOptions = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth': `${authToken}`,
      });
    } else {
      httpOptions = this.Content_Type_HttpOptions;
    }
    return { headers: httpOptions };
  }

  get authorisedHttpOptions() {
    const authToken = this._sharedService.getToken();
    const httpOptions = new HttpHeaders({
      'X-Auth': `${authToken}`,
    });
    return { headers: httpOptions };
  }

  // return authorisation header with blob
  get Blob_HttpOptions(): any {
    return {
      headers: new HttpHeaders({
        'X-Auth': `${this._sharedService.getToken()}`,
      }),
      responseType: 'blob',
    };
  }

  get Blob_Progress_HttpOptions(): any {
    return {
      headers: new HttpHeaders({
        'X-Auth': `${this._sharedService.getToken()}`,
      }),
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    };
  }

  // TODO: Verify by calling API
  /**
   * return formData object from filesObject
   */
  createFormDataObject(params, filesObj) {
    const formData = new FormData();
    for (const obj of filesObj) {
      const imgFilesObj: File[] = obj['files'];
      for (let i = 0; i < imgFilesObj.length; i++) {
        formData.append(obj['reqKey'], imgFilesObj[i], imgFilesObj[i].name);
      }
    }
    if (params && Object.keys(params).length) {
      for (const docKey in params) {
        if (params.hasOwnProperty(docKey)) {
          formData.append(docKey, params[docKey]);
        }
      }
    }
    return formData;
  }

  /**
   * method name : overridable httpHelperMethod
   * purpose : handle loader, and call overload in parent class for getting Observable of response
   * created : Sep 24 2018 11:30 AM
   * Revision :
   */
  httpHelperMethod(
    methodType: HttpMethodsTypeEnum,
    url: string,
    params = {},
    httpOptions = this.HttpOptions,
    showToast,
    showLoader,
    searchParams = {},
    filesObj?: any
  ): Observable<any> {
    if (showLoader) {
      AppLogger(`<=====starting of api call=====> ${url}`);
      this._sharedService.setLoader(true);
    }
    if (
      methodType === HttpMethodsTypeEnum.POST_MULTIPART ||
      methodType === HttpMethodsTypeEnum.PUT_MULTIPART
    ) {
      const formData = this.createFormDataObject(params, filesObj);
      params = formData;
    }
    return super.httpHelperMethod(
      methodType,
      url,
      params,
      httpOptions,
      showToast,
      showLoader,
      searchParams,
      filesObj
    );
  }

  Authorised_SignUp_HttpOptions(authToken) {
    const httpOptions = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });
    return { headers: httpOptions };
  }

  Email_Verify_HttpOptions(token) {
    const httpOptions = new HttpHeaders({
      'verification-token': token,
    });
    return { headers: httpOptions };
  }
}
