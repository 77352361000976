import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppPublicRoutes } from "@sharedModule/constants";
import { AuthGuard } from "@sharedModule/guards";
import {
  AdvisorForgotOtpComponent,
  AdvisorForgotPasswordComponent,
  AdvisorLoginComponent,
  AdvisorLoginOtpComponent,
  AdvisorResetPasswordComponent,
  AppleSiteAssociationComponent,
  SetPasswordComponent,
} from "@publicModule/components";

const routes: Routes = [
  {
    path: AppPublicRoutes.LOGIN,
    component: AdvisorLoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: AppPublicRoutes.APPLE_SITE_AASSOCIATION,
    component: AppleSiteAssociationComponent,
  },
  {
    path: AppPublicRoutes.LOGIN_OTP,
    component: AdvisorLoginOtpComponent,
    canActivate: [AuthGuard],
  },
  {
    path: AppPublicRoutes.FORGOT_PASSWORD,
    component: AdvisorForgotPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: AppPublicRoutes.FORGOT_OTP,
    component: AdvisorForgotOtpComponent,
    canActivate: [AuthGuard],
  },
  {
    path: AppPublicRoutes.RESET_PASSWORD,
    component: AdvisorResetPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: AppPublicRoutes.SET_PASSWORD,
    component: SetPasswordComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdvisorAuthRoutingModule {}
