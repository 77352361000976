import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ChangePasswordService} from '@privateModule/services';
import {FormBaseComponent} from '@sharedModule/components';
import {CommonRegexp, passwordRegex, ValidationConstant} from '@sharedModule/constants';
import {removeEmptyFields} from '@sharedModule/functions';

@Component({
  selector: 'app-admin-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent
  extends FormBaseComponent
  implements OnInit
{
  // Validations Constants
  validationMsg = new ValidationConstant();

  // Form Group Variables
  changePasswordForm: UntypedFormGroup = new UntypedFormGroup({});

  // state constant
  isLoadingResults = false;
  forceChangeRequired = false;

  // Check changes password validation variables
  passwordError: unknown[] = [];

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    fb: UntypedFormBuilder,
    private changePasswordService: ChangePasswordService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(fb);
  }

  ngOnInit() {
    if (this.data && this.data['forceChangeRequired']) {
      this.forceChangeRequired = this.data['forceChangeRequired'];
    }
    this.createChangePasswordForm();
  }

  // Initialization methods
  createChangePasswordForm = () => {
    this.changePasswordForm = this.createForm(
      {
        oldPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(30),
          ],
        ],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(CommonRegexp.PASSWORD_REGEXP),
            Validators.minLength(8),
            Validators.maxLength(30),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {validator: this.validate},
    );
  };

  // API calls
  changePasswordApiCall = (params: any) => {
    return this.changePasswordService.changePassword(params);
  };

  // Page events
  onSubmitChangePasswordForm = (form: UntypedFormGroup) => {
    if (this.onSubmit(form)) {
      this.isLoadingResults = true;
      const params = removeEmptyFields(form.value);
      this.changePasswordApiCall(params).subscribe(() => {
        this.isLoadingResults = false;
        this.onCancel();
      });
    }
  };

  onCancel(): void {
    this.dialogRef.close();
  }

  get formControls() {
    return this.changePasswordForm.controls;
  }

  /**
   * Confirm Validation Check
   */
  validate(resetPasswordFormGroup: UntypedFormGroup) {
    const password = resetPasswordFormGroup.controls['newPassword'];
    const repeatPassword = resetPasswordFormGroup.controls['confirmPassword'];
    if (repeatPassword.value.length <= 0) {
      return null;
    }
    if (password.value.length === 0) {
      return null;
    }
    if (repeatPassword.value !== password.value) {
      repeatPassword.setErrors({incorrect: true});
      return {
        doesMatchPassword: true,
      };
    }
    return null;
  }

  /**
   * On change password validation check value
   * @param form
   */
  onCheckValue = (form: UntypedFormGroup) => {
    const {lowerCaseLetters, upperCaseLetters, numbers, specialCharacter} =
      passwordRegex;
    const value = form.controls['newPassword'].value;
    this.passwordError[1] = value.match(lowerCaseLetters);
    this.passwordError[0] = value.match(upperCaseLetters);
    this.passwordError[3] = value.match(numbers);
    this.passwordError[2] = value.match(specialCharacter);
    this.passwordError[4] = value.length >= 8;
  };
}
