import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MaterialImportsModule } from "./material-imports";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  ConfirmationMessageDialogComponent,
  CustomPaginatorComponent,
  DataNotFoundComponent,
  FormBaseComponent,
  ProgressHudComponent,
  SelectSearchComponent,
  TableBaseComponent,
  ValidationComponent,
  RpmReportViewComponent,
  BillingDataViewComponent,
  CommentsLogDialogComponent,
  UnauthorizeAccessDialogComponent,
  AdvisorUsersChangePasswordComponent,
  ChangePasswordDialogComponent,
  KtalkComponent,
  DownloadProgressComponent,
  ExportFilesComponent
} from "@sharedModule/components";
import {
  DisableControlDirective,
  FocusDirective,
  DateFormatDirective,
} from "@sharedModule/directives";
import {
  CheckEmptyPipe,
  FormatTimePipe,
  LocaleNumberPipe,
  PlaceNAPipe,
  SafeHTMLPipe,
  SortPipe,
} from "@sharedModule/pipes";
import {
  APIManager,
  SharedService,
  SharedUserService,
} from "@sharedModule/shared-service";
import { HttpInterceptors } from "@sharedModule/http-interceptors";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { PdfViewerModule } from "ng2-pdf-viewer";

@NgModule({
  declarations: [
    ProgressHudComponent,
    DataNotFoundComponent,
    FormBaseComponent,
    ValidationComponent,
    CustomPaginatorComponent,
    FocusDirective,
    DisableControlDirective,
    DateFormatDirective,
    AdvisorUsersChangePasswordComponent,
    ChangePasswordDialogComponent,
    SafeHTMLPipe,
    CheckEmptyPipe,
    PlaceNAPipe,
    SortPipe,
    LocaleNumberPipe,
    FormatTimePipe,
    TableBaseComponent,
    ConfirmationMessageDialogComponent,
    SelectSearchComponent,
    RpmReportViewComponent,
    BillingDataViewComponent,
    CommentsLogDialogComponent,
    UnauthorizeAccessDialogComponent,
    DownloadProgressComponent,
    ExportFilesComponent,
    KtalkComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialImportsModule,
    PdfViewerModule,
  ],
  exports: [
    ProgressHudComponent,
    DataNotFoundComponent,
    FormBaseComponent,
    ValidationComponent,
    CustomPaginatorComponent,
    FocusDirective,
    DisableControlDirective,
    DateFormatDirective,
    SafeHTMLPipe,
    CheckEmptyPipe,
    PlaceNAPipe,
    SortPipe,
    LocaleNumberPipe,
    FormatTimePipe,
    TableBaseComponent,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportsModule,
    ConfirmationMessageDialogComponent,
    SelectSearchComponent,
    RpmReportViewComponent,
    BillingDataViewComponent,
    CommentsLogDialogComponent,
    UnauthorizeAccessDialogComponent,
    DownloadProgressComponent,
    ExportFilesComponent,
    KtalkComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SharedService,
        SharedUserService,
        HttpInterceptors,
        APIManager,
      ],
    };
  }
}
