import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppPrivateRoutes } from "@sharedModule/constants";
import { AuthGuard } from "@sharedModule/guards";

const routes: Routes = [
  {
    path: "",
    redirectTo: AppPrivateRoutes.PATIENTS_ROUTE,
    pathMatch: "full",
  },
  {
    path: AppPrivateRoutes.PATIENTS_ROUTE,
    loadChildren: () =>
      import("./modules/patient-module/patient.module").then(
        (m) => m.PatientModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppPrivateRoutes.AUDIT_ROUTE,
    loadChildren: () =>
      import("./modules/audit-module/audit.module").then((m) => m.AuditModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppPrivateRoutes.BILLING_ROUTE,
    loadChildren: () =>
      import("./modules/billing-module/billing.module").then(
        (m) => m.BillingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppPrivateRoutes.DEVICES_ROUTE,
    loadChildren: () =>
      import("./modules/device-module/device.module").then(
        (m) => m.DeviceModule
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: AppPrivateRoutes.COMPLIANCE_REPORT_ROUTE,
  //   loadChildren: () =>
  //     import(
  //       "./modules/compliance-report-module/compliance-report.module"
  //     ).then((m) => m.ComplianceReportModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: AppPrivateRoutes.ADVISOR_CONTAINER_ROUTE,
    loadChildren: () =>
      import("./modules/message-module/message.module").then(
        (m) => m.MessageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppPrivateRoutes.PATIENTS_DETAILS_ROUTE + "/:id",
    loadChildren: () =>
      import(
        "./modules/patient-module/modules/patients-details-module/patients-details.module"
      ).then((m) => m.PatientsDetailsModule),
    canActivate: [AuthGuard],
  },

  {
    path: AppPrivateRoutes.USER_VIEW_PROFILE,
    loadChildren: () =>
      import("./modules/profile-module/profile.module").then(
        (m) => m.ProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppPrivateRoutes.REVIEW_ROUTE,
    loadChildren: () =>
      import("./modules/review-module/review.module").then(
        (m) => m.ReviewModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppPrivateRoutes.USERS_ROUTE,
    loadChildren: () =>
      import("./modules/users-module/users.module").then((m) => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppPrivateRoutes.PATIENT_REVIEW,
    loadChildren: () =>
      import("./modules/patient-review-module/patient-review.module").then(
        (m) => m.PatientReviewModule
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: AppPrivateRoutes.XEALTH_ROUTE,
  //   loadChildren: () =>
  //     import("./modules/xealth-module/xealth.module").then(
  //       (m) => m.XealthModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivateModuleRoutingModule {}
