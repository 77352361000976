import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';

import {ValidationConstant, CommonRegexp} from '@sharedModule/constants';
import {FormBaseComponent} from '@sharedModule/components';
import { AdminUsersService } from '@publicModule/services';

@Component({
  selector: 'app-admin-advisor-users-change-password',
  templateUrl: './advisor-users-change-password.component.html',
  styleUrls: ['./advisor-users-change-password.component.scss'],
})
export class AdvisorUsersChangePasswordComponent
  extends FormBaseComponent
  implements OnInit
{
  // Validations Constants
  validationMsg = new ValidationConstant();

  // Form Group Variables
  userChangePasswordForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdvisorUsersChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminUsersService: AdminUsersService,
    fb: UntypedFormBuilder,
  ) {
    super(fb);
  }

  ngOnInit() {
    this.createUserChangePasswordForm();
  }

  // Initialization methods
  createUserChangePasswordForm = () => {
    this.userChangePasswordForm = this.createForm(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(CommonRegexp.PASSWORD_REGEXP),
            Validators.minLength(8),
            Validators.maxLength(30),
          ],
        ],

        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: passwordValidator,
      },
    );
  };

  changePasswordApiCall(params) {
    return this.adminUsersService.changePassword(this.data['personId'], params);
  }

  //page event
  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmitUserChangePasswordForm = (form: UntypedFormGroup) => {
    if (this.onSubmit(form)) {
      this.changePasswordApiCall(form.value).subscribe((result) => {
        this.onCancel();
      });
    }
  };

  get formControls() {
    return this.userChangePasswordForm.controls;
  }
}

// Custom Password and Confi. password Validation
function passwordValidator(resetPasswordFormGroup: UntypedFormGroup) {
  const password = resetPasswordFormGroup.controls.newPassword;
  const repeatPassword = resetPasswordFormGroup.controls.confirmPassword;
  if (repeatPassword.value !== password.value) {
    repeatPassword.setErrors({incorrect: true});
    return {
      doesMatchPassword: true,
    };
  }
}
