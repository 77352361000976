import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Subject, takeUntil } from "rxjs";

import { ConfigDataModel } from "../../models";
import { AppPrivateRoutes, AppPublicRoutes } from "@sharedModule/constants";
import { SharedService, SharedUserService } from "@sharedModule/shared-service";
import { ChangePasswordDialogComponent } from "@sharedModule/components";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "app-admin-advisor-header",
  templateUrl: "./advisor-header.component.html",
  styleUrls: ["./advisor-header.component.scss"],
})
export class AdvisorHeaderComponent implements OnInit, OnDestroy {
  // Data variables
  loginRequiredSubscriber: any;
  configSubscriber$: any;
  userDetail: any;
  configData: ConfigDataModel;

  // State variables
  isShowDropDown = false;
  isShowNavbar = true;

  // Observable variables
  destroyed = new Subject<void>();

  mediaQuery = window.matchMedia("(max-width: 1024px)");

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _sharedService: SharedService,
    public dialog: MatDialog,
    private adminSharedService: SharedUserService,
    private _appService: AppService
  ) {}

  /**
   * Patients Management URL
   * @returns {string}
   */
  get patientsManagementUrl() {
    return "/" + AppPrivateRoutes.PATIENTS_ROUTE;
  }

  get usersManagementUrl() {
    return "/" + AppPrivateRoutes.USERS_ROUTE;
  }

  get MessageManagementUrl() {
    return "/" + AppPrivateRoutes.ADVISOR_CONTAINER_ROUTE;
  }

  get billingManagementUrl() {
    return "/" + AppPrivateRoutes.BILLING_ROUTE;
  }

  get deviceManagementUrl() {
    return "/" + AppPrivateRoutes.DEVICES_ROUTE;
  }

  // get complianceReportUrl() {
  //   return "/" + AppPrivateRoutes.COMPLIANCE_REPORT_ROUTE;
  // }

  get loginAuditUrl() {
    return "/" + AppPrivateRoutes.AUDIT_ROUTE;
  }

  get reviewUrl() {
    return "/" + AppPrivateRoutes.REVIEW_ROUTE;
  }

  get userViewProfileUrl() {
    return "/" + AppPrivateRoutes.USER_VIEW_PROFILE;
  }

  get patientReviewUrl() {
    return "/" + AppPrivateRoutes.PATIENT_REVIEW;
  }

  // get xealthUrl() {
  //   return "/" + AppPrivateRoutes.XEALTH_ROUTE;
  // }

  ngOnInit() {
    this.subscribeIsLoggedIn();
    if (this.mediaQuery.matches) {
      this.isShowNavbar = false;
    }
    this.breakpointObserver
      .observe(["(min-width: 1025px)"])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        if (result.matches) {
          this.isShowNavbar = true;
        }
      });
  }

  subscribeIsLoggedIn() {
    this.loginRequiredSubscriber = this._sharedService
      .getLoginRequired()
      .subscribe(() => {
        this.userDetail = this._sharedService.getUser();
        if (!this.isConsultant) {
          this.configDataSubscriber();
        }
      });
  }

  configDataSubscriber = () => {
    this.configSubscriber$ = this.adminSharedService
      .getConfigData()
      .subscribe((data) => {
        this.configData = data;
      });
  };

  onClickLogout() {
    this._appService.logoutApi().subscribe(() => {
      this.isShowDropDown = false;
      this._sharedService.logout();
      this._router.navigate(["/" + AppPublicRoutes.LOGIN]);
    });
  }

  onClickTempClean = () => {
    this._appService.tempCleanAPI().subscribe();
  };

  ngOnDestroy() {
    if (this.loginRequiredSubscriber) {
      this.loginRequiredSubscriber.unsubscribe();
    }
    if (this.configSubscriber$) {
      this.configSubscriber$.unsubscribe();
    }

    this.destroyed.next();
    this.destroyed.complete();
  }

  onChangePasswordOpen = () => {
    this.isShowDropDown = false;
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      panelClass: "change-password-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  };

  onUserViewProfileDropdown = () => {
    this.isShowDropDown = false;
  };

  onShowDropdown = () => {
    this.isShowDropDown = true;
  };

  onShowNavbarPhone = () => {
    if (this.mediaQuery.matches) {
      this.isShowNavbar = !this.isShowNavbar;
    }
  };

  onChangeTab = () => {
    if (this.mediaQuery.matches) {
      this.isShowNavbar = false;
    }
  };

  getUnreadMessageCount = (count: number) => {
    if (count > 99) {
      return "99+";
    } else {
      return count.toString();
    }
  };

  get isConsultant() {
    return this._sharedService.isConsultant();
  }

  get isSystemAdmin() {
    return this._sharedService.isSystemAdmin();
  }
}
