import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {PatientModel} from '@privateModule/models';
import {saveFile} from '@sharedModule/functions';

@Component({
  selector: 'app-admin-billing-data-view',
  templateUrl: './billing-data-view.component.html',
  styleUrls: ['./billing-data-view.component.scss'],
})
export class BillingDataViewComponent implements OnInit {
  // Data variables
  pdfSrc: any;
  personId: number;
  patientDetail: PatientModel;

  constructor(
    private dialogRef: MatDialogRef<BillingDataViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit() {
    [
      this.pdfSrc,
      this.personId,
      this.patientDetail
    ] = [
      this.data.pdfSrc,
      this.data.personId,
      this.data.patientDetail
    ]
  }

  // Page events
  onDownloadReport = () => {
    saveFile(this.pdfSrc, `${this.patientDetail.preferredName?this.patientDetail.preferredName:this.patientDetail.first}.pdf`);
  };

  onPrintClick = () => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = this.pdfSrc;
    document.body.appendChild(iframe);
    if (iframe.contentWindow) {
      iframe.contentWindow.print();
    }
  };

  onCloseReport = () => {
    this.dialogRef.close();
  };
}
