import { environment } from "src/environments/environment";

const BASE_URL = `${environment.apiUrl}/`;
const XEALTH_URL = `${environment.apiUrl}/api/xealth/`;
const API_BASE_URL = `${environment.apiUrl}/api/admin/`;
const ADMIN_BASE_URL = `${environment.apiUrl}/admin/`;

export class AdminApi {
  // Auth
  public static LOGIN = BASE_URL + "admin/login";
  public static FORGOT_PASSWORD_EMAIL = BASE_URL + "physician/forgot/password";
  public static FORGOT_PASSWORD_VERIFY_CODE =
    BASE_URL + "physician/forgot/password/verify/otp";
  public static FORGOT_PASSWORD_RESEND_CODE =
    BASE_URL + "physician/forgot/password/resend/otp";
  public static FORGOT_PASSWORD_RESET =
    BASE_URL + "physician/forgot/password/reset";
  public static RESET_PASSWORD = BASE_URL + "physician/password/reset";
  public static ADMIN_CONFIG = API_BASE_URL + "application/config";
  public static LOGIN_VERIFICATION_CODE = ADMIN_BASE_URL + "login/verify/otp";
  public static RESEND_LOGIN_VERIFICATION_CODE =
    ADMIN_BASE_URL + "login/resend/otp";
  public static LOGOUT = API_BASE_URL + "logout";

  // Set password
  public static INFORMATION_RESET_PASSWORD = BASE_URL + "set-password";
  public static PATIENT_SET_PASSWORD =
    BASE_URL + "patient/forgot/password/reset";
  public static PHYSICIAN_SET_PASSWORD =
    BASE_URL + "physician/forgot/password/reset";

  // Patient list
  public static PATIENT_LIST = API_BASE_URL + "patient/filter";
  public static PATIENT_EVENT_LIST = API_BASE_URL + "common/record/filter";
  public static PATIENT = API_BASE_URL + "patient";
  public static ACTIVATE_PATIENT = API_BASE_URL + "patient/activate";
  public static UNLOCK_PATIENT = API_BASE_URL + "unlock";
  public static LATEST_CHECKIN = API_BASE_URL + "latestcheckin";
  public static PEAK_FLOW = API_BASE_URL + "peakflow";
  public static RESCUE_CHART = API_BASE_URL + "patient/details/rescue";
  public static CONTROLLER_CHART = API_BASE_URL + "patient/details/controller";
  public static USER_SUMMARY_DATA = API_BASE_URL + "latestcheckin/summary";
  public static ACTIVITY_CHART = API_BASE_URL + "patient/details/fitness";

  // Organization list
  public static FILTER_ORGANISATION = API_BASE_URL + "organization/filter";

  // Diagnosis list
  public static DIAGNOSIS = API_BASE_URL + "diagnosis";

  // Diagnosis filter
  public static FILTER_DIAGNOSIS = API_BASE_URL + "diagnosis/filter";

  // Clinician list
  public static FILTER_CLINICIAN = API_BASE_URL + "clinical/type/filter";

  // Physician list
  public static FILTER_PHYSICIAN = API_BASE_URL + "physician/filter";

  // User
  public static USER = API_BASE_URL + "user";
  public static USER_LIST = API_BASE_URL + "physician/data/filter";

  // Patient details
  public static PATIENT_DETAILS = API_BASE_URL + "patient/details";
  public static ENGAGEMENT_REPORT = API_BASE_URL + "engagementreport";

  // Messages list
  public static MESSAGE_LIST = API_BASE_URL + "message/list";
  public static SENT_MESSAGE_LIST = API_BASE_URL + "message/sent/list";
  public static MESSAGE_STATUS_UPDATE = API_BASE_URL + "message/read";
  public static MESSAGE_SEND = API_BASE_URL + "message/send";
  public static MULTIPLE_MESSAGE_SEND = API_BASE_URL + "message/mass/send";
  public static REMOVE_MESSAGE = API_BASE_URL + "message/delete";
  public static MESSAGE_TYPE = API_BASE_URL + "message/type/list";

  // Medication
  public static MEDICATION_LOGS = API_BASE_URL + "medications/consume/logs";
  public static PATIENT_MEDICATION_LIST = API_BASE_URL + "medications/filter";
  public static REMOVE_MEDICATIONS = API_BASE_URL + "medication/remove";
  public static FIND_MEDICATION = API_BASE_URL + "medication/filter";
  public static ADD_MEDICATION = API_BASE_URL + "medication/add";

  // Action plan
  public static ACTION_PLAN = API_BASE_URL + "actionplan";

  // Action plan
  public static ALLERGIES = API_BASE_URL + "patient/allergy/filter";

  // Questionnaire
  public static QUESTIONNAIRE = API_BASE_URL + "questionnaire";
  public static QUESTIONNAIRE_DETAILS = API_BASE_URL + "questionnaire/details";

  // Rpm Report Action
  public static RPM_REPORT_ACTION = API_BASE_URL + "rpm/action";

  // Device Chart Details
  public static DEVICE_CHART_DETAILS =
    API_BASE_URL + "patient/details/spirometry";

  // Oximeter Chart Details
  public static OXIMETER_CHART_DETAILS =
    API_BASE_URL + "patient/details/oximeter";
  public static OXIMETER_CHART_DETAILS_V1 =
    API_BASE_URL + "patient/details/v1/oximeter";

  // Weight scale Chart Details
  public static WEIGHT_SCALE_CHART_DETAILS =
    API_BASE_URL + "patient/details/weighing-scale";

  // Billing
  public static BILLING = API_BASE_URL + "billing";
  public static BILLING_LIST = API_BASE_URL + "billing/filter";

  // Rpm report
  public static EXPORT_RPM_REPORT = API_BASE_URL + "export/rpm/reports";

  // Rpm report to EMR
  public static EXPORT_RPM_REPORT_TO_EMR =
    API_BASE_URL + "export/rpm/reports/emr";

  // Billing Audit
  // public static BILLING_AUDIT = API_BASE_URL + 'billing/codes';

  // Billing New Audit
  public static BILLING_AUDIT = API_BASE_URL + "billing/codes/v1";

  // Billing New Report list
  public static BILLING_REPORT_LIST = API_BASE_URL + "billing/codes/v2";

  // Spirometry list
  // public static FILTER_SPIROMETRY = API_BASE_URL + 'spirometry/data/filter';

  // Spirometer filter APi
  public static FILTER_SPIROMETER = API_BASE_URL + "spirometry/data/filter/v1";

  // Oximeter
  public static FILTER_OXIMETER = API_BASE_URL + "oximeter/data/filter";

  // weight scale
  public static FILTER_WEIGHT_SCALE =
    API_BASE_URL + "weighing-scale/data/filter";

  // Notification
  public static NOTIFICATION_LIST = API_BASE_URL + "messages/patient";

  // Comments log
  public static PATIENT_DETAILS_COMMENT_LOG =
    API_BASE_URL + "patient/provider-comments/filter";
  public static PATIENT_REVIEW_COMMENT_LOG =
    API_BASE_URL + "patient/review-comments/filter";

  // Location list
  public static COUNTRY = BASE_URL + "country/filter";
  public static STATE = BASE_URL + "state/filter";
  public static CITY = BASE_URL + "city/filter";

  // Address
  public static ADDRESS = API_BASE_URL + "address";
  public static ADDRESS_LIST = API_BASE_URL + "address/list";
  public static DEVICES_LIST = API_BASE_URL + "device/list";
  public static DEVICE = API_BASE_URL + "device";
  public static DEVICE_REMOVE = API_BASE_URL + "device/remove";
  public static VENDOR_LIST = API_BASE_URL + "vendor/list";
  public static VENDOR_DEVICE_LIST = API_BASE_URL + "vendor/device/filter";

  // Devices List
  public static DEVICES = API_BASE_URL + "patient/device/filter";

  // Login Audit
  public static LOGIN_AUDIT = API_BASE_URL + "physician/logs/login/filter";

  // Review
  public static REVIEW = API_BASE_URL + "preview/filter";

  // Review Checkin List
  public static REVIEW_CHECKIN_LIST = API_BASE_URL + "preview/latest/readings";

  // Review Marked Or Unmark
  public static REVIEW_MARK_OR_UNMARK_LIST =
    API_BASE_URL + "preview/mark-as-viewed";

  //ChangePassword
  public static CHANGE_PASSWORD = API_BASE_URL + "person/";

  //export billing report
  public static EXPORT_BILLING_REPORT = API_BASE_URL + "billing/info/";

  // Xealth
  public static XEALTH_ORDER = XEALTH_URL + "order";

  // Compliance Report
  public static COMPLIANCE_PATIENT_LIST =
    API_BASE_URL + "patient/device/summary/filter";
  public static COMPLIANCE_DEVICE_READING =
    API_BASE_URL + "latestDeviceReading";

  // Export Listing
  public static EXPORT_PATIENT_LISTING = API_BASE_URL + "export/data/patient";
  public static EXPORT_STAFF_LISTING = API_BASE_URL + "export/data/physician";
  public static EXPORT_DEVICE_LISTING = API_BASE_URL + "export/data/device";
  public static EXPORT_LOGIN_AUDIT_LISTING =
    API_BASE_URL + "export/data/physicianLogin";
  public static EXPORT_REVIEW_LISTING = API_BASE_URL + "export/data/review";
  public static EXPORT_COMPLIANCE_LISTING =
    API_BASE_URL + "export/data/compliance";

  // Patient Review
  public static PATIENT_REVIEW_LIST = API_BASE_URL + "patient/preview/filter";
  public static PATIENT_REVIEW_COUNT = API_BASE_URL + "patient/preview/count";
  public static PATIENT_MARK_AS_REVIEWED =
    API_BASE_URL + "mark-as-viewed/patient";
  public static PATIENT_REVIEW_DETAILS =
    API_BASE_URL + "patient/preview/detail";

  // Share Patient Details
  public static SHARE_PATIENT_DETAILS = API_BASE_URL + "consult";
  public static EXTERNAL_PATIENT_LIST = API_BASE_URL + "consult/patient/list";

  // Temp Clean API
  public static TEMP_CLEAN = API_BASE_URL + "tempClear";
}
