import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { APPStorage, PatientDetailTabsEnum } from "@sharedModule/constants";
import { EncryptionFunctions } from "@sharedModule/functions";

@Injectable({
  providedIn: "root",
})
export class SharedUserService {
  private _user;
  private patientData;
  private adminSignUpToken = "";
  private setPasswordToken = "";
  private adminEmailAddress = "";
  private redirectUrl = "";
  private adminSignUpDetails = {};
  private patientDetailTimer = 0;
  private patientDetailTab = PatientDetailTabsEnum.SUMMERY;
  private patientPersonIdForMessage: number;
  private configData: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(
    null
  );

  constructor() {}

  getUser() {
    if (!this._user) {
      this._user = EncryptionFunctions.DECRYPT_OBJ(
        sessionStorage.getItem(APPStorage.USER),
        APPStorage.USER
      );
    }
    return this._user;
  }

  setUser(value): void {
    sessionStorage.setItem(
      APPStorage.USER,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this._user = value;
  }

  get Sign_Up_Process_Header() {
    const authToken = this.getAdminSignUpToken();
    const httpOptions = new HttpHeaders({
      "Content-Type": "application/json",
      "X-Auth": `${authToken}`,
    });
    return { headers: httpOptions, observe: "response" };
  }

  get Set_Password_Process_Header() {
    const authToken = this.getSetPasswordToken();
    const httpOptions = new HttpHeaders({
      "Content-Type": "application/json",
      "X-Auth": `${authToken}`,
    });
    return { headers: httpOptions, observe: "response" };
  }

  // Get patient data
  getPatientListData(): any {
    this.patientData = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.PATIENT_DATA)
    );
    return this.patientData;
  }

  setPatientListData(value: any): void {
    sessionStorage.setItem(
      APPStorage.PATIENT_DATA,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.patientData = value;
  }

  // Patient detail tab data
  getPatientDetailTabData(): string {
    this.patientDetailTab = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.PATIENT_DETAIL_TAB_DATA)
    );
    return this.patientDetailTab;
  }

  setPatientDetailTabData(value: PatientDetailTabsEnum): void {
    sessionStorage.setItem(
      APPStorage.PATIENT_DETAIL_TAB_DATA,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.patientDetailTab = value;
  }

  // Patient person id for message
  getPatientPersonIdForMessage(): any {
    this.patientPersonIdForMessage = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.PATIENT_PERSON_ID_MESSAGE)
    );
    return this.patientPersonIdForMessage;
  }

  setPatientPersonIdForMessage(value: number): void {
    sessionStorage.setItem(
      APPStorage.PATIENT_PERSON_ID_MESSAGE,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.patientPersonIdForMessage = value;
  }

  // Patient detail timer
  getPatientDetailTimer(): any {
    this.patientDetailTimer = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.PATIENT_DETAIL_TIMER)
    );
    return this.patientDetailTimer;
  }

  setPatientDetailTimer(value: any): void {
    sessionStorage.setItem(
      APPStorage.PATIENT_DETAIL_TIMER,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.patientDetailTimer = value;
  }

  // SignUp process token
  getAdminSignUpToken(): string {
    this.adminSignUpToken = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.SIGN_UP_TOKEN)
    );
    return this.adminSignUpToken;
  }

  setAdminSignUpToken(value: string): void {
    sessionStorage.setItem(
      APPStorage.SIGN_UP_TOKEN,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.adminSignUpToken = value;
  }

  // Set password process token
  getSetPasswordToken(): string {
    this.setPasswordToken = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.SET_PASSWORD_TOKEN)
    );
    return this.setPasswordToken;
  }

  setSetPasswordToken(value: string): void {
    sessionStorage.setItem(
      APPStorage.SET_PASSWORD_TOKEN,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.setPasswordToken = value;
  }

  // Sign up email address related data
  setAdminEmailAddress(value: any): void {
    sessionStorage.setItem(
      APPStorage.EMAIL_ADDRESS,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.adminEmailAddress = value;
  }

  getAdminEmailAddress(): any {
    this.adminEmailAddress = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.EMAIL_ADDRESS)
    );
    return this.adminEmailAddress;
  }

  // Store redirect url
  setRedirectUrl(value: any): void {
    sessionStorage.setItem(
      APPStorage.REDIRECT_URL,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.redirectUrl = value;
  }

  getRedirectUrl(): any {
    this.redirectUrl = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.REDIRECT_URL)
    );
    return this.redirectUrl;
  }

  // Sign Up related data
  setAdminSignUpDetails(value: any): void {
    sessionStorage.setItem(
      APPStorage.SIGN_UP_DETAILS,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
    this.adminSignUpDetails = value;
  }

  getAdminSignUpDetails(): any {
    this.adminSignUpDetails = EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.SIGN_UP_DETAILS)
    );
    return this.adminSignUpDetails;
  }

  // Config data
  getConfigData(): Observable<any> {
    return this.configData.asObservable();
  }

  setConfigData(val: any): void {
    this.configData.next(val);
  }

  // Organization store
  setOrganizationId(value: string) {
    sessionStorage.setItem(
      APPStorage.ORGANIZATION_ID,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
  }

  getOrganizationId() {
    return EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.ORGANIZATION_ID)
    );
  }

  // Compliance organization store
  setComplianceOrganizationId(value: string) {
    sessionStorage.setItem(
      APPStorage.COMPLIANCE_ORGANIZATION_ID,
      EncryptionFunctions.ENCRYPT_OBJ(value)
    );
  }

  getComplianceOrganizationId() {
    return EncryptionFunctions.DECRYPT_OBJ(
      sessionStorage.getItem(APPStorage.COMPLIANCE_ORGANIZATION_ID)
    );
  }
}
