import { Injectable } from "@angular/core";
import { HttpMethodsTypeEnum, AdminApi } from "@sharedModule/constants";
import { APIManager } from "@sharedModule/shared-service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(private _apiManager: APIManager) {}

  logoutApi = (): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      AdminApi.LOGOUT,
      {},
      this._apiManager.HttpOptions,
      false,
      true
    );
  };

  tempCleanAPI = (): Observable<any> => {
    return this._apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      AdminApi.TEMP_CLEAN,
      {},
      this._apiManager.HttpOptions,
      true,
      false
    );
  };
}
