import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {
  AppPublicRoutes,
  CommonRegexp,
  passwordRegex,
  ValidationConstant,
} from '@sharedModule/constants';
import {FormBaseComponent} from '@sharedModule/components';
import {SetPasswordService} from '@publicModule/services';
import { SharedUserService } from '@sharedModule/shared-service';

@Component({
  selector: 'app-admin-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent extends FormBaseComponent implements OnInit {
  validationMsg = new ValidationConstant();

  // Form related variables
  resetPasswordForm: UntypedFormGroup;

  // Check reset password validation variables
  passwordError: any[] = [];
  userData: {email: string; patient: boolean};

  // State variables
  isShowSuccessMessage = false;

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminSharedService: SharedUserService,
    private setPasswordService: SetPasswordService,
  ) {
    super(fb);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.getUserInformationApiCall(params['id']).subscribe((response) => {
        this.handleUserInfoResponse(response);
      });
    });
    this.createResetPwdForm();
  }

  handleUserInfoResponse = (response: any) => {
    this.userData = response['body']['payload'];
    this.adminSharedService.setSetPasswordToken(response.headers.get('x-auth'));
  };

  // Initialisation methods
  createResetPwdForm() {
    this.resetPasswordForm = this.createForm(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(30),
            Validators.pattern(CommonRegexp.PASSWORD_REGEXP),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {validator: this.validate},
    );
  }

  // Api calls
  getUserInformationApiCall = (id: string) => {
    return this.setPasswordService.getResetPasswordInformation(id);
  };

  setPasswordApiCall = (params: any) => {
    if (this.userData.patient) {
      return this.setPasswordService.setPasswordPatient(params);
    } else {
      return this.setPasswordService.setPasswordPhysician(params);
    }
  };

  // Page events
  onSubmitResetPasswordForm(form: UntypedFormGroup) {
    if (this.onSubmit(form)) {
      this.setPasswordApiCall(form.value).subscribe(() => {
        if (this.userData.patient) {
          this.isShowSuccessMessage = true;
        } else {
          this.router.navigate(['/' + AppPublicRoutes.LOGIN]);
        }
      });
    }
  }

  // Helper methods
  validate(registrationFormGroup: UntypedFormGroup) {
    const password = registrationFormGroup.controls['password'];
    const repeatPassword = registrationFormGroup.controls['confirmPassword'];
    if (repeatPassword.value.length === 0) {
      return null;
    }
    if (repeatPassword.value !== password.value) {
      repeatPassword.setErrors({incorrect: true});
      return {
        doesMatchPassword: true,
      };
    }
    return null;
  }

  get formControls() {
    return this.resetPasswordForm.controls;
  }

  /**
   * On reset password validation check value
   * @param form
   */
  onCheckValue = (form: UntypedFormGroup) => {
    const {lowerCaseLetters, upperCaseLetters, numbers, specialCharacter} =
      passwordRegex;
    const value = this.formControls['password'].value;
    this.passwordError[1] = value.match(lowerCaseLetters);
    this.passwordError[0] = value.match(upperCaseLetters);
    this.passwordError[3] = value.match(numbers);
    this.passwordError[2] = value.match(specialCharacter);
    this.passwordError[4] = value.length >= 8;
  };
}
