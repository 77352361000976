import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-export-files",
  templateUrl: "./export-files.component.html",
  styleUrls: ["./export-files.component.scss"],
})
export class ExportFilesComponent implements OnInit {
  // Angular Variables
  @Output() allExport = new EventEmitter<boolean>;
  
  // Form Variables
  allFilterTypeControl = new UntypedFormControl(true);

  ngOnInit() {}

  // Page events
  onClickExport() {
    this.allExport.emit(this.allFilterTypeControl.value);
  }
}
