import {
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { createUUID, saveFile } from "@sharedModule/functions";
import { Observable } from "rxjs";
import { FileModel, TaskStatus } from "../download-progress.model";
import { SharedService } from "@sharedModule/shared-service";

@Injectable({
  providedIn: "root",
})
export class DownloadProgressService {
  // Data Variables
  taskList: FileModel[] = [];

  constructor(private sharedService: SharedService) {}

  addDownloadTask(fileName: string, observable: Observable<any>) {
    const newTask: FileModel = {
      id: createUUID(8),
      fileName: fileName,
      observable: observable,
      subcription: null,
      progress: 0,
      status: TaskStatus.Started,
    };

    this.taskList.unshift(newTask);
    this.downloadSubscribe(newTask);
    this.sharedService.updateDownloadTaskList(this.taskList);
  }

  downloadSubscribe = (task: FileModel) => {
    task.subcription = task.observable.subscribe({
      next: (event) => {
        if (this.isHttpProgressEvent(event)) {
          const percentage = Math.round((100 * event.loaded) / event.total);
          task.progress = percentage;
          task.status = TaskStatus.InProgress;
        }
        if (this.isHttpResponse(event)) {
          saveFile(event.body, task.fileName);
          task.progress = 100;
          task.status = TaskStatus.Completed;
          this.removeTask(task.id);
        }
      },
      error: () => {
        task.status = TaskStatus.Failed;
        this.removeTask(task.id);
      },
    });
  };

  removeTask(taskId: string) {
    setTimeout(() => {
      if (this.taskList && this.taskList.length > 0) {
        const foundTaskIndex = this.taskList.findIndex(
          (task) => task.id === taskId
        );
        if (foundTaskIndex >= 0) {
          const foundTask: FileModel = this.taskList[foundTaskIndex];
          foundTask.subcription.unsubscribe();
          this.taskList.splice(foundTaskIndex, 1);
        }
      }
    }, 3000);
  }

  // Helper Methods
  private isHttpProgressEvent = (
    event: HttpEvent<unknown>
  ): event is HttpProgressEvent => {
    return event.type === HttpEventType.DownloadProgress;
  };

  private isHttpResponse = <T>(
    event: HttpEvent<T>
  ): event is HttpResponse<T> => {
    return event.type === HttpEventType.Response;
  };
}
