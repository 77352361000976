import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AdminApi,
  HttpMethodsTypeEnum,
} from '@sharedModule/constants';
import { APIManager } from '@sharedModule/shared-service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private apiManager: APIManager) {}

  getMessageList(params): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.MESSAGE_LIST,
      params,
      this.apiManager.HttpOptions,
      false,
      true,
    );
  }

  getSentMessageList(params): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.SENT_MESSAGE_LIST,
      params,
      this.apiManager.HttpOptions,
      false,
      true,
    );
  }

  composeMessage(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST_MULTIPART,
      AdminApi.MESSAGE_SEND,
      params,
      this.apiManager.authorisedHttpOptions,
      true,
      true,
      {},
      []
    );
  }

  multipleComposeMessage(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST_MULTIPART,
      AdminApi.MULTIPLE_MESSAGE_SEND,
      params,
      this.apiManager.authorisedHttpOptions,
      true,
      true,
      {},
      []
    );
  }

  getMessageType(): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.GET,
      AdminApi.MESSAGE_TYPE,
      {},
      this.apiManager.HttpOptions,
      false,
      false,
    );
  }

  removeMessage(params: any): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.REMOVE_MESSAGE,
      params,
      this.apiManager.authorisedHttpOptions,
      true,
      true,
    );
  }

  updateMessageStatus(params): Observable<any> {
    return this.apiManager.httpHelperMethod(
      HttpMethodsTypeEnum.POST,
      AdminApi.MESSAGE_STATUS_UPDATE,
      params,
      this.apiManager.HttpOptions,
      false,
      false,
    );
  }
}
